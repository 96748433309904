import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Button,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogActions,
    IconButton,
    Tooltip,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DOMPurify from 'dompurify';
import { TextInputComponent, NameInputComponent, AddressInputComponent, EmailInputComponent, PhoneNumberInputComponent, CPRInputComponent, BirthdayInputComponent } from '../InputComponents';
import { info as infoType, address as addresstype, cpr as cprType, children as childrenType, email as emailType, kreditDataPension as kreditDataPensionType, pdfImage as pdfImageType, text as textType, name as nameType, phone as phoneType } from '../../reducers/onboardingQuestionTypes';
import { intro as introGroupType, welcomeMessage as welcomeMessageGroupType, comment as commentGroupType } from '../../reducers/onboardingQuestionGroupTypes';
import { VerifyOnboardingFields, VerifyOnboardingField, VerifyAllGroups } from '../../pages/Kunder/Kunde/Onboarding/VerifyOnboardingFields';
import { getFile, deleteFile as deleteFileFromDB } from '../../databaseRepository/customerDataManager';
import { inviteUser as inviteUserAction } from '../../actions/uniifyActions';
import {
    addOrUpdateAnswer as addOrUpdateAnswerAction,
    addFileToAnswer as addFileToAnswerAction,
    removeFileRef as removeFileRefAction,
    addChildToAnswer as addChildToAnswerAction,
    updateChildInAnswer as updateChildInAnswerAction,
    deleteChildInAnswer as deleteChildInAnswerAction,
    addOrUpdateCustomerComment as addOrUpdateCustomerCommentAction,
    updateOnboardingProcessField as updateOnboardingProcessFieldAction,
    deleteCustomerLink as deleteCustomerLinkAction,
} from '../../actions/onboardingActions';
import UploadOnboardingFileModal from './UploadOnboardingFileModal';
import FileDialog from '../../pages/Kunder/Kunde/Files/FileDialog';
import FileNotFoundDialog from '../../pages/Kunder/Kunde/Onboarding/FileNotFoundDialog';
import { copyAnswers } from '../../reducers/defaultOnboardingQuestionnaires';
import { isNullOrWhitespace } from '../../utils/helperFunctions';
import { GetMergedQuistionair } from './onboardingHelpers';

export default function Questions({ processId, partnerId, customerId, customerName, startActiveQuestionGroup }) {
    const dispatch = useDispatch();
    const [activeQuestionGroupIndex, setActiveQuestionGroupIndex] = useState(-1);
    const onboardingProcessesData = useSelector(state => state.onboardingProcessesData);
    const process = onboardingProcessesData?.processes?.find(x => x.processId === processId);
    const questionnaire = process?.questionnaire;
    const answers = process?.answers;
    const [fileToShow, setFileToShow] = useState();
    const [fileNotFound, setFileNotFound] = useState('');
    const [alertDirectionForward, setAlertDirectionForward] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [closePage, setClosePage] = useState(false);
    const [pageClosed, setPageClosed] = useState(false);

    const localQuestionGroups = useMemo(() => {
        return GetMergedQuistionair(questionnaire);
    }, [questionnaire]);

    useEffect(() => {
        if(!localQuestionGroups || activeQuestionGroupIndex !== -1) return;

        if (startActiveQuestionGroup.groupId && startActiveQuestionGroup.activeResponder) {
            const index = localQuestionGroups.findIndex(x => (x.responder ?? 'customer') === startActiveQuestionGroup.activeResponder && x.id === startActiveQuestionGroup.groupId);
            setActiveQuestionGroupIndex(index);
        } else {
            setActiveQuestionGroupIndex(0);
        }
    }, [activeQuestionGroupIndex, localQuestionGroups, startActiveQuestionGroup.activeResponder, startActiveQuestionGroup.groupId]);

    const updateAnswer = useCallback((responder, questionGroupId, questionId, answerData) =>
        dispatch(addOrUpdateAnswerAction(processId, questionGroupId, questionId, responder, answerData)), [dispatch, processId]);

    const updateCustomerComment = useCallback((responder, questionGroupId, comment) =>
        dispatch(addOrUpdateCustomerCommentAction(processId, questionGroupId, responder, comment)), [dispatch, processId]);

    const addFileToAnswer = useCallback((responder, questionGroupId, questionId, fileId, fileName) =>
        dispatch(addFileToAnswerAction(processId, responder, questionGroupId, questionId, fileId, fileName)), [dispatch, processId]);


    const removeFileRef = useCallback((responder, questionGroupId, questionId, fileId) =>
        dispatch(removeFileRefAction(processId, responder, questionGroupId, questionId, fileId)), [dispatch, processId]);

    const addChildToAnswer = useCallback((responder, questionGroupId, questionId) =>
        dispatch(addChildToAnswerAction(processId, responder, questionGroupId, questionId)), [dispatch, processId]);

    const updateChildInAnswer = useCallback((responder, questionGroupId, questionId, childId, fieldName, fieldValue) =>
        dispatch(updateChildInAnswerAction(processId, responder, questionGroupId, questionId, childId, fieldName, fieldValue)), [dispatch, processId]);

    const deleteChildInAnswer = useCallback((responder, questionGroupId, questionId, childId) =>
        dispatch(deleteChildInAnswerAction(processId, responder, questionGroupId, questionId, childId)), [dispatch, processId]);

    const updateOnboardingProcessField = useCallback((fieldName, fieldValue) =>
        dispatch(updateOnboardingProcessFieldAction(processId, fieldName, fieldValue)), [dispatch, processId]);

    const deleteCustomerLink = useCallback(() => dispatch(deleteCustomerLinkAction(processId)), [dispatch, processId]);

    const inviteUser = useCallback(({ redirectUrl }) => dispatch(inviteUserAction({ redirectUrl, customerId })), [dispatch, customerId]);

    async function findAndSetFileToShow(fileId) {
        const localFile = await getFile(partnerId, customerId, fileId);
        if(localFile === null) {
            setFileNotFound(fileId);
        } else {
            setFileToShow(localFile);
        }
    }

    async function tryDeleteFile(responder, questionGroupId, questionId, fileId) {
        let isDeleted = true;
        try {
            await deleteFileFromDB({ customerId, partnerId }, fileId);
        } catch (error) {
            isDeleted = false;
        }
        await removeFileRef(responder, questionGroupId, questionId, fileId);

        if(!isDeleted) setFileNotFound(fileId);
    }

    const activeQuestionGroup = localQuestionGroups[activeQuestionGroupIndex !== -1 ? activeQuestionGroupIndex : 0];

    const getAnswerGroups = useCallback((responder) => {
        if(!answers) return null;

        let localResponder;
        if(responder === 'customer') localResponder = 'customerAnswerGroups';
        if(responder === 'spouse') localResponder = 'spouseAnswerGroups';
        if(responder === 'shared') localResponder = 'sharedAnswerGroups';

        const responderAnswers = copyAnswers(answers);

        return responderAnswers[localResponder];
    }, [answers]);

    const getAnswerGroup = useCallback((responder, questionGroupId) => {
        if(!answers) return null;

        const responderAnswers = getAnswerGroups(responder);
        const answerGroupIndex = responderAnswers?.findIndex(answerGroup => answerGroup.id === questionGroupId) ?? -1;
        if (answerGroupIndex === -1) return null;

        return responderAnswers[answerGroupIndex];
    }, [answers, getAnswerGroups]);

    function nextItem(allVerified) {
        const lenght = localQuestionGroups?.length;
        if(allVerified && activeQuestionGroupIndex + 1 < lenght) setActiveQuestionGroupIndex(activeQuestionGroupIndex + 1);
        else setShowAlert(true);
    }

    function previousItem(allVerified) {
        if(allVerified && activeQuestionGroupIndex > 0) setActiveQuestionGroupIndex(activeQuestionGroupIndex - 1);
        else setShowAlert(true);
    }

    const activeResponder = activeQuestionGroup?.responder ?? 'customer';
    const activeAnswerGroup = getAnswerGroup(activeResponder, activeQuestionGroup.id);
    const allMandotoryFiledsVerified = VerifyOnboardingFields(activeQuestionGroup, activeAnswerGroup);
    const activeQuestionNumber = localQuestionGroups.filter(x => x.type !== welcomeMessageGroupType).findIndex(x => x.id === activeQuestionGroup.id && (x.responder ?? 'customer') === activeResponder) + 1;
    const numberOfQuestions = localQuestionGroups?.filter(x => x.type !== welcomeMessageGroupType).length;
    const allGroupsVerified = VerifyAllGroups(localQuestionGroups, getAnswerGroups(activeResponder));

    const handleGetPensionData = async () => {
        const redirectUrl = `${window.location.href}?kreditdata_person=${activeResponder}`;
        const { uniifyCustomerId, templateId, teamId } = await inviteUser({ redirectUrl });
        if (uniifyCustomerId) {
            const kreditDataUrl = `https://app.kreditdata.dk/consent/${teamId}/${templateId}?cc=${uniifyCustomerId}`;
            window.location.assign(kreditDataUrl);
        } else {
            alert('Der gik noget galt');
        }
    };
    const handleFinish = () => {
        setPageClosed(true);
        if(allGroupsVerified){
            updateOnboardingProcessField('finished', true);
            deleteCustomerLink();
        }else{
            updateOnboardingProcessField('started', true);
        }

    };

    return (
        <div style={{ borderBottom: '2px solid', margin: '0px 25px', padding: '25px 0px' }}>
            { activeQuestionGroup.type === welcomeMessageGroupType &&
                <p style={{ textAlign: 'center' }}>
                    { activeResponder === 'shared' ? 'Nu skal I indtaste fælles data' : `Nu skal ${activeResponder === 'customer' ? customerName : questionnaire.spouseName.firstName} indtaste` }
                </p>
            }
            { activeQuestionGroup.type !== welcomeMessageGroupType && (
                <>
                    { activeQuestionGroup.type !== introGroupType && questionnaire?.includeSpouse && <p style={{ textAlign: 'center' }}>{ activeResponder === 'shared' ? 'Fælles' : `${activeResponder === 'customer' ? customerName : questionnaire.spouseName.firstName}` }</p> }
                    <div style={{ marginBottom: 10, paddingRight: 20 }}>
                        <span style={{ fontSize: 25 }}>{ activeQuestionGroup.title }</span>
                        <span style={{ float: 'right', fontSize: 16 }}>Trin { activeQuestionNumber } af { numberOfQuestions }</span>
                    </div>
                    { activeQuestionGroup.commentText &&
                        <div style={{ marginLeft: '25px', marginTop: '25px', display: 'inline-block', textAlign: 'left', border: '2px solid #ccc', borderRadius: '16px', padding: '12.5px 25px', width: '25%' }}>
                            <Typography variant="h6">Kommentar fra rådgiveren:</Typography>
                            { activeQuestionGroup?.commentText }
                        </div> }
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div style={{ minWidth: 335, marginRight: 100 }}>
                            { activeQuestionGroup.questions?.map(question => {
                                const answer = activeAnswerGroup?.answers?.find(x => x.id === question.id);
                                const answerData = answer?.answerData;
                                const madatoryVerified = VerifyOnboardingField(question.type, answer);
                                return (
                                    <div key={ question.id } style={{ marginLeft: '25px', marginTop: '25px' }}>
                                        { !question?.mandatory && <span style={{ display: 'inline-block', width: 35.6333 }}/> }
                                        { question.type === infoType && (
                                            <div style={{ marginTop: 25 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.hintText) }} />
                                        ) }
                                        { question.type === nameType && (
                                            <NameInputComponent
                                                firstName={ answerData?.firstName ?? '' }
                                                lastName={ answerData?.lastName ?? '' }
                                                onChange={ (firstName, lastName) => updateAnswer(activeResponder, activeQuestionGroup.id, question.id, { firstName, lastName }) }
                                                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                                            />
                                        ) }
                                        { question.type === addresstype && (
                                            <AddressInputComponent
                                                onChange={ selectedAddress => updateAnswer(activeResponder, activeQuestionGroup.id, question.id, { selectedAddress }) }
                                                value={ answerData?.selectedAddress }
                                                style={{ display: 'inline-block', verticalAlign: 'middle', width: 310 }}
                                            />
                                        ) }
                                        { question.type === emailType && (
                                            <EmailInputComponent
                                                onChange={ email => updateAnswer(activeResponder, activeQuestionGroup.id, question.id, { email }) }
                                                value={ answerData?.email ?? '' }
                                                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                                            />
                                        ) }
                                        { question.type === phoneType && (
                                            <PhoneNumberInputComponent
                                                onChange={ phoneNumber => updateAnswer(activeResponder, activeQuestionGroup.id, question.id, { phoneNumber }) }
                                                value={ answerData?.phoneNumber ?? '' }
                                                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                                            />
                                        ) }
                                        { question.type === cprType && (
                                            <>
                                                <CPRInputComponent
                                                    onChange={ cpr => updateAnswer(activeResponder, activeQuestionGroup.id, question.id, { cpr }) }
                                                    value={ answerData?.cpr ?? '' }
                                                    style={{ display: 'inline-block', verticalAlign: 'middle' }}
                                                />
                                                <Tooltip
                                                    title={ question.hintText } style={{ bottom: 15, right: 5 }}  tabIndex = { -1 }>
                                                    <IconButton size='small'>
                                                        <QuestionMarkIcon style={{ width: 15 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>

                                        ) }
                                        { question.type === pdfImageType && (
                                            <div style={{ maxWidth: 600, display: 'inline-block', verticalAlign: 'middle' }}>
                                                <Typography variant="h6" style={{ display: 'inline' }}>{ question.title }</Typography>
                                                <Tooltip
                                                    title={ question.hintText } style={{ bottom: 15, right: 5 }} >
                                                    <IconButton size='small'>
                                                        <QuestionMarkIcon style={{ width: 15 }} />
                                                    </IconButton>
                                                </Tooltip>
                                                { answerData?.files?.map(file => (
                                                    <div key={ file.fileId } style={{ display: 'flex', marginBottom: 10 }}>
                                                        <div style={{ flex: '70%' }}>Filenavn: { file.fileName }</div>
                                                        <div style={{ flex: '15%', marginRight: 5 }}><Button onClick={ () => findAndSetFileToShow(file.fileId) }>Vis fil</Button></div>
                                                        <div style={{ flex: '15%' }}><Button onClick={ () => tryDeleteFile(activeResponder, activeQuestionGroup.id, question.id, file.fileId) }>Slet fil</Button></div>
                                                        <FileNotFoundDialog
                                                            open={ file.fileId === fileNotFound }
                                                            onCancel={ () => setFileNotFound('') }
                                                            onConfirm={ () => removeFileRef(activeResponder, activeQuestionGroup.id, question.id, file.fileId) }
                                                        />
                                                    </div>
                                                )) }
                                                <UploadOnboardingFileModal
                                                    customerId={ customerId }
                                                    specifiedFileType={ question.data?.pdfType ?? undefined }
                                                    onSave={ (fileId, fileName) => addFileToAnswer(activeResponder, activeQuestionGroup.id, question.id, fileId, fileName) }
                                                    spouse={ activeResponder === 'spouse' }
                                                />
                                            </div>
                                        ) }
                                        { question.type === kreditDataPensionType && (
                                            <div style={{ maxWidth: 600, display: 'inline-block', verticalAlign: 'middle' }}>
                                                <Typography variant="h6" style={{ display: 'inline' }}>{ question.title }</Typography>
                                                <Tooltip
                                                    title={ question.hintText } style={{ bottom: 15, right: 5 }} >
                                                    <IconButton size='small'>
                                                        <QuestionMarkIcon style={{ width: 15 }} />
                                                    </IconButton>
                                                </Tooltip>
                                                { answerData?.files?.map(file => (
                                                    <div key={ file.fileId } style={{ display: 'flex', marginBottom: 10 }}>
                                                        <div style={{ flex: '70%' }}>Filenavn: { file.fileName }</div>
                                                        <div style={{ flex: '15%', marginRight: 5 }}><Button onClick={ () => findAndSetFileToShow(file.fileId) }>Vis fil</Button></div>
                                                        <div style={{ flex: '15%' }}><Button onClick={ () => tryDeleteFile(activeResponder, activeQuestionGroup.id, question.id, file.fileId) }>Slet fil</Button></div>
                                                        <FileNotFoundDialog
                                                            open={ file.fileId === fileNotFound }
                                                            onCancel={ () => setFileNotFound('') }
                                                            onConfirm={ () => removeFileRef(activeResponder, activeQuestionGroup.id, question.id, file.fileId) }
                                                        />
                                                    </div>
                                                )) }
                                                <Button
                                                    onClick={ handleGetPensionData }
                                                >
                                                    Hent Pensionsdata
                                                </Button>
                                            </div>
                                        ) }
                                        { question.type === textType && (
                                            <TextInputComponent
                                                label={ question.title }
                                                name='Bemærkninger'
                                                textAlign='left'
                                                style={{ maxWidth: 310, width: 310 }}
                                                multiline
                                                rows={ 4 }
                                                onChange={ text => updateAnswer(activeResponder, activeQuestionGroup.id, question.id, { text }) }
                                                value={ answerData?.text ?? '' }
                                            />
                                        ) }
                                        { question.type === childrenType && (
                                            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                                <Typography variant="h6" style={{ marginBottom: 20 }}>{ question.title }</Typography>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={ answerData?.noKids ?? false }
                                                        onChange={ event => updateAnswer(activeResponder, activeQuestionGroup.id, question.id, { ...answerData, noKids: event.target.checked }) }
                                                    />
                                                } label="Har ingen hjemmeboende børn" />

                                                { !answerData?.noKids && answerData?.children?.map(child => (
                                                    <div key={ child.id } style={{ marginBottom: 10 }}>
                                                        <TextInputComponent
                                                            style={{ marginTop: 10, marginRight: 10 }}
                                                            label={ 'Navn' }
                                                            name='name'
                                                            textAlign='left'
                                                            onChange={ name => updateChildInAnswer(activeResponder, activeQuestionGroup.id, question.id, child.id, 'name', name) }
                                                            value={ child.name ?? '' }
                                                        />
                                                        <BirthdayInputComponent
                                                            value={ child.birthday ?? '' }
                                                            onChange={ birthday => updateChildInAnswer(activeResponder, activeQuestionGroup.id, question.id, child.id, 'birthday', birthday) }
                                                        />
                                                        <Button
                                                            style={{ marginLeft: 10, marginTop: 10 }}
                                                            onClick={ () => deleteChildInAnswer(activeResponder, activeQuestionGroup.id, question.id, child.id) }
                                                        >
                                                            Slet barn
                                                        </Button>
                                                    </div>
                                                )) }
                                                <Button style={{ display: answerData?.noKids ? 'none' : null }} onClick={ () => addChildToAnswer(activeResponder, activeQuestionGroup.id, question.id) }>Tilføj barn</Button>
                                            </div>
                                        )
                                        }
                                        { question?.mandatory && madatoryVerified && <CheckCircleOutlineIcon style={{ verticalAlign: 'middle', marginRight: 5 }} fontSize='medium' color='success' /> }
                                        { question?.mandatory && !madatoryVerified && <ErrorOutlineIcon style={{ verticalAlign: 'middle', marginRight: 5 }} fontSize='medium' color='error' /> }
                                    </div>
                                );
                            }) }
                        </div>
                        { !isNullOrWhitespace(activeQuestionGroup?.guideText) &&
                            <div style={{ marginTop: 25, padding: 10, border: '2px solid black', height: '100%' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activeQuestionGroup.guideText) }} />
                        }
                    </div>
                    <div>
                        <div>
                            { activeQuestionGroup.type !== introGroupType && activeQuestionGroup.type !== commentGroupType &&
                                <div style={{ marginLeft: '25px', marginTop: '25px' }}>
                                    <TextInputComponent
                                        label='Kommentar til rådgiver'
                                        name='Kommentar til rådgiver'
                                        textAlign='left'
                                        style={{ maxWidth: 310, width: 310 }}
                                        multiline
                                        rows={ 4 }
                                        onChange={ (comment) => updateCustomerComment(activeResponder, activeQuestionGroup.id, comment) }
                                        value={ activeAnswerGroup?.customerComment ?? '' }
                                    />
                                </div>
                            }
                        </div>

                    </div>
                    <FileDialog
                        file={ fileToShow }
                        onClose={ () => setFileToShow() }
                    />
                </>
            ) }
            <div style={{ marginTop: 10, display: 'flex',  justifyContent: 'center' }}>
                { activeQuestionNumber !== 1 && <Button
                    style={{ marginRight: 5 }}
                    onClick={ () => {  setAlertDirectionForward(false); previousItem(activeQuestionGroup.type !== welcomeMessageGroupType ? allMandotoryFiledsVerified : true); } }
                >Forrige emne</Button> }
                { activeQuestionNumber !== numberOfQuestions &&
                    <Button
                        style={{ marginRight: 5 }}
                        onClick={ () => { setAlertDirectionForward(true); nextItem(activeQuestionGroup.type !== welcomeMessageGroupType ? allMandotoryFiledsVerified : true); } }
                    >Næste emne</Button> }
                { (activeQuestionNumber === numberOfQuestions || allGroupsVerified) &&
                    <Button
                        onClick={ () => { setClosePage(true); } }
                    >Del med rådgiver</Button> }
            </div>
            <Dialog
                open={ showAlert }
                onClose={ () => setShowAlert(false) }
            >
                <DialogTitle>Du mangler at udfylde obligatoriske felter, er du sikker på, at du vil fortsætte?</DialogTitle>
                <DialogActions>
                    <Button onClick={ () => setShowAlert(false) }>
                        Nej
                    </Button>
                    <Button
                        onClick={ () => {
                            if(alertDirectionForward) nextItem(true);
                            else previousItem(true);
                            setShowAlert(false);
                        } }
                        autoFocus
                    >
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={ closePage }
                onClose={ () => setClosePage(false) }
            >
                { !pageClosed ?
                    <>
                        <DialogTitle> { !allGroupsVerified ?'Du mangler at udfylde obligatoriske informationer, er' : 'Er' } du sikker på at du vil afslutte og dele med rådgiver?
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={ () => setClosePage(false) }>
                                Nej
                            </Button>
                            <Button onClick={ handleFinish }>
                                Ja, afslut
                            </Button>
                        </DialogActions>
                    </>
                    : <DialogTitle>
                        Du kan nu lukke siden. { !allGroupsVerified && 'Du kan stadig vende tilbage senere og udfylde resten af informationerne senere.' }
                    </DialogTitle>
                }
            </Dialog>
        </div>
    );
}

Questions.propTypes = {
    processId: PropTypes.string.isRequired,
    partnerId: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired,
    startActiveQuestionGroup: PropTypes.object,
};
