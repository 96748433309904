import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { logout } from '../../actions/authActions';

const SideBarBottomContent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();

    const onLogout = useCallback(() => {
        dispatch(logout());
        history.push('/');
    }, [dispatch, history]);

    return (
        <div>
            <List disablePadding component="nav" aria-label="sidebar bottom menu">
                <ListItem
                    button
                    component={ RouterLink }
                    to="/profil"
                    selected={ pathname === '/profil' }
                >
                    <ListItemIcon>
                        <PersonOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profil" />
                </ListItem>
                <ListItem
                    button
                    component={ RouterLink }
                    to="/indstillinger"
                    selected={ pathname === '/indstillinger' }
                >
                    <ListItemIcon>
                        <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Indstillinger" />
                </ListItem>
                <ListItem
                    button
                    onClick={ onLogout }
                >
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Log ud" />
                </ListItem>
                { /* PLOP_INJECT_PAGE */ }
            </List>
            <img style={{ marginTop: '16px', marginLeft: '10px' }} src={ `${process.env.PUBLIC_URL}/assets/img/monax.png` } />
        </div>
    );
};

SideBarBottomContent.defaultProps = {};

SideBarBottomContent.propTypes = {};

export default SideBarBottomContent;
