export const calulateOpsparingSum = aftaler => {
    return aftaler?.map(x => x.opsparing ?? 0).reduce((sum, x) => sum + x);
};
export const getLatestScrape = scrapes => {
    if (!scrapes.length) return undefined;
    return sortScrapesByDate(scrapes)[0];
};

export const sortScrapesByDate = scrapes => {
    if (!scrapes.length) return undefined;
    return scrapes.sort((a, b) => {
        return new Date(b?.profil?.udskrevetDato) - new Date(a?.profil?.udskrevetDato);
    });
};
