import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

export default function InputComponent({
    value, name, disabled, onChange, variant, size, placeholder,
    inputPropsStyle, textAlign, disableBorder, renderFunc, rows,
    parserFunc, activeType, label, multiline, style, fullWidth,
    error, helperText }) {
    const [isActive, setIsActive] = useState(false);
    const [internalValue, setInternalValue] = useState(parserFunc(value));
    useEffect(() => setInternalValue(parserFunc(value)), [parserFunc, value]);

    function onFocus() {
        setIsActive(true);
    }

    function onBlur(event) {
        onChange(event.target.value);
        setIsActive(false);
    }

    function wrappredOnChange(event) {
        setInternalValue(event.target.value);
    }

    return (
        <TextField
            disabled={ disabled }
            sx={ disableBorder ? {
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    borderStyle: 'none',
                },
                '& .MuiInputBase-input': {
                    padding: 0,
                },
                '& .MuiOutlinedInput-inputMarginDense': {
                    padding: 0,
                },
            } : {} }
            name={ name }
            type={ isActive ? activeType : 'string' }
            value={ isActive ? internalValue : renderFunc(internalValue) }
            onChange={ wrappredOnChange }
            onFocus={ (e) => onFocus(e) }
            onBlur={ (e) => onBlur(e) }
            onKeyDown={ (e) => e.key === 'Enter' && document.activeElement.blur() }
            variant={ variant }
            size={ size }
            inputProps={{ style: { textAlign, ...inputPropsStyle } }}
            style={{ maxWidth: fullWidth ? '100%' : 150, ...style }}
            label={ label }
            multiline={ multiline }
            fullWidth={ fullWidth }
            placeholder={ placeholder }
            rows={ rows }
            error={ error }
            helperText={ helperText }
        />
    );
}

InputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    renderFunc: PropTypes.func,
    parserFunc: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string,
    inputPropsStyle: PropTypes.object,
    style: PropTypes.object,
    textAlign: PropTypes.string,
    disableBorder: PropTypes.bool,
    multiline: PropTypes.bool,
    activeType: PropTypes.string,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    error: PropTypes.bool,
    helperText: PropTypes.node,
};

InputComponent.defaultProps = {
    disabled: false,
    variant: 'outlined',
    size: 'small',
    inputPropsStyle: { },
    style: { },
    textAlign: 'center',
    disableBorder: false,
    multiline: false,
    renderFunc: x => x,
    parserFunc: x => x,
    activeType: 'string',
    fullWidth: false,
    error: false,
};
