import React from 'react';
import PropTypes from 'prop-types';
import InputComponent from './InputComponent';
import { isNullOrNaN } from '../../utils/helperFunctions';

export default function NumberInputComponent({ value, name, disabled, onChange, variant, size, inputPropsStyle, label, disableBorder, textAlign, style, fullWidth, placeholder }) {
    return (
        <InputComponent
            name={ name }
            value={ value }
            onChange={ x => {
                if(isNullOrNaN(x)) {
                    onChange(x);
                } else {
                    onChange(Number(x));
                }
            } }
            disabled={ disabled }
            inputPropsStyle={ inputPropsStyle }
            variant={ variant }
            size={ size }
            disableBorder={ disableBorder }
            textAlign={ textAlign }
            label={ label }
            activeType='number'
            style={ style }
            fullWidth={ fullWidth }
            placeholder={ placeholder }
        />
    );
}

NumberInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    inputPropsStyle: PropTypes.object,
    style: PropTypes.object,
    variant: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    textAlign: PropTypes.string,
    placeholder: PropTypes.string,
    disableBorder: PropTypes.bool,
    fullWidth: PropTypes.bool,
};
