import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button, TextField } from '@mui/material';

const Link = styled('div')(()=> ({
    marginLeft: 12,
    marginBottom: 12,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
        color: '#032d40',
    },
}));

const texts = {
    'User not found': 'Forkert kodeord',
    'Too many requests': 'For mange forsøg - prøv igen senere',
};

const LoginForm = (props) => {
    const { error } = props;
    return (
        <form
            onSubmit={ props.login }
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 40,
            }}
        >
            <div style={{
                fontSize: 15,
                color: '#032d40',
                marginBottom: 12,
            }}>Velkommen tilbage</div>
            <TextField
                value={ props.email }
                onChange={ (e) => props.setEmail(e.target.value) }
                color='secondary'
                autoFocus
                label="Email"
                variant="filled"
                required
                type="email"
                autoComplete="email"
                style={{ marginBottom: 31 }}
            />
            <TextField
                value={ props.password }
                onChange={ (e) => props.setPassword(e.target.value) }
                color='secondary'
                variant="filled"
                required
                label="Kodeord"
                type="password"
                autoComplete="current-password"
                error={ !!error }
                helperText={ texts[error?.message] }
            />
            <Link onClick={ () => props.setShowForgotPassword(true) }>Glemt kodeord?</Link>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="submit" disabled={ props.loading }>
                    Log ind
                </Button>
            </div>
        </form>
    );
};

LoginForm.defaultProps = {};

LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    setShowForgotPassword: PropTypes.func.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool,
};

export default LoginForm;
