import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '../UIComponents';
import PensionsInfoAftalerView from './PensionsInfoAftalerView';
import PensionsInfoFlereOplysingerView from './PensionsInfoFlereOplysingerView';
import PensionsInfoUdbetalingerView from './PensionsInfoUdbetalingerView';

const PensionsInfoView = ({ scrape }) => {
    const [value, setValue] = useState(0);
    const handleChange = newValue => {
        setValue(newValue);
    };
    if (!scrape) return null;

    const tabs = [{ label: 'Aftaler' }, { label: 'Udbetalinger' }, { label: 'Flere oplysninger' }];

    return (
        <div>
            <Tabs tabs={ tabs } setValue={ handleChange } value={ value } />
            { scrape.content && tabs[value].label === 'Aftaler' && <PensionsInfoAftalerView aftaler={ scrape.content.aftaler } profil={ scrape.content.profil } /> }
            { scrape.content && tabs[value].label === 'Udbetalinger' && <PensionsInfoUdbetalingerView scrape={ scrape } /> }
            { scrape.content && tabs[value].label === 'Flere oplysninger' && <PensionsInfoFlereOplysingerView pensionFlereOplysninger={ scrape.content.pensionFlereOplysninger } /> }
        </div>
    );
};

PensionsInfoView.propTypes = {
    scrape: PropTypes.shape({
        content: PropTypes.shape({
            profil: PropTypes.object.isRequired,
            aftaler: PropTypes.array.isRequired,
            udbetalinger: PropTypes.array.isRequired,
            pensionFlereOplysninger: PropTypes.array.isRequired,
        }),
    }),
};

export default PensionsInfoView;
