import omit from 'lodash/omit';
import { processesRef } from './processDataManager';

export function updateOnboardingProcess(process) {
    return processesRef(process.partnerId, process.customerId)
        .doc(process.processId).set(omit(process, ['partnerId', 'customerId', 'processId', 'isUpdating']));
}

export async function getSingleOnboardingProcess(partnerId, customerId, processId) {
    const process = await processesRef(partnerId, customerId).doc(processId).get();
    if(process.exists){
        const processData = process.data();
        return {
            ...processData,
            partnerId,
            customerId,
            processId: process.id,
        };
    }
    return { partnerId, customerId, processId };
}

export async function getAllOnboadringProccess(partnerId, customerId) {
    const processes = await processesRef(partnerId, customerId).where('type', '==', 'onboarding').get();
    const mappedData = await Promise.all(processes.docs.map(async p => {
        return {
            ...p.data(),
            partnerId,
            customerId,
            processId: p.id,
        };

    }));
    return mappedData;
}
