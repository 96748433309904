import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputComponent from './InputComponent';
import { isNullOrWhitespace } from '../../utils/helperFunctions';

export default function CPRInputComponent({ value, onChange, style, variant, helperText }) {
    const [error, setError] = useState(false);

    function validateInput(input) {
        if (isNullOrWhitespace(input)) return false;

        if(input.match(/[^\d]/g)) return false;

        if (input.length === 10 && input.match(/\d/g) && input.match(/\d/g).length === 10) {
            return true;
        }

        return false;
    }

    function renderCPR(cpr) {
        if(isNullOrWhitespace(cpr)) return cpr;
        if (cpr?.length <= 6) return cpr;
        return `${cpr.slice(0, 6)  }-${'X'.repeat(Math.min(cpr.slice(6).length, 4))}`;
    }

    return (
        <InputComponent
            name='CPR'
            value={ value }
            error={ error }
            helperText={ error ? 'Indtast et korrekt CPR nummer' : helperText }
            onChange={ x => { onChange(x); setError(!validateInput(x)); } }
            renderFunc={ x => renderCPR(x) }
            parserFunc={ x => x }
            textAlign='left'
            label='CPR nummer'
            variant={ variant }
            style={{ ...style }}
        />
    );
}

CPRInputComponent.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    variant: PropTypes.string,
    helperText: PropTypes.string,
};
