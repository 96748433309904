import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, FormControl, Select, CircularProgress } from '@mui/material';
import { fileTypeTranslations } from '../../pages/Kunder/Kunde/Files/fileTypes';
import { uploadFileOnboardingLink } from '../../actions/customerActions';

const Dropzone = styled('div')(()=> ({
    height: '100px',
    lineHeight: '90px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderColor: '#AAA',
    border: 1,
    background: 'repeating-linear-gradient(-55deg, #CCC, #CCC 10px, #DDD 10px, #DDD 20px)',
    textAlign: 'center',
    verticalAlign: 'middle',
}));

export default function UploadOnboardingFileModal({ customerId, specifiedFileType, onSave, spouse }) {
    const [ open, setOpen ] = useState(false);;
    const [file, setFile] = useState();
    const [fileType, setFileType] = useState(specifiedFileType);
    const dispatch = useDispatch();
    const [ uploading, setUploading ] = useState(false);

    useEffect(() => setFileType(specifiedFileType), [specifiedFileType]);

    const handleFileTypeChange = e => setFileType(e.target.value);

    const handleSave = async () => {
        setUploading(true);
        setOpen(false);
        file.fileType = fileType;
        const { fileId, fileName } = await dispatch(uploadFileOnboardingLink(customerId, file, spouse));
        onSave(fileId, fileName);
        // reset file upload modal
        setFile(undefined);
        setFileType(specifiedFileType);
        setUploading(false);
    };

    const onDrop = useCallback(acceptedFiles => {
        setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const fileTypeArr = Object.entries(fileTypeTranslations).map(([key, value]) => ({
        key,
        value,
    }));

    return (
        <div>
            <Button onClick={ () => setOpen(true) } disabled={ uploading }>
                { uploading ? <CircularProgress size={ 20 } color="secondary" /> : 'Tilføj fil' }
            </Button>
            <Dialog open={ open } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Upload fil</DialogTitle>
                <DialogContent style={{ minWidth: 500 }}>
                    <Dropzone { ...getRootProps() }>
                        <input { ...getInputProps() } />
                        { file && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <p style={{ margin: 0, fontSize: 14 }}>{ file.name }</p>
                            </div>
                        ) }
                        { (!file && isDragActive) ? (
                            'Træk en fil hertil for at uploade'
                        ) : (
                            'Træk en fil hertil for at uploade, eller klik for at vælge fil'
                        ) }
                    </Dropzone>
                    { !fileType && (
                        <FormControl sx={{ m: 1 }} variant='standard' style={{ minWidth: 120 }}>
                            <InputLabel htmlFor="type-native-simple">Type</InputLabel>
                            <Select
                                native
                                label="Type"
                                value={ fileType }
                                onChange={ handleFileTypeChange }
                                inputProps={{
                                    name: 'type',
                                    id: 'type-native-simple',
                                }}
                            >
                                <option aria-label="None" value={ undefined }>
                                    Ukendt
                                </option>
                                { fileTypeArr.map(({ key, value }) => (
                                    <option value={ key } key={ key }>
                                        { value }
                                    </option>
                                )) }
                            </Select>
                        </FormControl>
                    ) }
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => setOpen(false) }>
                        Fortryd
                    </Button>
                    <Button onClick={ handleSave } disabled={ !file }>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

UploadOnboardingFileModal.propTypes = {
    customerId: propTypes.string.isRequired,
    specifiedFileType: propTypes.string,
    onSave: propTypes.func.isRequired,
    spouse: propTypes.bool.isRequired,
};

UploadOnboardingFileModal.defaultProps = {
    specifiedFileType: undefined,
    spouse: false,
};
