import algo from 'algostrata';
import { isNullOrNaN, isNullOrUndefined } from './helperFunctions';

function range(start, end) { return [...Array(1+end-start).keys()].map(v => start+v); }

/**
 * Calculates the sum of the provided assets sum. Assets with no value (i.e null/undefined/NaN) are disregarded
 * If the provided asset array is null or undefinde zero will be returned
 * @param {Asset[]} assets - an array of assets
 * @returns {number} - The sum of the asset array
 */
export function getAssetsSum(assets) {
    if(isNullOrUndefined(assets)) return NaN;

    return assets.filter(asset => asset).reduce((accumulator, asset) => {
        return isNullOrNaN(asset.value) ? accumulator : accumulator + Number(asset.value);
    }, 0);
}

export function generateProjection(initialValue, startYear, endYear, returnPerYearSuggested, returnPerYearCurrent ){
    const numberOfStandardDeviationsArray = [-2, -1, 0, 1, 2];
    const years = range(startYear, endYear);

    return years.map((year,atYear) => {
        const valuesSuggested = numberOfStandardDeviationsArray.map(nStd => (
            Math.round(algo.valueAtYearWithStandardDeviation(initialValue, returnPerYearSuggested, nStd, atYear), 0)
        ));
        const valuesCurrent = numberOfStandardDeviationsArray.map(nStd => (
            Math.round(algo.valueAtYearWithStandardDeviation(initialValue, returnPerYearCurrent, nStd, atYear), 0)
        ));
        return {
            year,
            'std2_suggested':[ valuesSuggested[0], valuesSuggested[4]],
            'std1_suggested':[ valuesSuggested[1], valuesSuggested[3]],
            'average_suggested': valuesSuggested[2],
            'std2_current':[ valuesCurrent[0], valuesCurrent[4]],
            'std1_current':[ valuesCurrent[1], valuesCurrent[3]],
            'average_current': valuesCurrent[2],
        };
    });

}
export function generateDifferenceProjection(initialValue, returnPerYear, baseCostPerYear, newCostFirstYear, newCostPerYear, startYear, intervals){
    const years = intervals.map(interval => startYear + interval);

    const data = years.map(year => ({
        year,
        difference: Math.round(algo.differenceAfterYearWithFirstYear(initialValue, returnPerYear, baseCostPerYear, newCostFirstYear, newCostPerYear, year - startYear), 0),
    }));
    return data;
}

export function getCostComparison(costResults, oneTimeManagementCost, portfolioSum) {
    const currentCosts = costResults[0];
    const targetCosts = costResults[1];
    const currentCostsInKr = currentCosts.Total.value;
    const targetCostsInKr = targetCosts.Total.value;
    const targetCostsFirstYearInKr = targetCostsInKr + oneTimeManagementCost;

    const savingInKr = currentCostsInKr - targetCostsInKr;
    const savingFirstYearInKr = currentCostsInKr - targetCostsFirstYearInKr;

    const data = {
        currentCosts,
        targetCosts,
        portfolioSum,
        oneTimeManagementCost,
        currentCostsInKr,
        targetCostsInKr,
        targetCostsFirstYearInKr,
        savingInKr,
        savingFirstYearInKr,
        currentCostsInPercent: currentCostsInKr / portfolioSum,
        targetCostsInPercent: targetCostsInKr / portfolioSum,
        targetCostsFirstYearInPercent: targetCostsFirstYearInKr / portfolioSum,
        relativeSavingFirstYearInPercent: savingFirstYearInKr / currentCostsInKr,
        relativeSavingInPercent: savingInKr / currentCostsInKr,
    };

    return data;
}

export function equals(valuaA, valueB = 1, precision = 0.00001) {
    return Math.abs(valuaA - valueB)  < precision;
}

/**
 * Checks if an asset is rated
 * @param {Asset} asset - The asset
 * @returns {boolean} - True if the asset is rated
 */
export function assetIsRated(asset) {
    return !isNullOrUndefined(asset) && !isNullOrNaN(asset.msStars) && asset.msStars >= 0;
}

/**
 * Calculates the mean rating of the provided assets. Assets with no value are disregarded (i.e null/undefined/NaN).
 * When calculating the mean rating only assets with a Morningstar rating is used.
 * If there are no assets with an rating meanRating will be NaN
 * If assets is null or undefined or there are no assets with a valid value ratedAssetFraction will be NaN and meanRating will be NaN.
 * @param {Asset[]} assets - An array of assets
 * @returns {Object.<MeanRating>} - The mean rating object
 */
export function calculateMeanRating(assets) {
    if(!assets) return { ratedAssetFraction: NaN, meanRating: NaN };

    const assetWithValue = assets.filter(asset => asset).map(asset => ({ value: Number(asset.value), msStars: asset.msStars })).filter(asset => !isNullOrNaN(asset.value) && asset.value >= 0);
    const totalAssetSum = getAssetsSum(assetWithValue);

    const ratedAssets = assetWithValue.filter(assetIsRated).map(asset => ({ value: asset.value, msStars: Number(asset.msStars) }));
    const totalRatedAssetSum = getAssetsSum(ratedAssets);

    const ratedAssetFraction = totalRatedAssetSum / totalAssetSum;

    const meanRating = ratedAssets.reduce((accumulator, asset) => accumulator + (asset.value * asset.msStars), 0) / totalRatedAssetSum;

    return { ratedAssetFraction, meanRating };
}

/**
 * @typedef {Object} MeanRating
 * @property {number} ratedAssetFraction - The fraction of the assets that is rated
 * @property {number} meanRating - The mean rating of the rated assets
 */

/**
 * @typedef {Object} Asset
 * @property {number} value - The value of the asset
 * @property {number} msStars - The morningstar rating
 */
