import axios from 'axios';
import { config } from '../config';

const request = axios.create({ baseURL: config.teal.apiUrl });
const newayRequest = axios.create({ baseURL: config.teal.newayApiUrl });

let token;

// eslint-disable-next-line import/no-mutable-exports
export let setToken;
const ready = new Promise((res) => {
    setToken = (newToken) => {
        token = newToken;
        res();
    };
});

request.interceptors.request.use(configuration => {
    configuration.headers.authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
    return configuration;
});

newayRequest.interceptors.request.use(configuration => {
    configuration.headers.authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
    return configuration;
});

export async function getUser() {
    const result = await request('/user');
    return result.data.user;
}

export async function getNewayUsers() {
    await ready;
    const result = await request('/access/users');
    return result.data.users;
}

export async function createSharing(sharing) {
    const { data } = await request({
        method: 'post',
        url: '/access/sharings',
        data: sharing,
    });

    return data.sharing;
}

export async function getSharing(sharingId) {
    await ready;
    const { data } = await request(`/access/sharings/${sharingId}`);

    return data.sharing;
}

export async function getSharings() {
    await ready;
    const { data } = await request('/access/sharings');

    return data.sharings;
}


export async function getNewayUser(userId) {
    await ready;
    const response = await newayRequest({
        url: '/user',
        headers: { 'x-user-id': userId },
    });
    return response.data.user;
}

export async function getUsersFiles(userID) {
    await ready;
    const response = await newayRequest({
        url: '/user/files',
        headers: { 'x-user-id': userID },
    });
    return response.data.files;
}

export async function getUsersFileBinary(userId, fileId) {
    const response = await newayRequest({
        url: `/user/files/${fileId}/binary`,
        headers: { 'x-user-id': userId },
        responseType: 'blob',
    });
    return response.data;
}

export async function getScrape({ scrapeId }) {
    const response = await request(`/pensions-info-scrapes/${scrapeId}`);
    return response.data.scrape;
}

export async function createScrape({ pdf }) {
    const formData = new FormData();
    formData.set('pensionsInfoPdf', pdf);

    const response = await request({
        method: 'post',
        url: '/pensions-info-scrapes',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data.scrape;
}
export async function createScrapeFromContent({ content }) {
    const { data } = await request({
        method: 'post',
        url: '/pensions-info-scrapes/with-content',
        data: { content },
    });

    const scrape = {
        owner: data.scrape.owner,
        status: data.scrape.status,
        externalAccessToken: data.scrape.externalAccessToken,
        source: data.scrape.source,
        createdAt: data.scrape.createdAt,
        updatedAt: data.scrape.updatedAt,
        scrapeId: data.scrape.scrapeId,
    };

    return scrape;
}

export async function getFileFromScrape(scrapeId) {
    const response = await request({
        url: `/pensions-info-scrapes/${scrapeId}/file`,
        responseType: 'blob',
    });
    return new Promise((res) => {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = () => res(reader.result);
    });
}
export async function deleteScrape(scrapeId) {
    const { data } = await request({
        method: 'delete',
        url: `/pensions-info-scrapes/${scrapeId}`,
    });
    return data.sharing;
}
