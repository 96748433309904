// User data
export const USER_DATA_UPDATED = 'USER_DATA_UPDATED';
export const USER_DATA_ADDED = 'USER_DATA_ADDED';
export const USER_DATA_LOADED = 'USER_DATA_LOADED';
export const USER_DATA_FETCHING_CHANGED = 'USER_DATA_FETCHING_CHANGED';
export const USER_RESET_STATE = 'USER_RESET_STATE';
export const USER_DATA_SCRAPER_FAIL = 'USER_DATA_SCRAPER_FAIL';

export const WEALTH_DATA_UPDATED = 'WEALTH_DATA_UPDATED';
export const WEALTH_DATA_LOADED = 'WEALTH_DATA_LOADED';
export const WEALTH_DATA_FETCHING_CHANGED = 'WEALTH_DATA_FETCHING_CHANGED';

export const CALCULATION_DATA_ADDED = 'CALCULATION_DATA_ADDED';
export const CALCULATION_DATA_UPDATED = 'CALCULATION_DATA_UPDATED';
export const CALCULATION_DATA_LOADED = 'CALCULATION_DATA_LOADED';
export const CALCULATION_DATA_REQUESTED = 'CALCULATION_DATA_REQUESTED';
export const CALCULATION_DATA_FAIL = 'CALCULATION_DATA_FAIL';

export const CUSTOMER_DATA_ADDED = 'CUSTOMER_DATA_ADDED';
export const CUSTOMER_DATA_UPDATED = 'CUSTOMER_DATA_UPDATED';
export const CUSTOMER_ALL_DATA_UPDATED = 'CUSTOMER_ALL_DATA_UPDATED';
export const CUSTOMER_DATA_LOADED = 'CUSTOMER_DATA_LOADED';
export const CUSTOMER_DATA_FETCHING_CHANGED = 'CUSTOMER_DATA_FETCHING_CHANGED';
export const CUSTOMER_DATA_UPDATE_REQUEST = 'CUSTOMER_DATA_UPDATE_REQUEST';
export const CUSTOMER_FILE_UPLOAD_REQUEST = 'CUSTOMER_FILE_UPLOAD_REQUEST';
export const CUSTOMER_FILE_SCRAPE_REQUEST = 'CUSTOMER_FILE_SCRAPE_REQUEST';
export const CUSTOMER_FILE_DELETE_REQUEST = 'CUSTOMER_FILE_DELETE_REQUEST';

export const DOCUMENT_DATA_UPDATED = 'DOCUMENT_DATA_UPDATED';
export const DOCUMENT_DATA_REQUESTED = 'DOCUMENT_DATA_REQUESTED';
export const DOCUMENT_DATA_SUCCESS = 'DOCUMENT_DATA_SUCCESS';
export const DOCUMENT_DATA_FAIL = 'DOCUMENT_DATA_FAIL';
export const DOCUMENTS_UPDATED = 'DOCUMENTS_UPDATED';
export const DOCUMENT_DATA_LOADED = 'DOCUMENT_DATA_LOADED';

export const PENSION_CALCULATION_REQUESTED = 'PENSION_CALCULATION_REQUESTED';
export const PENSION_DATA_REQUESTED = 'PENSION_DATA_REQUESTED';
export const PENSION_DATA_SUCCESS = 'PENSION_DATA_SUCCESS';
export const PENSION_DATA_FAIL = 'PENSION_DATA_FAIL';
export const PENSION_CALCULATION_FAIL = 'PENSION_CALCULATION_FAIL';
export const PENSION_INFO_REQUESTED = 'PENSION_INFO_REQUESTED';
export const PENSION_DATA_SCRAPER_FAIL = 'PENSION_DATA_SCRAPER_FAIL';
export const PENSION_CALCULATION_SUCCESS = 'PENSION_CALCULATION_SUCCESS';
// Pension data
export const PENSION_DATA_LOADED = 'PENSION_DATA_LOADED';
export const PENSION_DATA_UPDATED = 'PENSION_DATA_UPDATED';
export const PENSION_INFO_SUCCESS = 'PENSION_INFO_SUCCESS';

// Auth data
export const AUTH_DATA_LOADED = 'AUTH_DATA_LOADED';
export const AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED';
export const AUTH_DATA_UPDATED = 'AUTH_DATA_UPDATED';
export const AUTH_DATA_REQUESTED = 'AUTH_DATA_REQUESTED';
export const AUTH_NOT_LOGGED_IN = 'AUTH_NOT_LOGGED_IN';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const AUTH_DATA_FAIL_VERIFICATION = 'AUTH_DATA_FAIL_VERIFICATION';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const AUTH_PASSWORD_RESET = 'AUTH_PASSWORD_RESET';
export const AUTH_REDIRECT = 'AUTH_REDIRECT';
export const AUTH_CREATE_USER_SUCCESS = 'AUTH_CREATE_USER_SUCCESS';

// Provider data
export const PROVIDERS_LOADED = 'PROVIDERS_LOADED';
export const PROVIDERS_REQUESTED = 'PROVIDERS_REQUESTED';


export const PENSION_DATA_PENSIONS_UPDATED = 'PENSION_DATA_PENSIONS_UPDATED';
export const PENSION_DATA_FETCHING_CHANGED = 'PENSION_DATA_FETCHING_CHANGED';
export const PENSION_DATA_UPDATED_DELTA = 'PENSION_DATA_UPDATED_DELTA';

export const AAOP_DATA_REQUESTED = 'AAOP_DATA_REQUESTED';
export const AAOP_DATA_UPDATED = 'AAOP_DATA_UPDATED';
export const AAOP_DATA_FAIL = 'AAOP_DATA_FAIL';

export const PROCESS_DATA_UPDATED = 'PROCESS_DATA_UPDATED';
export const PROCESS_DATA_LOADED = 'PROCESS_DATA_LOADED';
export const PROCESS_DATA_FIELD_UPDATED = 'PROCESS_DATA_FIELD_UPDATED';

export const PROCESS_DATA_REQUESTED = 'PROCESS_DATA_REQUESTED';
export const PROCESSES_DATA_UPDATED = 'PROCESSES_DATA_UPDATED';
export const PROCESSES_DATA_UPDATE_REQUEST = 'PROCESSES_DATA_UPDATE_REQUEST';

export const ADVISOR_DATA_UPDATED = 'ADVISOR_DATA_UPDATED';
export const ADVISOR_DATA_ADDED = 'ADVISOR_DATA_ADDED';
export const ADVISOR_DATA_MULTIPLE_ADDED = 'ADVISOR_DATA_MULTIPLE_ADDED';
export const ADVISOR_DATA_UPDATE_REQUEST = 'ADVISOR_DATA_UPDATE_REQUEST';
export const ADVISOR_DATA_SET_CURRENT = 'ADVISOR_DATA_SET_CURRENT';

export const INVEST_DATA_LOAD_REQUEST = 'INVEST_DATA_LOAD_REQUEST';
export const INVEST_DATA_UPDATED = 'INVEST_DATA_UPDATED';
export const INVEST_DATA_BULK_UPDATED = 'INVEST_DATA_BULK_UPDATED';
export const INVEST_DATA_LOADED = 'INVEST_DATA_LOADED';
export const INVEST_CHECK_REQUESTED = 'INVEST_CHECK_REQUESTED';
export const INVEST_CHECK_SUCCESS = 'INVEST_CHECK_SUCCESS';
export const INVEST_CHECK_ALLOCATION_SUCCESS = 'INVEST_CHECK_ALLOCATION_SUCCESS';
export const INVEST_CHECK_FAIL = 'INVEST_CHECK_FAIL';
export const INVEST_ASSET_DATA_UPDATED = 'INVEST_ASSET_DATA_UPDATED';
export const INVEST_ASSET_BULK_DATA_UPDATED = 'INVEST_ASSET_BULK_DATA_UPDATED';
export const INVEST_ASSET_UPDATED = 'INVEST_ASSET_UPDATED';
export const INVEST_ASSET_ADDED = 'INVEST_ASSET_ADDED';
export const INVEST_ASSET_DELETED = 'INVEST_ASSET_DELETED';
export const INVEST_PORTFOLIO_DATA_UPDATED = 'INVEST_PORTFOLIO_DATA_UPDATED';
export const INVEST_PORTFOLIO_BULK_DATA_UPDATED = 'INVEST_PORTFOLIO_BULK_DATA_UPDATED';

export const MIFID_PROCESSES_LOAD_REQUEST = 'MIFID_PROCESSES_LOAD_REQUEST';
export const MIFID_PROCESSES_LOADED = 'MIFID_PROCESSES_LOADED';
export const MIFID_PROCESSES_FIELD_UPDATED = 'MIFID_PROCESSES_FIELD_UPDATED';
export const MIFID_PROCESSES_MIFID_DATA_UPDATED = 'MIFID_PROCESSES_MIFID_DATA_UPDATED';
export const MIFID_PROCESSES_MIFID_DATA_FIELD_UPDATED = 'MIFID_PROCESSES_MIFID_DATA_FIELD_UPDATED';
export const MIFID_PROCESSES_UPDATING = 'MIFID_PROCESSES_UPDATING';
export const MIFID_PROCESSES_UPDATED = 'MIFID_PROCESSES_UPDATED';
export const MIFID_PROCESSES_ADDED = 'MIFID_PROCESSES_ADDED';

export const ONBOARDING_PROCESSES_LOAD_REQUEST = 'ONBOARDING_PROCESSES_LOAD_REQUEST';
export const ONBOARDING_PROCESSES_LOADED = 'ONBOARDING_PROCESSES_LOADED';
export const ONBOARDING_PROCESSES_UPDATING = 'ONBOARDING_PROCESSES_UPDATING';
export const ONBOARDING_PROCESSES_UPDATED = 'ONBOARDING_PROCESSES_UPDATED';
export const ONBOARDING_PROCESSES_FIELD_UPDATED = 'ONBOARDING_PROCESSES_FIELD_UPDATED';
export const ONBOARDING_PROCESSES_ADDED = 'ONBOARDING_PROCESSES_ADDED';

export const MODEL_PORTFOLIO_DATA_UPDATED = 'MODEL_PORTFOLIO_DATA_UPDATED';
export const MODEL_PORTFOLIO_DATA_LOADED = 'MODEL_PORTFOLIO_DATA_LOADED';

export const REPORT_REQUESTED = 'REPORT_REQUESTED';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_FAIL = 'REPORT_FAIL';

// Partner data
export const PARTNER_DATA_REQUESTED = 'PARTNER_DATA_REQUESTED';
export const PARTNER_DATA_UPDATED = 'PARTNER_DATA_UPDATED';
export const PARTNER_DATA_CUSTOM_ASSET_ADDED = 'PARTNER_DATA_CUSTOM_ASSET_ADDED';
export const PARTNER_DATA_CUSTOM_ASSET_UPDATED = 'PARTNER_DATA_CUSTOM_ASSET_UPDATED';
export const PARTNER_DATA_CUSTOM_ASSET_FIELD_UPDATED = 'PARTNER_DATA_CUSTOM_ASSET_FIELD_UPDATED';
export const PARTNER_DATA_CUSTOM_ASSET_DELETED = 'PARTNER_DATA_CUSTOM_ASSET_DELETED';
export const PARTNER_DATA_DEFAULT_VALUE_UPDATED = 'PARTNER_DATA_DEFAULT_VALUE_UPDATED';
export const PARTNER_DATA_MODEL_PORTFOLIO_ADDED = 'PARTNER_DATA_MODEL_PORTFOLIO_ADDED';
export const PARTNER_DATA_MODEL_PORTFOLIO_ALL_UPDATED = 'PARTNER_DATA_MODEL_PORTFOLIO_ALL_UPDATED';
export const PARTNER_DATA_MODEL_PORTFOLIO_FIELD_UPDATED = 'PARTNER_DATA_MODEL_PORTFOLIO_FIELD_UPDATED';
export const PARTNER_DATA_MODEL_PORTFOLIO_ASSET_ADDED = 'PARTNER_DATA_MODEL_PORTFOLIO_ASSET_ADDED';
export const PARTNER_DATA_MODEL_PORTFOLIO_ASSET_DELETED = 'PARTNER_DATA_MODEL_PORTFOLIO_ASSET_DELETED';
export const PARTNER_DATA_MODEL_PORTFOLIO_ASSET_UPDATED = 'PARTNER_DATA_MODEL_PORTFOLIO_ASSET_UPDATED';
export const PARTNER_DATA_MODEL_PORTFOLIO_ASSET_FIELD_UPDATED = 'PARTNER_DATA_MODEL_PORTFOLIO_ASSET_FIELD_UPDATED';
export const PARTNER_DATA_MIFID_QUESTIONNAIRE_UPDATED = 'PARTNER_DATA_MIFID_QUESTIONNAIRE_UPDATED';

export const SITE_LOADING = 'SITE_LOADING';
export const SITE_CHANGED = 'SITE_CHANGED';

export const ADVICE_MODULE_DATA_UPDATED = 'ADVICE_MODULE_DATA_UPDATED';

export const CONFIG_CHANGED = 'CONFIG_CHANGED';

export const INTEGRATIONS_UPDATED = 'INTEGRATIONS_UPDATED';
export const INTEGRATIONS_LOADING = 'INTEGRATIONS_LOADING';
export const INTEGRATIONS_RESET = 'INTEGRATIONS_RESET';
export const INTEGRATIONS_FAILED = 'INTEGRATIONS_FAILED';

export const UNIIFY_INVITE_SUCCESS = 'UNIIFY_INVITE_SUCCESS';
export const UNIIFY_FETCH_SUCCESS = 'UNIIFY_FETCH_SUCCESS';
export const UNIIFY_FETCH_PDF_SUCCESS = 'UNIIFY_FETCH_PDF_SUCCESS';
