import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getFundAsync, getFunds } from '../../apiRepository/investApiManager';
import { renderKr, renderPercent } from '../../utils/renderingService';
import { assetIsRated, calculateMeanRating, getAssetsSum } from '../../utils/calc';
import { CurrencyInputComponent, AutocompleteAsync, TextInputComponent } from '../InputComponents';
import { WarningIcon, ErrorIcon } from '../UIComponents';

const StyledTableCell = styled(TableCell)(()=> ({
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
}));

export default function AssetsTable(props) {
    const { editable, assets, onAddAsset, onEditAsset, onEditAssetField, onDeleteAsset, isCurrentPortfolio } = props;

    function onUpdateAsset(index, name, value) {
        onEditAssetField(index, name, value);
    }

    async function onChange(index, id) {
        const fund = await getFundAsync(id);
        onEditAsset(index, {
            id: fund?.id ?? null,
            name: fund?.name ?? null,
            isin: fund?.isin ?? null,
            msStars: fund?.stars ?? null,
            msStarsDate: fund?.starsDate ?? null,
            calculationSettings: null,
            firstPriceDate: null ,
        });
    }

    const { meanRating, ratedAssetFraction } = useMemo(() => calculateMeanRating(assets), [assets]);

    const uniqueDates = useMemo(() => [...new Set(assets.map(x => x.msStarsDate).filter(y => y))], [assets]);

    const uniqueDatesHTML = useMemo(() => {
        return uniqueDates.map((x, index) => {
            const date = new Date(x);
            return <p key={ x + 1 } style={{ fontSize: 'small' }}><sup key={ x+2 }>{ index + 1 }</sup> As of { date.toLocaleDateString('da-DK') }</p>;
        });
    }, [uniqueDates]);

    const assetSum = useMemo(() => getAssetsSum(assets), [assets]);

    return (
        <div>
            <Paper variant="outlined">
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#f9fafb', width: '340px', fontSize: '12px', fontWeight: '700' }}>Navn</TableCell>
                            <TableCell style={{ backgroundColor: '#f9fafb', width: '184px', fontSize: '12px', fontWeight: '700' }}>Fondskode</TableCell>
                            <TableCell style={{ backgroundColor: '#f9fafb', width: '130px', fontSize: '12px', fontWeight: '700' }}>Værdi</TableCell>
                            <TableCell style={{ backgroundColor: '#f9fafb', width: '70px', fontSize: '12px', fontWeight: '700' }}>Morningstar Rating&trade;</TableCell>
                            { editable && <TableCell style={{ backgroundColor: '#f9fafb', width: '20px', fontSize: '12px', fontWeight: '700' }}/> }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { assets.map((asset, index) => {
                            const mappedAsset = asset.id === null ? null : { id: asset.id, name: asset.name, isin: asset.isin };
                            return(
                                <TableRow
                                    key={ index } // eslint-disable-line react/no-array-index-key
                                >
                                    <TableCell style={{ padding: 5 }}>
                                        <div style={{ position: 'relative' }}>
                                            { editable ?
                                                <AutocompleteAsync
                                                    fieldLabel='Fondsnavn'
                                                    onChange={ value => onChange(index, value?.id) }
                                                    value={ mappedAsset }
                                                    getOptions={ getFunds }
                                                    searchField='name'
                                                    alternativeSearchField='isin'
                                                />
                                                :
                                                <TextInputComponent
                                                    disabled={ true }
                                                    name='Fondsavn'
                                                    value={ mappedAsset.name }
                                                    onChange={ () => { } }
                                                    disableBorder
                                                    fullWidth
                                                    textAlign='left'
                                                />
                                            }
                                            { asset.obsolete && <ErrorIcon style={{ position: 'absolute',  right: '0',   top: '-3px' }}/> }
                                        </div>
                                    </TableCell>
                                    <StyledTableCell>
                                        { editable ?
                                            <AutocompleteAsync
                                                fieldLabel='Fondskode'
                                                onChange={ value => onChange(index, value?.id) }
                                                value={ mappedAsset }
                                                getOptions={ getFunds }
                                                searchField='isin'
                                                alternativeSearchField='name'
                                            />
                                            :
                                            <TextInputComponent
                                                disabled={ true }
                                                name='Fondskode'
                                                value={ mappedAsset.isin }
                                                onChange={ () => { } }
                                                disableBorder
                                                fullWidth
                                                textAlign='left'
                                            />
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <CurrencyInputComponent
                                            disabled={ !editable }
                                            name="value"
                                            value={ parseFloat(asset.value) }
                                            onChange={ value => onUpdateAsset(index, 'value', value) }
                                            textAlign='left'
                                            disableBorder={ true }
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        { (() => {
                                            if (!assetIsRated(asset)) return <p style={{ fontSize: '14px', fontFamily: 'sans-serif', display: 'inline-block' }}>Ikke ratet</p>;

                                            const dateIndex = uniqueDates.indexOf(asset.msStarsDate) + 1;
                                            const wholeStars = Math.floor(asset.msStars);
                                            const fraction = asset.msStars % 1;
                                            const starStr = '\u2605';
                                            return (
                                                <p style={{ display: 'inline-block' }}>
                                                    <span style={{ fontFamily: 'Wingdings' }}>
                                                        { starStr.repeat(wholeStars) }
                                                        { fraction > 0 && <span style={{ 'clipPath': `inset(0 ${100-(fraction*100)}% 0 0)` }}>{ starStr }</span> }
                                                    </span>
                                                    <sup style={{ fontSize: 'x-small' }}>{ (dateIndex < 1 ? '': dateIndex) }</sup>
                                                </p>
                                            );
                                        })() }
                                    </StyledTableCell>

                                    { editable && (
                                        <StyledTableCell
                                            align="center"
                                            style={{ padding: 0, borderLeft: 0, minWidth: 20 }}
                                        >
                                            <Tooltip title='Slet Række'>
                                                <IconButton onClick={ () => onDeleteAsset(index) } size="large">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </StyledTableCell>
                                    ) }
                                </TableRow>
                            );
                        } ) }
                    </TableBody>
                </Table>
            </Paper>
            { assets.some(x => x.obsolete) &&
                <div>
                    <ErrorIcon style={{ fontSize: 'small' }}/>
                    &#160;
                    <p style={{ display:'inline', verticalAlign:'middle', fontSize: 'small' }}>Der er udgåede aktiver i porteføljen!</p>
                </div>
            }
            { uniqueDates !== undefined && uniqueDates.length > 0 && (
                <div>
                    { uniqueDatesHTML }
                </div>
            ) }

            <div className="space-between">
                { editable ? (
                    <Button
                        style={{ marginTop:8 }}
                        onClick={ () => onAddAsset() }
                    >
                        + Tilføj række
                    </Button>
                ) : (
                    <div />
                ) }

                <div style={{ marginRight: 50 }}>
                    <div>
                        <h3 style={{ marginBottom: 0 }}>{ renderKr(assetSum, 1) }</h3>
                        <p style={{ marginTop: 0, color: '#a0a0a0' }}>Samlet total</p>
                    </div>
                    { (() => {

                        if (meanRating == null || Number.isNaN(meanRating) || ratedAssetFraction < 0.5)  return;

                        const wholeStars = Math.floor(meanRating);
                        const fraction = meanRating % 1;
                        const starStr = '\u2605';
                        return (
                            <div>
                                <h3 style={{ marginBottom: 0, display: 'inline-block' }}>
                                    <p style={{ fontFamily: 'Wingdings' }}>
                                        { starStr.repeat(wholeStars) }
                                        { fraction > 0 && <span style={{ 'clipPath': `inset(0 ${100-(fraction*100)}% 0 0)` }}>{ starStr }</span> }
                                    </p>
                                </h3>
                                <p style={{ marginTop: 0,marginBottom:24, color: '#a0a0a0' }}>Vægtet gennemsnit</p>
                            </div>
                        );
                    })() }
                </div>
            </div>
            { ratedAssetFraction < 0.5 &&
            <div>
                <WarningIcon style={{ verticalAlign:'middle' }}/>
                &#160;
                <p style={{ display:'inline', verticalAlign:'middle', fontSize: 'small' }}>
                    Der er ikke relevant at beregne &quot;gennemsnitligt antal stjerner,&quot; idet mere end 50% af den samlede volumen
                    findes i papirer, som er mindre end 3 år gamle og dermed endnu ikke ratet hos Morningstar.
                </p>
            </div>
            }
            { ratedAssetFraction >= 0.5 && ratedAssetFraction < 1 &&
            <div>
                <WarningIcon style={{ verticalAlign:'middle' }}/>
                &#160;
                <p style={{ display:'inline', verticalAlign:'middle', fontSize: 'small' }}>
                    De gennemsnitlige antal stjerner på den { isCurrentPortfolio ? 'eksisterende' : 'anbefalede' } portefølje er ikke fuldstændig,
                    idet { renderPercent(1-ratedAssetFraction) } af porteføljens investeringsforeninger ikke er ratet,
                    da de er under 3 år gamle (Morningstar rater kun fonde med minimum 3 års historik).
                    Sammenligningen mellem det gennemsnitlige antal stjerner i den eksisterende portefølje
                    og den anbefalede portefølje er derfor ikke fuldstændig og dermed forbundet med usikkerhed!
                </p>
            </div>
            }
        </div>
    );
}

AssetsTable.propTypes = {
    editable: PropTypes.bool,
    assets: PropTypes.array.isRequired,
    onAddAsset: PropTypes.func,
    onEditAsset: PropTypes.func,
    onEditAssetField: PropTypes.func,
    onDeleteAsset: PropTypes.func,
    isCurrentPortfolio : PropTypes.bool.isRequired,
};

AssetsTable.defaultProps = {
    editable: false,
    onAddAsset: () => {},
    onEditAsset: () => {},
    onEditAssetField: () => {},
    onDeleteAsset: () => {},
};
