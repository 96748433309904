import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

const WarningButton = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleAccept = (e) => {
        setOpen(false);
        props.onClick(e);
    };
    return (
        <>
            <Button
                sx={{
                    '&.MuiButton-root': {
                        backgroundColor: '#F44336',
                        '&:hover': {
                            background: '#f00',
                        },
                        color: 'white',
                    },
                }}
                onClick={ handleClickOpen }
            >
                { props.children }
            </Button>
            <Dialog
                open={ open }
                onClose={ handleClose }
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{ props.title }</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        { props.description }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } color='secondary'>
                        { props.disagreeButtonText }
                    </Button>
                    <Button
                        onClick={ handleAccept }
                        autoFocus
                    >
                        { props.agreeButtonText }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

WarningButton.defaultProps = {
    agreeButtonText: 'Fortsæt',
    disagreeButtonText: 'Fortryd',
};

WarningButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    agreeButtonText: PropTypes.string,
    disagreeButtonText: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default WarningButton;
