import React from 'react';
import PropTypes from 'prop-types';
import PageActions from '../PageActions';

const SectionActions = (props) => (
    <PageActions id="page-actions">
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
        }}>{ props.children }</div>
    </PageActions>
);

SectionActions.defaultProps = {};

SectionActions.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default SectionActions;
