export function renderNumber(x, pct = false, maximumDigits = 0, minimumDigits = 0) {
    if (x === undefined) {
        return 0;
    }
    if (pct === false) {
        return `${x.toLocaleString('da', {
            minimumFractionDigits: minimumDigits,
            maximumFractionDigits: maximumDigits,
        })} kr.`;
    }
    return `${x.toLocaleString('da', {
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: maximumDigits,
    })} %`;
}

export function renderNum(x, maximumDigits = 2, minimumDigits = 2) {
    if (x === undefined) {
        return 0;
    }
    return x.toLocaleString('da', {
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: maximumDigits,
    });
}

export function renderPct(x, maximumDigits = 2, minimumDigits = 2) {
    if (x === undefined) {
        return 0;
    }
    return `${x.toLocaleString('da', {
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: maximumDigits,
    })} %`;
}

export function renderKr(x, maximumDigits = 0, minimumDigits = 0) {
    if (x === undefined) {
        return 0;
    }
    return `${x.toLocaleString('da', {
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: maximumDigits,
    })} kr.`;
}

export function num(x) {
    let retVal = 0;
    const y = Number(x);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(y) === false) {
        retVal = y;
    }

    return retVal;
}

export function sortOnId(a, b) {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
}

export function reverseSortOnId(a, b) {
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;
    return 0;
}

export function changeArrayObjectOnObject(object, propName, newArrayObject) {
    const newArray = [...object[propName].filter(o => o.id !== newArrayObject.id), newArrayObject];
    return { ...object, [propName]: newArray };
}
