import omit from 'lodash/omit';
import { db } from './database';

export function processesRef(partnerId, customerId) {
    return db.collection(`partners/${partnerId}/customers/${customerId}/processes`);
}

export async function getProcess(partnerId, customerId, processId) {
    const process = await processesRef(partnerId, customerId).doc(processId).get();
    if (process.exists) return { ...process.data(), partnerId, customerId, processId };
    throw new Error(`Process with partnerId ${partnerId}, customerId ${customerId} and processId ${processId} not found`);
}

export async function getAllProcesses(partnerId, customerId) {
    const processes = await processesRef(partnerId, customerId).get();
    return processes.docs.map(p => ({ ...p.data(), partnerId, customerId, processId: p.id }));
}

export function updateProcess(process) {
    return processesRef(process.partnerId, process.customerId)
        .doc(process.processId).set(omit(process, ['partnerId', 'customerId', 'processId']));
}
