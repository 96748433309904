import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useWindowSize } from '../../../hooks';
import NavBar from '../NavBar';
import SideBar from '../SideBar';
import ContentHeader from '../ContentHeader';
import ErrorBoundary from '../ErrorBoundary';

const Layout = (props) => {
    const { sidebarBottomContent, sidebarTopContent, logoPath } = props;
    const theme = useTheme();
    const { width } = useWindowSize();
    const isMobile = width < theme.breakpoints.values.md;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const maxWidth = 1110;

    return (
        <ErrorBoundary
            errorCallback={ props.errorCallback }
            imgPath={ props.errorBoundaryImgPath }
        >
            <div style={{ display: 'flex' }}>
                <CssBaseline />
                <NavBar
                    maxWidth={ maxWidth }
                    isMobile={ isMobile }
                    mobileOpen={ mobileOpen }
                    setMobileOpen={ setMobileOpen }
                    logoPath={ logoPath }
                />
                <SideBar
                    isMobile={ isMobile }
                    mobileOpen={ mobileOpen }
                    bottomContent={ sidebarBottomContent }
                    topContent={ sidebarTopContent }
                    setMobileOpen={ setMobileOpen }
                />

                <div style={{
                    flexGrow: 1,
                    padding: !isMobile && 30,
                    marginTop: 60,
                    height: 'calc(100vh - 60px)',
                    marginLeft: !isMobile && 184,
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        flexDirection: 'column',
                    }}>
                        <ContentHeader isMobile={ isMobile } maxWidth={ maxWidth } />

                        <main style={{
                            border: !isMobile && '1px solid #eaeaea',
                            borderRadius: !isMobile && 5,
                            width: '100%',
                            maxWidth: `${maxWidth  }px`,
                            marginBottom: !isMobile && 28,
                        }}>
                            { props.children }
                        </main>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

Layout.defaultProps = {};

Layout.propTypes = {
    sidebarBottomContent: PropTypes.node,
    sidebarTopContent: PropTypes.node,
    logoPath: PropTypes.string,
    errorCallback: PropTypes.func,
    errorBoundaryImgPath: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default Layout;
