import axios from 'axios';
import { config } from '../config';
import { isNullOrWhitespace, isNullOrNaN } from '../utils/helperFunctions';
import { defaultTurnover } from '../utils/renderingService';
import { defaultTransactionCostIndirect as fallbackDefaultTransactionCostIndirect } from '../defaultValues';

const request = axios.create({
    baseURL: config.algostrata.url,
    headers: { 'x-api-key': config.algostrata.apiKey },
    timeout: 10 * 1000,
});

const fundsPromise = request('/assets').catch((error) => {
    alert('Kunne ikke hente fondsdata.\nSøgning på fonde vil ikke fungere.\nKontakt venligst support.');
    console.error(error);
});

export async function getFunds(){
    const fundsResponse = await fundsPromise;
    return fundsResponse.data;
}

export async function getFundAsync(id) {
    if(!id) return;

    const fundsResponse = await fundsPromise;
    const result = fundsResponse.data.find(x => x.id === id);
    if (result === undefined) return null;

    return { id: result.id, name: result.name, isin: result.isin, stars: result.starRating, starsDate: result.starRatingDate };
}

export async function getFundByIsinAsync(isin) {
    if(!isin)  return;

    const fundsResponse = await fundsPromise;
    const result = fundsResponse.data.find(x => x.isin === isin);
    if (result === undefined) return null;

    return { id: result.id, name: result.name, isin: result.isin, stars: result.starRating, starsDate: result.starRatingDate };
}

export async function getFundInfoByIdAsync(id) {
    if(!id) return;

    const fundResponse = await request.get(`/assets/getById/${id}`);
    return fundResponse.data;
}

export async function getPortfolioCost(portfolio, customAssets, defaultTransactionCostIndirect) {
    const modifiedPortfolio = {
        portfolioTurnoverRatio: Number(portfolio.excessTurnoverRatio),
        managementCostRatio: Number(portfolio.managementCostRatio),
        defaultTransactionCostIndirect: Number(defaultTransactionCostIndirect ?? fallbackDefaultTransactionCostIndirect),
        assets: [],
    };

    modifiedPortfolio.assets = await Promise.all(portfolio.assets
        .map(async orgAsset => {
            const newAsset = {
                id: !isNullOrWhitespace(orgAsset.id) ? orgAsset.id : (await getFundByIsinAsync(orgAsset.isin)).id,
                amount:
                {
                    unit: 'DKK',
                    value: Number(orgAsset.value),
                },
                assetFeeInformation: null,
            };

            if(customAssets !== undefined && customAssets.length > 0) {
                const customAssetIndex = customAssets.findIndex(customAsset => customAsset?.id === newAsset.id);
                if(customAssetIndex !== -1 && !isNullOrNaN(customAssets[customAssetIndex].aaop)) {
                    const assetInfo = await getFundInfoByIdAsync(newAsset.id);
                    newAsset.assetFeeInformation = {
                        danishAAOP: Number(customAssets[customAssetIndex].aaop) ?? 0,
                        frontLoad: assetInfo.mifid.frontLoad ?? 0,
                        deferLoad: assetInfo.mifid.deferLoad ?? 0,
                        transactionCostIndirect: assetInfo.mifid.transactionCostIndirect ?? Number(defaultTransactionCostIndirect ?? fallbackDefaultTransactionCostIndirect),
                    };
                }
            }

            return newAsset;
        }));

    return request.post('/PortfolioAnalyser/calculatePortfolioCost', modifiedPortfolio);
}

export async function getPortfolioMifidCost(portfolio, oneTimeManagementCost = 0) {
    const modifiedPortfolio = {
        portfolioTurnoverRatio: Number(portfolio.excessTurnoverRatio) + defaultTurnover,
        managementCostRatio: {
            AsFraction: Number(portfolio.managementCostRatio),
        },
        managementCostFixed: {
            unit: 'DKK',
            value: oneTimeManagementCost,
        },
        assets: [],
    };

    modifiedPortfolio.assets = portfolio.assets
        .map(orgAsset => ({
            id: orgAsset.id,
            amount:
            {
                unit: 'DKK',
                value: Number(orgAsset.value),
            },
            assetFeeInformation: null,
        }));

    return request.post('/PortfolioAnalyser/calculatePortfolioMifidCost', modifiedPortfolio);
}

export async function getPortfolioCountryExposure(portfolio) {
    const modifiedPortfolio = {
        assets: [],
    };

    modifiedPortfolio.assets = portfolio.assets
        .map(orgAsset => ({
            id: orgAsset.id,
            amount:
            {
                unit: 'DKK',
                value: Number(orgAsset.value),
            },
        }));

    return request.post('/PortfolioAnalyser/calculatePortfolioCountryExposure', modifiedPortfolio);
}

async function checkForAndGetCustomAllocationAsset(orgAsset, customAssets) {
    const asset = {
        id: !isNullOrWhitespace(orgAsset.id) ? orgAsset.id : (await getFundByIsinAsync(orgAsset.isin)).id,
        amount:
        {
            unit: 'DKK',
            value: Number(orgAsset.value),
        },
        assetAllocation: null,
    };

    if(customAssets && customAssets.length > 0) {
        const customAssetIndex = customAssets.findIndex(customAsset => customAsset.id === asset.id);

        if(customAssetIndex !== -1) {
            const customAsset = customAssets[customAssetIndex];
            if(customAsset.allocation) {
                asset.assetAllocation = {
                    stock: {
                        shortPosition: 0,
                        longPosition: Math.round(customAsset.allocation.stock * 10000) / 10000,
                    },
                    bond: {
                        shortPosition: 0,
                        longPosition: Math.round(customAsset.allocation.bond * 10000) / 10000,
                    },
                    preferred: {
                        shortPosition: 0,
                        longPosition: 0,
                    },
                    convertible: {
                        shortPosition: 0,
                        longPosition: 0,
                    },
                    cash: {
                        shortPosition: 0,
                        longPosition: Math.round(customAsset.allocation.cash * 10000) / 10000,
                    },
                    other: {
                        shortPosition: 0,
                        longPosition: Math.round(customAsset.allocation.other * 10000) / 10000,
                    },
                };
            }
        }
    }
    return asset;
}

export async function getPortfolioAssetAllocation(portfolio, customAssets) {
    const assets = await Promise.all(portfolio.assets.filter(asset => (!isNullOrWhitespace(asset.id) || !isNullOrWhitespace(asset.isin)) && !Number.isNaN(asset.value))
        .map(async asset => checkForAndGetCustomAllocationAsset(asset, customAssets)));

    const payload = {
        assets,
    };
    const response = await request.post('/PortfolioAnalyser/calculatePortfolioAllocation', payload);
    return { data: response.data, status: response.status };
}

export async function getPortfolioAssetAllocationSquaredDistance(originPortfolio, pointPortfolios, customAssets) {
    const originAssets = await Promise.all(originPortfolio.assets.filter(asset => (!isNullOrWhitespace(asset.id) || !isNullOrWhitespace(asset.isin)) && !Number.isNaN(asset.value))
        .map(async asset => checkForAndGetCustomAllocationAsset(asset, customAssets)));

    const points = await Promise.all(pointPortfolios.map(async portfolio => {
        const pointAssets = await Promise.all(portfolio.assets.filter(asset => (!isNullOrWhitespace(asset.id) || !isNullOrWhitespace(asset.isin)) && !Number.isNaN(asset.value))
            .map(async asset => checkForAndGetCustomAllocationAsset(asset, customAssets)));

        return {
            id: portfolio.id,
            assets: pointAssets,
        };
    }));

    const payload = {
        origin: {
            id: 'origin',
            assets: originAssets,
        },
        points,
    };
    const response = await request.post('/PortfolioAnalyser/calculatePortfolioAllocationSquaredDistance', payload);
    return { data: response.data, status: response.status };
}

export async function getPortfolioPerformance(portfolio, calculationDate) {
    const assets = await Promise.all(portfolio.assets.filter(asset => asset.calculationSettings?.includeInHistoricalPerformance ?? true).map(async orgAsset => {
        return {
            id: !isNullOrWhitespace(orgAsset.id) ? orgAsset.id : (await getFundByIsinAsync(orgAsset.isin)).id,
            amount:
            {
                unit: 'DKK',
                value: Number(orgAsset.value),
            },
        };
    }));

    const payload = {
        assets,
        calculationDate,
        managementCosts: Number(portfolio.managementCostRatio),
        portfolioTurnoverRatio: Number(portfolio.excessTurnoverRatio),
    };
    const response = await request.post('/PortfolioAnalyser/calculatePortfolioPerformance', payload);
    return { data: response.data, status: response.status };
}

export async function getPortfolioVolatility(assets) {
    const mappedAssets = await Promise.all(assets.map(async orgAsset => {
        return {
            id: !isNullOrWhitespace(orgAsset.id) ? orgAsset.id : (await getFundByIsinAsync(orgAsset.isin)).id,
            amount:
            {
                unit: 'DKK',
                value: Number(orgAsset.value),
            },
        };
    }));

    const payload = {
        assets: mappedAssets,
    };
    const response = await request.post('/PortfolioAnalyser/calculatePortfolioVolatilityFromSRRI', payload);
    return { data: response.data, status: response.status };
}

export async function getPortfolioHistoryLength(inputAssets) {
    const assets = await Promise.all(inputAssets.map(async orgAsset => {
        return {
            id: !isNullOrWhitespace(orgAsset.id) ? orgAsset.id : (await getFundByIsinAsync(orgAsset.isin)).id,
        };
    }));

    const payload = {
        assets,
    };
    const response = await request.post('/PortfolioAnalyser/calculatePortfolioHistoryLength', payload);
    return { data: response.data, status: response.status };
}
