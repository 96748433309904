import axios from 'axios';
import { config } from '../config';
import { auth as authentication } from '../databaseRepository/database';

export async function getClient(clientId) {
    const result = await axios.get(`${config.functionsUrl}/oauth-clients/${clientId}`);
    return result.data.client;
}

export async function getTealBusinessToken() {
    const idToken = await authentication.currentUser.getIdToken();

    const result = await axios({
        method: 'POST',
        url: `${config.functionsUrl}/oauth-teal-business-token`,
        headers: { 'authorization': `Bearer ${idToken}` },
    });

    return result.data.access_token;
}
