import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';


export default function BirthdayInputComponent({ value, onChange }) {
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => setInternalValue(value), [value]);

    function onBlur(event) {
        onChange(event.target.value);
    }

    function wrappredOnChange(event) {
        setInternalValue(event.target.value);
    }

    return (
        <TextField
            id="date"
            label="Fødseslsdag"
            type="date"
            value={ internalValue }
            onChange={ wrappredOnChange }
            onBlur={ (e) => onBlur(e) }
            style={{ height: 55 }}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}

BirthdayInputComponent.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
};
