import React, { useState } from 'react';
import { InputLabel, MenuItem, FormControl, Select, TextField  } from '@mui/material';
import { encode } from '../../utils/base64';

export default function ReportInjector() {
    const [ state, setState ] = useState({ injectState: '{ }' });
    const [ dropdownValue, setDropdownValue ] = useState('');

    function handleChange(event) {
        setState({ injectState: event.target.value });

        let newReportState;
        try {
            newReportState = JSON.parse(event.target.value);
            console.log(newReportState);
        } catch (error) {
            setState({ parseError: error });
            return;
        }

        setState({ parseError: null });
        window.setReportState(newReportState.state);
    }

    function toggleShow() {
        setState({ show: !state.show });
    }

    function handleDropDownChange(value) {
        setDropdownValue(value);
        handleChange({ target: { value: JSON.stringify({ 'state': encode(presets[value].value) }) } });
    }

    return (
        <div id='report-injector' style={{ width: '1100px', margin: '0px auto 10px auto' }}>
            <button onClick={ () => toggleShow() } style={{ margin: '10px' }}>Edit JSON</button>
            <FormControl variant='filled' style={{ width: '200px', backgroundColor: 'white', marginTop: '10px' }}>
                <InputLabel>Choose a preset</InputLabel>
                <Select
                    label="Choose a preset"
                    value={ dropdownValue }
                    onChange={ event => handleDropDownChange(event.target.value) }
                >
                    { presets.map((x, index) => {
                        return <MenuItem key={ index } value={ x.key } disabled={ x.disabled }>{ x.text }</MenuItem>;
                    }) }
                </Select>
            </FormControl>
            {
                state.show && (
                    <div>
                        <p>{ state.parseError ? state.parseError.toString() : ('Parses') }</p>
                        <TextField
                            style={{ fontFamily: 'monospace', resize: 'none', width: '100%', backgroundColor: 'white' }}
                            multiline
                            rows={ 30 }
                            value={ state.injectState }
                            onChange={ event => handleChange(event) }
                            variant="outlined"
                        />
                    </div>
                )
            }
        </div>
    );
}

const presets = [
    {
        disabled: false,
        key: 0,
        text: 'Stats',
        value: JSON.stringify({
            reportType: 'blank',
        }, null, 4),
    },
    {
        disabled: false,
        key: 1,
        text: 'InvestTjek 1',
        value: JSON.stringify({
            'reportType': 'investCheck',
            'variation': 'offer',
            'investData': {
                'isFetching': false,
                'calculationSettings': {
                    'selectedHistoricalPerformanceYears': {
                        'yearFive': true,
                    },
                },
                'currentPortfolio': {
                    'standardDeviation': 0.2,
                    'estimatedReturn': '0.0600',
                    'assets': [
                        {
                            'firstPriceDate': {
                                'inputDate': {
                                    'seconds': 1635853444,
                                    'nanoseconds': 57000000,
                                },
                                'value': {
                                    'seconds': 1284422400,
                                    'nanoseconds': 0,
                                },
                            },
                            'calculationSettings': null,
                            'msStars': 3,
                            'msStarsDate': '2021-09-30T00:00:00Z',
                            'cost': 0,
                            'msId': '',
                            'name': 'StockRate Invest Globale Aktier',
                            'isin': 'DK0060206316',
                            'id': '5dda4b878ff26c0001da8dfb',
                            'value': '100000',
                        },
                        {
                            'value': '100000',
                            'name': 'Nykredit Alpha Kobra',
                            'msStarsDate': null,
                            'isin': 'DK0060455889',
                            'msStars': null,
                            'calculationSettings': null,
                            'cost': 0,
                            'firstPriceDate': {
                                'inputDate': {
                                    'seconds': 1635853444,
                                    'nanoseconds': 57000000,
                                },
                                'value': {
                                    'seconds': 1363737600,
                                    'nanoseconds': 0,
                                },
                            },
                            'msId': '',
                            'id': '5dda37028ff26c0001da825b',
                        },
                    ],
                    'managementCostRatio': '0.0055',
                    'excessTurnoverRatio': 0,
                },
                'error': false,
                'oneTimeManagementCost': '1000',
                'result': {
                    'currentPortfolioVolatility': {
                        'warnings': [],
                        'result': {
                            'volatility': 0.08,
                        },
                    },
                    'targetPortfolioVolatility': {
                        'warnings': [],
                        'result': {
                            'volatility': 0.2,
                        },
                    },
                    'portfolioSum': 200000,
                    'targetPortfolioMifidCost': {
                        'result': {
                            'totalFeesInPercent': {
                                'asFraction': 0.00639,
                            },
                            'totalFeesFirstYear': {
                                'value': 2278,
                                'unit': 'Currencies:DKK',
                            },
                            'totalAssetEntryExitCost': {
                                'unit': 'Currencies:DKK',
                                'value': 0,
                            },
                            'totalAssetTransactionFees': {
                                'value': 8,
                                'unit': 'Currencies:DKK',
                            },
                            'totalAssetPerformanceFees': {
                                'unit': 'Currencies:DKK',
                                'value': 0,
                            },
                            'totalAssetOngoingCost': {
                                'unit': 'Currencies:DKK',
                                'value': 570,
                            },
                            'totalFeesInPercentFirstYear': {
                                'asFraction': 0.01139,
                            },
                            'portfolioManagementYearlyFee': {
                                'value': 700,
                                'unit': 'Currencies:DKK',
                            },
                            'portfolioManagementOneTimeFee': {
                                'unit': 'Currencies:DKK',
                                'value': 1000,
                            },
                            'totalFees': {
                                'unit': 'Currencies:DKK',
                                'value': 1278,
                            },
                        },
                        'warnings': [
                            'The amount of cash in the portfolio was not given using default value of 0 DKK',
                            'MaximumEntryCostAcquired was not given for asset with id: 5e96b85cee946f0370b9e168, using default value of 0%',
                            'MaximumExitCostAcquired was not given for asset with id: 5e96b85cee946f0370b9e168, using default value of 0%',
                            'MaximumEntryCostAcquired was not given for asset with id: 5e96b852ee946f0370b9e0cc, using default value of 0%',
                            'MaximumExitCostAcquired was not given for asset with id: 5e96b852ee946f0370b9e0cc, using default value of 0%',
                        ],
                    },
                    'targetAllocation': {
                        'result': {
                            'cash': {
                                'longPosition': 0.00239205,
                                'shortPosition': 0,
                            },
                            'bond': {
                                'shortPosition': 0,
                                'longPosition': 0.00000235,
                            },
                            'other': {
                                'longPosition': 0.0001471,
                                'shortPosition': 0,
                            },
                            'stock': {
                                'shortPosition': 0,
                                'longPosition': 0.99745845,
                            },
                        },
                        'warnings': [],
                    },
                    'costComparison': {
                        'savingInKr': 4220,
                        'targetCostsFirstYearInKr': 2200,
                        'oneTimeManagementCost': 1000,
                        'targetCostsInPercent': 0.006,
                        'savingFirstYearInKr': 3220,
                        'portfolioSum': 200000,
                        'relativeSavingFirstYearInPercent': 0.5940959409594095,
                        'targetCostsFirstYearInPercent': 0.011,
                        'targetCostsInKr': 1200,
                        'targetCosts': {
                            'SpreadIForeninger': {
                                'text': 'SpreadIForeninger',
                                'value': 500,
                            },
                            'omkostningsandel': {
                                'text': 'omkostningsandel',
                                'value': 0.006,
                            },
                            'LobendeOmk': {
                                'text': 'LobendeOmk',
                                'value': 0,
                            },
                            'SpreadDepot': {
                                'text': 'SpreadDepot',
                                'value': 0,
                            },
                            'Total': {
                                'text': 'Total',
                                'value': 1200,
                            },
                            'Plejeomkostninger': {
                                'text': 'Plejeomkostninger',
                                'value': 700,
                            },
                        },
                        'currentCostsInPercent': 0.0271,
                        'relativeSavingInPercent': 0.7785977859778598,
                        'currentCostsInKr': 5420,
                        'currentCosts': {
                            'Plejeomkostninger': {
                                'text': 'Plejeomkostninger',
                                'value': 1100,
                            },
                            'Total': {
                                'value': 5420,
                                'text': 'Total',
                            },
                            'LobendeOmk': {
                                'value': 2850,
                                'text': 'LobendeOmk',
                            },
                            'SpreadDepot': {
                                'text': 'SpreadDepot',
                                'value': 0,
                            },
                            'omkostningsandel': {
                                'value': 0.0271,
                                'text': 'omkostningsandel',
                            },
                            'SpreadIForeninger': {
                                'text': 'SpreadIForeninger',
                                'value': 1470,
                            },
                        },
                    },
                    'currentPortfolioMifidCost': {
                        'result': {
                            'totalAssetEntryExitCost': {
                                'value': 171.42857142857142,
                                'unit': 'Currencies:DKK',
                            },
                            'totalAssetOngoingCost': {
                                'value': 2498.2,
                                'unit': 'Currencies:DKK',
                            },
                            'totalFeesInPercentFirstYear': {
                                'asFraction': 0.024220142857142857,
                            },
                            'totalFeesInPercent': {
                                'asFraction': 0.024220142857142857,
                            },
                            'totalAssetPerformanceFees': {
                                'unit': 'Currencies:DKK',
                                'value': 287,
                            },
                            'portfolioManagementYearlyFee': {
                                'value': 1100,
                                'unit': 'Currencies:DKK',
                            },
                            'portfolioManagementOneTimeFee': {
                                'value': 0,
                                'unit': 'Currencies:DKK',
                            },
                            'totalFeesFirstYear': {
                                'value': 4844.028571428571,
                                'unit': 'Currencies:DKK',
                            },
                            'totalAssetTransactionFees': {
                                'unit': 'Currencies:DKK',
                                'value': 787.4,
                            },
                            'totalFees': {
                                'value': 4844.028571428571,
                                'unit': 'Currencies:DKK',
                            },
                        },
                        'warnings': [
                            'The amount of cash in the portfolio was not given using default value of 0 DKK',
                            'PerformanceFeeEstimated cost was not given for asset with id: 5dda4b878ff26c0001da8dfb, using default value of 0%',
                        ],
                    },
                    'input': {
                        'targetPortfolio': {
                            'excessTurnoverRatio': 0,
                            'assets': [
                                {
                                    'calculationSettings': null,
                                    'msStarsDate': '2021-09-30T00:00:00Z',
                                    'isin': 'IE0031442068',
                                    'value': '100000',
                                    'obsolete': false,
                                    'firstPriceDate': {
                                        'inputDate': {
                                            'seconds': 1634135119,
                                            'nanoseconds': 149000000,
                                        },
                                        'value': {
                                            'seconds': 1016150400,
                                            'nanoseconds': 0,
                                        },
                                    },
                                    'msStars': 4,
                                    'id': '5e96b85cee946f0370b9e168',
                                    'name': 'iShares Core S&P 500 ETF USD Dist',
                                },
                                {
                                    'name': 'iShares MSCI World ETF USD Dist',
                                    'value': '100000',
                                    'msStars': 4,
                                    'id': '5e96b852ee946f0370b9e0cc',
                                    'obsolete': false,
                                    'msStarsDate': '2021-09-30T00:00:00Z',
                                    'calculationSettings': null,
                                    'isin': 'IE00B0M62Q58',
                                    'firstPriceDate': {
                                        'value': {
                                            'seconds': 1130457600,
                                            'nanoseconds': 0,
                                        },
                                        'inputDate': {
                                            'seconds': 1634135122,
                                            'nanoseconds': 269000000,
                                        },
                                    },
                                },
                            ],
                            'estimatedReturn': '0.0600',
                            'srriVolatility': {
                                'inputDate': {
                                    'seconds': 1634135124,
                                    'nanoseconds': 117000000,
                                },
                                'value': 0.2,
                                'inputAssets': [
                                    {
                                        'msStarsDate': '2021-09-30T00:00:00Z',
                                        'firstPriceDate': {
                                            'value': {
                                                'seconds': 1016150400,
                                                'nanoseconds': 0,
                                            },
                                            'inputDate': {
                                                'seconds': 1634135119,
                                                'nanoseconds': 149000000,
                                            },
                                        },
                                        'msStars': 4,
                                        'isin': 'IE0031442068',
                                        'name': 'iShares Core S&P 500 ETF USD Dist',
                                        'calculationSettings': null,
                                        'value': '100000',
                                        'id': '5e96b85cee946f0370b9e168',
                                        'obsolete': false,
                                    },
                                    {
                                        'firstPriceDate': {
                                            'value': {
                                                'seconds': 1130457600,
                                                'nanoseconds': 0,
                                            },
                                            'inputDate': {
                                                'seconds': 1634135122,
                                                'nanoseconds': 269000000,
                                            },
                                        },
                                        'msStars': 4,
                                        'value': '100000',
                                        'calculationSettings': null,
                                        'id': '5e96b852ee946f0370b9e0cc',
                                        'isin': 'IE00B0M62Q58',
                                        'obsolete': false,
                                        'msStarsDate': '2021-09-30T00:00:00Z',
                                        'name': 'iShares MSCI World ETF USD Dist',
                                    },
                                ],
                            },
                            'managementCostRatio': '0.0035',
                            'standardDeviation': 0.2,
                        },
                        'oneTimeManagementCost': '1000',
                        'currentPortfolio': {
                            'estimatedReturn': '0.0600',
                            'managementCostRatio': '0.0055',
                            'excessTurnoverRatio': 0,
                            'assets': [
                                {
                                    'msStars': 3,
                                    'msId': '',
                                    'firstPriceDate': {
                                        'inputDate': {
                                            'seconds': 1634135494,
                                            'nanoseconds': 822000000,
                                        },
                                        'value': {
                                            'seconds': 1284422400,
                                            'nanoseconds': 0,
                                        },
                                    },
                                    'msStarsDate': '2021-09-30T00:00:00Z',
                                    'value': '100000',
                                    'cost': 0,
                                    'name': 'StockRate Invest Globale Aktier',
                                    'isin': 'DK0060206316',
                                    'id': '5dda4b878ff26c0001da8dfb',
                                    'calculationSettings': null,
                                },
                                {
                                    'firstPriceDate': {
                                        'inputDate': {
                                            'seconds': 1634135560,
                                            'nanoseconds': 580000000,
                                        },
                                        'value': {
                                            'seconds': 1363737600,
                                            'nanoseconds': 0,
                                        },
                                    },
                                    'msStarsDate': null,
                                    'value': '100000',
                                    'name': 'Nykredit Alpha Kobra',
                                    'isin': 'DK0060455889',
                                    'msId': '',
                                    'calculationSettings': null,
                                    'id': '5dda37028ff26c0001da825b',
                                    'msStars': null,
                                    'cost': 0,
                                },
                            ],
                            'standardDeviation': 0.2,
                        },
                    },
                    'currentAllocation': {
                        'warnings': [],
                        'result': {
                            'cash': {
                                'longPosition': 0.50529215,
                                'shortPosition': 0.0010029,
                            },
                            'stock': {
                                'shortPosition': 0,
                                'longPosition': 0.49571075,
                            },
                            'other': {
                                'shortPosition': 0,
                                'longPosition': 0,
                            },
                            'bond': {
                                'shortPosition': 0,
                                'longPosition': 0,
                            },
                        },
                    },
                    'currentPortfolioPerformance': null,
                    'date': {
                        'seconds': 1634136359,
                        'nanoseconds': 427000000,
                    },
                    'targetPortfolioPerformance': {
                        'warnings': [],
                        'result': {
                            'annualizedTotalReturnThreeYears': 0.162736823400368,
                            'assetsTotalReturnFiveYears': [
                                {
                                    'assetId': '5e96b85cee946f0370b9e168',
                                    'totalReturn': 1.0482725961792194,
                                },
                                {
                                    'totalReturn': 0.8843486650278121,
                                    'assetId': '5e96b852ee946f0370b9e0cc',
                                },
                            ],
                            'annualizedTotalReturnOneYear': 0.265518147263196,
                            'assetsTotalReturnOneYear': [
                                {
                                    'assetId': '5e96b85cee946f0370b9e168',
                                    'totalReturn': 0.24604378174306032,
                                },
                                {
                                    'totalReturn': 0.2919925127833328,
                                    'assetId': '5e96b852ee946f0370b9e0cc',
                                },
                            ],
                            'usedCalculationDate': '2021-10-12T00:00:00Z',
                            'annualizedTotalReturnFiveYears': 0.141313222576556,
                            'portfolioTotalReturnThreeYears': 0.5719700951060579,
                            'portfolioTotalReturnOneYear': 0.26551814726319656,
                            'portfolioTotalReturnFiveYears': 0.9365300649337991,
                            'assetsTotalReturnThreeYears': [
                                {
                                    'assetId': '5e96b85cee946f0370b9e168',
                                    'totalReturn': 0.606618155779099,
                                },
                                {
                                    'assetId': '5e96b852ee946f0370b9e0cc',
                                    'totalReturn': 0.5646263529652504,
                                },
                            ],
                        },
                    },
                },
                'selectedModelPortfolioId': '090c1830-84dd-4569-b62c-eeec88efb302',
                'targetPortfolio': {
                    'managementCostRatio': '0.0035',
                    'srriVolatility': {
                        'value': 0.2,
                        'inputAssets': [
                            {
                                'msStars': 4,
                                'firstPriceDate': {
                                    'inputDate': {
                                        'seconds': 1635853444,
                                        'nanoseconds': 57000000,
                                    },
                                    'value': {
                                        'seconds': 1016150400,
                                        'nanoseconds': 0,
                                    },
                                },
                                'msStarsDate': '2021-09-30T00:00:00Z',
                                'id': '5e96b85cee946f0370b9e168',
                                'value': '100000',
                                'name': 'iShares Core S&P 500 ETF USD Dist',
                                'obsolete': false,
                                'calculationSettings': null,
                                'isin': 'IE0031442068',
                            },
                            {
                                'isin': 'IE00B0M62Q58',
                                'value': '100000',
                                'firstPriceDate': {
                                    'value': {
                                        'seconds': 1130457600,
                                        'nanoseconds': 0,
                                    },
                                    'inputDate': {
                                        'seconds': 1635853444,
                                        'nanoseconds': 57000000,
                                    },
                                },
                                'msStars': 4,
                                'id': '5e96b852ee946f0370b9e0cc',
                                'obsolete': false,
                                'msStarsDate': '2021-09-30T00:00:00Z',
                                'calculationSettings': null,
                                'name': 'iShares MSCI World ETF USD Dist',
                            },
                        ],
                        'inputDate': {
                            'seconds': 1635853444,
                            'nanoseconds': 832000000,
                        },
                    },
                    'excessTurnoverRatio': 0,
                    'assets': [
                        {
                            'calculationSettings': null,
                            'msStarsDate': '2021-09-30T00:00:00Z',
                            'firstPriceDate': {
                                'value': {
                                    'seconds': 1016150400,
                                    'nanoseconds': 0,
                                },
                                'inputDate': {
                                    'seconds': 1635853444,
                                    'nanoseconds': 57000000,
                                },
                            },
                            'obsolete': false,
                            'name': 'iShares Core S&P 500 ETF USD Dist',
                            'msStars': 4,
                            'value': '100000',
                            'isin': 'IE0031442068',
                            'id': '5e96b85cee946f0370b9e168',
                        },
                        {
                            'name': 'iShares MSCI World ETF USD Dist',
                            'calculationSettings': {
                                'includeInHistoricalPerformance': true,
                            },
                            'msStars': 4,
                            'firstPriceDate': {
                                'inputDate': {
                                    'seconds': 1635853444,
                                    'nanoseconds': 57000000,
                                },
                                'value': {
                                    'seconds': 1130457600,
                                    'nanoseconds': 0,
                                },
                            },
                            'id': '5e96b852ee946f0370b9e0cc',
                            'isin': 'IE00B0M62Q58',
                            'value': '100000',
                            'obsolete': false,
                            'msStarsDate': '2021-09-30T00:00:00Z',
                        },
                    ],
                    'standardDeviation': 0.2,
                    'estimatedReturn': '0.0600',
                },
                'selectedModelPortfolioData': {
                    'oneTimeManagementCost': 0,
                    'managementCostRatio': 0,
                    'excessTurnoverRatio': 0,
                    'standardDeviation': '0.0500',
                    'estimatedReturn': '0.0600',
                    'id': '090c1830-84dd-4569-b62c-eeec88efb302',
                    'name': '#7',
                    'assets': [
                        {
                            'weight': '0.5000',
                            'msStarsDate': '2020-12-31T00:00:00Z',
                            'calculationSettings': null,
                            'msStars': 5,
                            'id': '5dda1b248ff26c0001da71b2',
                            'name': 'Investin Optimal VerdensIndex Moderat',
                            'isin': 'DK0060254712',
                        },
                        {
                            'isin': 'DK0060005171',
                            'msStarsDate': '2021-01-31T00:00:00Z',
                            'msStars': 3,
                            'id': '5dda299d8ff26c0001da7a25',
                            'name': 'Maj Invest Danske Aktier',
                            'calculationSettings': null,
                            'weight': '0.5000',
                        },
                    ],
                    'archived': false,
                    'srriVolatility': {
                        'inputDate': {
                            'seconds': 1614079527,
                            'nanoseconds': 592000000,
                        },
                        'value': 0.1375,
                        'inputAssets': [
                            {
                                'id': '5dda1b248ff26c0001da71b2',
                                'value': '0.5000',
                            },
                            {
                                'value': '0.5000',
                                'id': '5dda299d8ff26c0001da7a25',
                            },
                        ],
                    },
                },
                'checkLoading': false,
                'partnerId': 'ml-advice',
                'customerId': 'd36e5963-c2dc-43ae-a081-422415420ffd',
                'processId': '84753e3c-677a-4668-9265-5d85cd164237',
                'reportLoading': true,
                'comment': 'test',
            },
            'customer': {
                'phoneNumber': '',
                'advisorId': '24181025-53c9-4d3f-b83e-ccab0fe6a92d',
                'name': 'Eddi Søgaard',
                'firstName': 'Eddi',
                'email': 'test@test.dk',
                'deleted': false,
                'createdAt': {
                    'seconds': 1589537505,
                    'nanoseconds': 428000000,
                },
                'lastName': 'Søgaard',
                'partnerId': 'ml-advice',
                'customerId': 'd36e5963-c2dc-43ae-a081-422415420ffd',
                'files': [],
            },
            'partnerData': {
                'loaded': true,
                'isFetching': false,
                'pensionsInfoSettingsData': {
                    'providerDataList': [],
                },
                'modelPortfolios': [
                    {
                        'id': '061dd38a-b05a-49f3-9a18-a2cb75080129',
                        'oneTimeManagementCost': '7000',
                        'standardDeviation': '0.0500',
                        'estimatedReturn': '0.0101',
                        'name': '#1',
                        'modelPortfolioId': '061dd38a-b05a-49f3-9a18-a2cb75080129',
                        'archived': true,
                        'assets': [
                            {
                                'name': 'Absalon Invest Global High Yield 2020',
                                'weight': '0.1000',
                                'msStarsDate': null,
                                'msStars': null,
                                'isin': 'DK0060877959',
                                'id': '5dd9d61d8ff26c0001da4ec8',
                            },
                            {
                                'msStars': 4,
                                'msStarsDate': '2021-01-31T00:00:00Z',
                                'weight': '0.9000',
                                'calculationSettings': null,
                                'name': 'BankInvest Danske Aktier W',
                                'isin': 'DK0060821064',
                                'id': '5dd9e0b68ff26c0001da5481',
                                'firstPriceDate': null,
                            },
                        ],
                        'srriVolatility': {
                            'inputAssets': [
                                {
                                    'value': '0.1000',
                                    'id': '5dd9d61d8ff26c0001da4ec8',
                                },
                                {
                                    'id': '5dd9e0b68ff26c0001da5481',
                                    'value': '0.9000',
                                },
                            ],
                            'inputDate': {
                                'seconds': 1632314735,
                                'nanoseconds': 848000000,
                            },
                            'value': null,
                        },
                        'managementCostRatio': 0,
                        'excessTurnoverRatio': 0.14285714285714285,
                    },
                    {
                        'oneTimeManagementCost': '0',
                        'excessTurnoverRatio': 0,
                        'archived': false,
                        'estimatedReturn': '0.0500',
                        'srriVolatility': {
                            'inputAssets': [
                                {
                                    'id': '5dd9e0e88ff26c0001da5498',
                                    'value': '0.2000',
                                },
                                {
                                    'value': '0.8000',
                                    'id': '5dda1b248ff26c0001da71b2',
                                },
                            ],
                            'inputDate': {
                                'seconds': 1632314735,
                                'nanoseconds': 848000000,
                            },
                            'value': 0.067,
                        },
                        'name': '#2',
                        'standardDeviation': null,
                        'managementCostRatio': 0,
                        'assets': [
                            {
                                'msStars': 5,
                                'id': '5dd9e0e88ff26c0001da5498',
                                'calculationSettings': null,
                                'msStarsDate': '2021-02-28T00:00:00Z',
                                'isin': 'DK0060822542',
                                'name': 'BankInvest Lange Danske Obligationer W',
                                'firstPriceDate': null,
                                'weight': '0.2000',
                            },
                            {
                                'name': 'Investin Optimal VerdensIndex Moderat',
                                'msStarsDate': '2021-07-31T00:00:00Z',
                                'id': '5dda1b248ff26c0001da71b2',
                                'calculationSettings': null,
                                'isin': 'DK0060254712',
                                'weight': '0.8000',
                                'msStars': 5,
                                'firstPriceDate': null,
                            },
                        ],
                        'modelPortfolioId': '832db734-1fa7-4ae2-be4d-b9bbb98f939e',
                        'id': '832db734-1fa7-4ae2-be4d-b9bbb98f939e',
                    },
                    {
                        'standardDeviation': 0.08,
                        'archived': true,
                        'srriVolatility': {
                            'inputAssets': [],
                            'value': null,
                            'inputDate': {
                                'seconds': 1611917057,
                                'nanoseconds': 988000000,
                            },
                        },
                        'estimatedReturn': 0.05,
                        'id': 'e656e285-3df1-4425-8303-71ebdf81e9b7',
                        'modelPortfolioId': 'e656e285-3df1-4425-8303-71ebdf81e9b7',
                        'managementCostRatio': 0,
                        'oneTimeManagementCost': '1000',
                        'name': '#3',
                        'excessTurnoverRatio': 0,
                        'assets': [
                            {
                                'id': '',
                                'msStars': '',
                                'weight': 0,
                                'name': '',
                                'isin': '',
                                'msStarsDate': '',
                            },
                            {
                                'weight': 0,
                                'msStars': '',
                                'name': '',
                                'msStarsDate': '',
                                'id': '',
                                'isin': '',
                            },
                            {
                                'msStarsDate': '',
                                'name': '',
                                'msStars': '',
                                'isin': '',
                                'weight': 0,
                                'id': '',
                            },
                        ],
                    },
                    {
                        'estimatedReturn': '0.03',
                        'assets': [
                            {
                                'weight': '1',
                                'id': '5f06de051ef26a0001597e08',
                                'msStars': null,
                                'isin': null,
                                'name': 'Saxo Select Defensiv',
                                'msStarsDate': null,
                            },
                        ],
                        'archived': false,
                        'excessTurnoverRatio': 0,
                        'srriVolatility': {
                            'inputAssets': [
                                {
                                    'value': '1',
                                    'id': '5f06de051ef26a0001597e08',
                                },
                            ],
                            'value': null,
                            'inputDate': {
                                'seconds': 1632314735,
                                'nanoseconds': 848000000,
                            },
                        },
                        'managementCostRatio': 0,
                        'oneTimeManagementCost': 0,
                        'modelPortfolioId': '5e2985a2-2bbf-4170-8a83-b46b789a6648',
                        'standardDeviation': '0.0500',
                        'name': 'Saxo Select Defensiv',
                        'id': '5e2985a2-2bbf-4170-8a83-b46b789a6648',
                    },
                    {
                        'estimatedReturn': 0.05,
                        'archived': false,
                        'excessTurnoverRatio': 0,
                        'id': '3ce7706f-f150-4c8c-86fb-26d458396267',
                        'oneTimeManagementCost': '50000',
                        'srriVolatility': {
                            'value': null,
                            'inputDate': {
                                'seconds': 1632314735,
                                'nanoseconds': 848000000,
                            },
                            'inputAssets': [
                                {
                                    'id': '5f06df601ef26a0001597e09',
                                    'value': '0.9',
                                },
                                {
                                    'value': '0.1',
                                    'id': '5ebaa8b0ee946f0370722270',
                                },
                            ],
                        },
                        'standardDeviation': '0.0800',
                        'managementCostRatio': 0,
                        'name': '#5',
                        'assets': [
                            {
                                'isin': null,
                                'msStarsDate': null,
                                'name': 'Saxo Select Moderat',
                                'weight': '0.9',
                                'id': '5f06df601ef26a0001597e09',
                                'msStars': null,
                            },
                            {
                                'weight': '0.1',
                                'id': '5ebaa8b0ee946f0370722270',
                                'msStarsDate': null,
                                'msStars': null,
                                'name': 'Hammers Fonde - Lav, kl f',
                                'isin': null,
                            },
                        ],
                    },
                    {
                        'srriVolatility': {
                            'inputDate': {
                                'seconds': 1632314735,
                                'nanoseconds': 848000000,
                            },
                            'inputAssets': [
                                {
                                    'value': '1.9',
                                    'id': '5f06df601ef26a0001597e09',
                                },
                                {
                                    'value': '0.1',
                                    'id': '5ebaa8b0ee946f0370722270',
                                },
                            ],
                            'value': null,
                        },
                        'standardDeviation': 0.08,
                        'oneTimeManagementCost': 0,
                        'id': 'f85de30b-0f43-44f8-9cdf-1c74b0e22624',
                        'archived': true,
                        'managementCostRatio': 0,
                        'name': '#6',
                        'excessTurnoverRatio': 0,
                        'estimatedReturn': 0.05,
                        'assets': [
                            {
                                'id': '5f06df601ef26a0001597e09',
                                'isin': null,
                                'name': 'Saxo Select Moderat',
                                'msStarsDate': null,
                                'msStars': null,
                                'weight': '1.9',
                            },
                            {
                                'name': 'Hammers Fonde - Lav, kl f',
                                'id': '5ebaa8b0ee946f0370722270',
                                'isin': null,
                                'weight': '0.1',
                                'msStarsDate': null,
                                'msStars': null,
                            },
                        ],
                    },
                    {
                        'oneTimeManagementCost': 0,
                        'managementCostRatio': 0,
                        'estimatedReturn': '0.0600',
                        'standardDeviation': '0.0500',
                        'srriVolatility': {
                            'inputAssets': [
                                {
                                    'id': '5dda1b248ff26c0001da71b2',
                                    'value': '0.5000',
                                },
                                {
                                    'value': '0.5000',
                                    'id': '5dda299d8ff26c0001da7a25',
                                },
                            ],
                            'inputDate': {
                                'seconds': 1632314735,
                                'nanoseconds': 848000000,
                            },
                            'value': 0.1375,
                        },
                        'name': '#7',
                        'excessTurnoverRatio': 0,
                        'id': '090c1830-84dd-4569-b62c-eeec88efb302',
                        'archived': false,
                        'assets': [
                            {
                                'weight': '0.5000',
                                'id': '5dda1b248ff26c0001da71b2',
                                'name': 'Investin Optimal VerdensIndex Moderat',
                                'msStarsDate': '2020-12-31T00:00:00Z',
                                'msStars': 5,
                                'calculationSettings': null,
                                'isin': 'DK0060254712',
                            },
                            {
                                'weight': '0.5000',
                                'id': '5dda299d8ff26c0001da7a25',
                                'msStarsDate': '2021-01-31T00:00:00Z',
                                'isin': 'DK0060005171',
                                'name': 'Maj Invest Danske Aktier',
                                'calculationSettings': null,
                                'msStars': 3,
                            },
                        ],
                    },
                    {
                        'excessTurnoverRatio': 0,
                        'archived': true,
                        'estimatedReturn': 0.05,
                        'managementCostRatio': 0,
                        'assets': [],
                        'standardDeviation': '0.0300',
                        'oneTimeManagementCost': 0,
                        'id': 'a559b02b-341a-4d98-8c8a-18f74fae0e85',
                        'srriVolatility': {
                            'inputAssets': [],
                            'value': null,
                            'inputDate': {
                                'seconds': 1612529742,
                                'nanoseconds': 340000000,
                            },
                        },
                        'name': '#8',
                    },
                    {
                        'name': '#9',
                        'managementCostRatio': 0,
                        'excessTurnoverRatio': 0,
                        'id': '94294525-8806-4ae9-accf-c5f21b56dc6d',
                        'assets': [],
                        'standardDeviation': 0.08,
                        'oneTimeManagementCost': 0,
                        'archived': true,
                        'estimatedReturn': 0.05,
                    },
                    {
                        'excessTurnoverRatio': 0,
                        'id': '8d0b3a0e-8e20-43c3-801c-60e1af152604',
                        'managementCostRatio': 0,
                        'name': '#10',
                        'estimatedReturn': 0.05,
                        'assets': [
                            {
                                'name': 'Maj Invest Danske Aktier W',
                                'weight': '1.0000',
                                'id': '5dda299d8ff26c0001da7a23',
                                'msStars': 3,
                                'isin': 'DK0060825487',
                                'msStarsDate': '2021-02-28T00:00:00Z',
                                'calculationSettings': null,
                                'firstPriceDate': null,
                            },
                        ],
                        'oneTimeManagementCost': '5000',
                        'srriVolatility': {
                            'value': 0.2,
                            'inputDate': {
                                'seconds': 1632314735,
                                'nanoseconds': 848000000,
                            },
                            'inputAssets': [
                                {
                                    'id': '5dda299d8ff26c0001da7a23',
                                    'value': '1.0000',
                                },
                            ],
                        },
                        'standardDeviation': 0.08,
                        'archived': false,
                    },
                    {
                        'oneTimeManagementCost': 0,
                        'standardDeviation': 0.08,
                        'archived': true,
                        'managementCostRatio': 0,
                        'excessTurnoverRatio': 0,
                        'id': '705774bb-0893-4546-a8e1-09d247b4233e',
                        'estimatedReturn': 0.05,
                        'assets': [],
                        'name': '#11',
                    },
                ],
                'customAssets': [
                    {
                        'id': '5dda346c8ff26c0001da80e9',
                        'isin': 'LU1009726545',
                        'name': 'Nordea 1 - Alpha 10 MA BI DKK',
                        'aaop': '0.0145',
                        'orgAaop': null,
                    },
                    {
                        'defaultAllocation': null,
                        'isin': null,
                        'orgAaop': 0.0098,
                        'allocation': null,
                        'name': 'Saxo Select Defensiv',
                        'id': '5f06de051ef26a0001597e08',
                        'aaop': null,
                    },
                    {
                        'name': 'Maj Invest Global Sundhed',
                        'aaop': 0.01,
                        'allocation': null,
                        'id': '5dda299c8ff26c0001da7a1f',
                        'isin': 'DK0060157196',
                        'defaultAllocation': {
                            'stock': 0.9933627,
                            'cash': 0.0066373,
                            'other': 0,
                            'bond': 0,
                        },
                        'orgAaop': 0.0142,
                    },
                    {
                        'aaop': '0.0100',
                        'isin': 'DK0060821064',
                        'id': '5dd9e0b68ff26c0001da5481',
                        'orgAaop': 0.0127,
                        'allocation': {
                            'other': 0,
                            'stock': 0.9,
                            'bond': '0.0100',
                            'cash': '0.0900',
                        },
                        'name': 'BankInvest Danske Aktier W',
                        'defaultAllocation': {
                            'other': 0.0011779,
                            'stock': 0.990371,
                            'cash': 0.0084511,
                            'bond': 0,
                        },
                    },
                ],
                'defaultValues': {
                    'managementCostRatio': '0.0035',
                    'managementCostRatioCurrentPortfolio': '0.0125',
                    'transactionCostIndirect': 0.0025,
                    'columnGraphSavingYears': {
                        'secondColumn': 10,
                        'thirdColumn': 15,
                        'fourthColumn': 20,
                        'firstColumn': 5,
                    },
                },
                'mifid': {
                    'questionnaires': [
                        {
                            'questionnaire': {
                                'questions': [
                                    {
                                        'id': '9e88301a-5d29-4d9c-9ba8-9a1b5007512d',
                                        'answers': [
                                            {
                                                'points': 1,
                                                'id': '531c5de3-8768-437e-b8bd-2804c8a3a2eb',
                                                'text': 'bruger beløbet til det sædvanlige indkøb i supermarkedet',
                                            },
                                            {
                                                'points': 4,
                                                'id': 'b2b3d317-8ebd-488b-955e-23b1ec640901',
                                                'text': 'køber lottokuponer for pengene',
                                            },
                                            {
                                                'id': '3f7a8eec-db7b-435c-ab8d-b83fa639a0d2',
                                                'points': 2,
                                                'text': 'sætter dem på en opsparingskonto',
                                            },
                                            {
                                                'points': 3,
                                                'id': 'c717fd46-fd1a-4a36-9b0e-9fb656a76adb',
                                                'text': 'køber aktier',
                                            },
                                        ],
                                        'text': 'Du vinder 1000 kr. til bordfodboldsturneringen fredag eftermiddag  på kontoret. Du',
                                    },
                                    {
                                        'text': 'To uger efter du købte 100 aktier til 100 kr. pr. aktie, er kursen steget til 150 kr. Du beslutter at',
                                        'id': '57d833f1-86f9-45d9-b09f-b343b05e59b3',
                                        'answers': [
                                            {
                                                'id': '1779334c-4f75-4d7f-a5dd-2ed829e7835e',
                                                'text': 'købe flere aktier; det er jo en klar vinder',
                                                'points': 4,
                                            },
                                            {
                                                'text': 'sælge dem og tage gevinsten',
                                                'points': 1,
                                                'id': '1326a2ed-c3c4-470f-9d6d-7e4f344b32b0',
                                            },
                                            {
                                                'id': 'a0a650a1-d1e5-4e69-be45-2c55d2a24e98',
                                                'points': 3,
                                                'text': 'sælge halvdelen for at dække nogle af omkostningerne og du beholder resten',
                                            },
                                            {
                                                'points': 2,
                                                'id': 'dc4d490d-b5e6-4ca6-9c82-06c5944b6a84',
                                                'text': 'følge udviklingen nøje og vente på yderligere stigninger',
                                            },
                                        ],
                                    },
                                    {
                                        'answers': [
                                            {
                                                'text': 'du ville ønske du havde investeret mere',
                                                'points': 3,
                                                'id': 'a29d1557-8078-4447-90e8-3a970dae5f48',
                                            },
                                            {
                                                'id': 'a73b018e-85ba-4d13-b5a1-03fab7ed9d59',
                                                'text': 'ringer til din investeringsrådgiver for at få anbefalinger',
                                                'points': 4,
                                            },
                                            {
                                                'id': 'a9faa509-f638-4d79-92e3-4e18b985bcb9',
                                                'text': 'du er bare glad for at du er ude af markedet fordi det svinger for meget',
                                                'points': 2,
                                            },
                                            {
                                                'text': 'du lægger dårligt nok mærke til kursstigningerne',
                                                'points': 1,
                                                'id': 'a54c2ff1-96d1-43b3-a2ed-f274572ffd1f',
                                            },
                                        ],
                                        'id': 'cd603ead-a3d4-46e1-be34-f590b624c545',
                                        'text': 'På dage hvor aktiemarkedet stiger kraftigt',
                                    },
                                    {
                                        'id': 'e2ea1d67-5b78-4c74-8af1-84ab561800f4',
                                        'answers': [
                                            {
                                                'points': 2,
                                                'text': 'vælger værelse med forplejning til 1000 kr.',
                                                'id': '2aca5960-97c9-47de-8614-97ce2f274b6b',
                                            },
                                            {
                                                'text': 'taler med andre som kender stedet om mulighederne for sidste øjebliks reservationer',
                                                'id': '570e6094-c42d-4848-9475-d09be1e868ef',
                                                'points': 3,
                                            },
                                            {
                                                'text': 'booker standby men tegner forsikring - just in case',
                                                'points': 1,
                                                'id': '3c8aa42e-01d4-498a-8a1f-4fa2c71630d7',
                                            },
                                            {
                                                'id': 'b4d86e61-ddf9-4298-915f-28d7e5ab0b12',
                                                'text': 'tager chancen med standby',
                                                'points': 4,
                                            },
                                        ],
                                        'text': 'Du planlægger en ferierejse og kan enten vælge et forudbestemt værelse med forplejning til 1000 kr. eller du booker standby rejse, som kan ende med at koste alt mellem 500 til 2000 kr. Du',
                                    },
                                    {
                                        'id': 'ba9ca75d-3393-4e15-8128-ce7a498f9dd7',
                                        'answers': [
                                            {
                                                'id': 'dcbbeb82-a1ec-4881-8533-e9961719d725',
                                                'text': 'køber lejligheden',
                                                'points': 3,
                                            },
                                            {
                                                'id': '741fe758-06f1-468f-940d-88be36f05eba',
                                                'text': 'køber din lejlighed og undersøger muligheden for at købe endnu en',
                                                'points': 4,
                                            },
                                            {
                                                'id': '22263828-fe78-40e2-af90-331ffac0e8e5',
                                                'points': 2,
                                                'text': 'sælger optionen og planlægger at forblive som lejer i din lejlighed',
                                            },
                                            {
                                                'text': 'sælger optionen og fraflytter fordi du tror at de nye ejerlejligheder vil tiltrække børnefamilier',
                                                'id': '24be0c41-42f3-4a08-9a75-e8c5811bedea',
                                                'points': 1,
                                            },
                                        ],
                                        'text': 'Ejeren af lejligheden du bor i, omdanner bygningen til ejerlejligheder. Du får tilbudt at købe din lejlighed for 2 mio. kr. eller en reservation af lejligheden for 100.000 kr. Lignende lejligheder er fornyligt blevet solgt for 2½ mio. kr. Den nye boligudgift vil med lån m.m. blive højere end din nuværende husleje. Du',
                                    },
                                    {
                                        'text': 'Du har arbejdet 3 år i en virksomhed i kraftig vækst. Som leder bliver du nu tilbudt at købe op til 2 pct. af selskabets aktier (2000 aktier af 50 kr. pr. aktie). Selvom virksomheden er unoteret og privatejet har hovedaktionæren haft succes med tre andre virksomheder han har solgt, og han planlægger også at sælge denne virksomhed på et senere tidspunkt. Du',
                                        'id': '152d72cf-2f48-49e5-9351-59df6bed39a7',
                                        'answers': [
                                            {
                                                'text': 'køber så mange aktier du kan og fortæller hovedaktionæren at du gerne vil købe flere',
                                                'id': '7b05b888-0d33-4289-8975-20200b49386b',
                                                'points': 4,
                                            },
                                            {
                                                'text': 'køber de aktier du blev tilbudt',
                                                'id': 'a4735c58-de78-45cb-bcef-497799d47e2f',
                                                'points': 3,
                                            },
                                            {
                                                'id': 'fc8e427a-4c9f-488d-9c56-43227b58c2a3',
                                                'text': 'køber halvdelen af dem du blev tilbudt',
                                                'points': 2,
                                            },
                                            {
                                                'text': 'køber en lille andel af det du fik tilbudt',
                                                'id': 'f9d7ab38-f997-4de3-93f8-aceb86e7fbf3',
                                                'points': 1,
                                            },
                                        ],
                                    },
                                    {
                                        'id': '45a71f6c-a476-4198-895c-ac462f302a58',
                                        'answers': [
                                            {
                                                'text': '2 kr. enarmede tyveknægte',
                                                'points': 1,
                                                'id': '03143f3e-fe9c-4418-8eb1-50dde3e8dc41',
                                            },
                                            {
                                                'text': 'enarmede tyveknægte med min. indsats på 20 kr.',
                                                'id': '0f783303-129f-4a24-ae7a-9a7c52a61978',
                                                'points': 3,
                                            },
                                            {
                                                'id': 'e4496ea5-3685-4085-a671-9b5336ade886',
                                                'text': '5 kroners maskiner',
                                                'points': 2,
                                            },
                                            {
                                                'text': '100 kroners maskiner',
                                                'id': 'eb49a325-6406-41c9-81cc-53b748ad0915',
                                                'points': 4,
                                            },
                                        ],
                                        'text': 'Du er på kasino for første gang. Du vælger at spille på',
                                    },
                                    {
                                        'id': '74ec3126-2bd4-450e-86f6-1934ec3829fd',
                                        'text': 'Du ønsker at invitere nogle kunder ud i en by du ikke har besøgt før. Hvordan vælger du stedet?',
                                        'answers': [
                                            {
                                                'points': 2,
                                                'text': 'læser restaurationsanmeldelser i den lokale avis',
                                                'id': '06302635-45a5-4571-b9dc-dc8951b9150d',
                                            },
                                            {
                                                'text': 'spørger stedkendte fra din arbejdsplads om et godt sted',
                                                'id': 'bece712b-f187-438e-a1e9-98ef2e31308a',
                                                'points': 3,
                                            },
                                            {
                                                'id': '876f7ff5-3ced-4cbb-9937-8c8b96f66cef',
                                                'text': 'ringer til den eneste du kender i byen - en nylig tilflytter som går meget ud',
                                                'points': 4,
                                            },
                                            {
                                                'text': 'besøger byen inden mødet for at undersøge muligheder',
                                                'points': 1,
                                                'id': '97aa9c50-14ea-4d34-af66-91de5ad063e9',
                                            },
                                        ],
                                    },
                                    {
                                        'id': '46673a6a-f278-4428-9cb5-784ae818da99',
                                        'answers': [
                                            {
                                                'id': 'e39db846-ec9f-4e8b-af5a-069a40670fb1',
                                                'text': 'ingen smerte, ingen fornøjelse',
                                                'points': 4,
                                            },
                                            {
                                                'text': 'just do it!',
                                                'points': 3,
                                                'id': '9bc5912e-b1d2-4ac2-919c-eb19111335b6',
                                            },
                                            {
                                                'text': 'ser dig for inden du tager springet',
                                                'points': 2,
                                                'id': 'eb2fe7c2-2aa1-4055-a166-1fd5bf6f60e0',
                                            },
                                            {
                                                'text': 'alt godt kommer til den som venter',
                                                'points': 1,
                                                'id': 'af252246-038d-4fb3-9558-3e0fc844aa70',
                                            },
                                        ],
                                        'text': 'Sætningen som bedst beskriver din livsstil er',
                                    },
                                    {
                                        'text': 'Din holdning til penge er bedst beskrevet som',
                                        'answers': [
                                            {
                                                'id': 'e1de1760-25fb-4137-a631-4be377f632c8',
                                                'points': 2,
                                                'text': 'hver eneste krone sparet, er en krone tjent',
                                            },
                                            {
                                                'points': 3,
                                                'id': 'ab1f900a-6025-4dad-976a-e059371d1021',
                                                'text': 'penge som er tjent, skal naturligvis bruges',
                                            },
                                            {
                                                'text': 'bær kun kontanter til det nødvendige',
                                                'points': 1,
                                                'id': '3ece66d2-7d20-4d43-9ce6-9e61e51ced31',
                                            },
                                            {
                                                'points': 4,
                                                'text': 'brug altid andres penge, når du kan slippe afsted med det',
                                                'id': 'ca5f2a95-a2de-4310-8ded-7f215ce2c31c',
                                            },
                                        ],
                                        'id': 'b1f5c5c1-0897-4d43-9a02-4d722ae6bbc3',
                                    },
                                ],
                                'placements': [
                                    {
                                        'lowerLimit': 0,
                                        'upperLimit': 17,
                                        'text': 'Du er ikke villig til at tage mange chancer med din opsparing, selvom det betyder at du ikke opnår det store i afkast.',
                                        'id': '14040b20-07b6-4adc-8077-6b75a582ed6d',
                                    },
                                    {
                                        'lowerLimit': 18,
                                        'text': 'Du er semi-konservativ - dvs. du er villig til at løbe en lille risiko med den nødvendige information.',
                                        'id': 'a9215178-6ffe-45f8-a1ec-8f9d918691d2',
                                        'upperLimit': 24,
                                    },
                                    {
                                        'lowerLimit': 25,
                                        'id': '0d6604de-cb85-42b2-82c3-02281aaab073',
                                        'text': 'Du er semi-aggressiv og villig til at tage risici hvis du tror at sandsynligheden for opnå et højere afkast falder ud til din fordel.',
                                        'upperLimit': 32,
                                    },
                                    {
                                        'lowerLimit': 33,
                                        'id': '5af9a670-ab6d-4f1e-85cf-797693bcfd69',
                                        'text': 'Du er aggressiv og altid på udkig efter en mulighed for at på opsparingen til at yngle, og selvom det til tider kan tage sin tid. Du ser penge som et middel til at tjene endnu flere.',
                                        'upperLimit': 40,
                                    },
                                ],
                            },
                            'id': '08eebd85-840a-4adf-845d-3f2418903bd3',
                            'name': 'Default Questionnaire',
                        },
                    ],
                },
                'name': 'ML Advice',
                'config': {
                    'notificationEmails': 'asbjoern@msslink.dk, asbjoern@gmail.com, anders@msslink.dk',
                    'features': [
                        'teal-integration',
                        'apps',
                        'mifid',
                        'pensionstjek',
                    ],
                },
                'integrations': {
                    'teal': {
                        'enabled': true,
                    },
                },
                'partnerId': 'ml-advice',
            },
            'site': {
                'data': {},
                'theme': {
                    'colors': {
                        'primary': '#0F8685',
                        'secondary': '#01364F',
                        'logoBackgroundColor': '#032D40',
                        'sidebarBackground': '#F4F6F8',
                        'mainBackground': '#F4F6F8',
                        'staticColors': {
                            'positive': '#4CAF50',
                            'warning': '#FFB74D',
                            'negative': '#af111c',
                        },
                        'primaries': {
                            'light': '#019f9e',
                            'lighter': '#00b9b7',
                            'dark': '#166c6b',
                            'darker': '#195352',
                        },
                        'secondaries': {
                            'light': '#004768',
                            'lighter': '#005882',
                            'dark': '#062635',
                            'darker': '#05141c',
                        },
                        'extras': [
                            '#850f86',
                            '#86840f',
                        ],
                    },
                },
                'globalLoading': false,
            },
        }
        , null, 4),
    },
    {
        disabled: true,
        key: 2,
        text: 'PensionsTjek 1',
        value: JSON.stringify({
            customerName: 'Kourosh',
            reportType: 'pensionCheck',
        }, null, 4),
    },
];
