import { useLocation } from 'react-router';

export default function useSearchParams() {
    const params = new URLSearchParams(useLocation());
    const search = params.get('search');
    if (search === '') return;

    const parameters = search.slice(1).split('&').reduce((a, p) => {
        const split = p.split('=');
        return { ...a, [split[0]]: split[1] };
    }, {});

    return parameters;
}
