import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const NavBar = (props) => {
    const { mobileOpen, isMobile, setMobileOpen, logoPath, maxWidth } = props;
    const { theme } = useSelector(state => state.site);
    return (
        <AppBar position='fixed' color='secondary'>
            { isMobile && (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 15,
                    height: '100%',
                }}>
                    { !mobileOpen && (
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={ () => setMobileOpen(true) }
                            edge='start'
                            // className={clsx(classes.menuButton, open && classes.hide)}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                    ) }

                    <div style={{
                        position: 'absolute',
                        left: '50%',
                        '-webkit-transform': 'translateX(-50%)',
                        transform: 'translateX(-50%)',
                    }}>
                        <img src={ logoPath } alt='logo' style={{ width: '80px', marginTop: '9px' }}/>
                    </div>
                </div>
            ) }
            <div style={{ display: isMobile && 'none' }}>
                <div style={{
                    backgroundColor: theme.colors.logoBackgroundColor,
                    minWidth: 184,
                    height: 60,
                    float: 'left',
                    zIndex: 2,
                }}>
                    <div style={{
                        backgroundColor: theme.colors.logoBackgroundColor,
                        height: 60,
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 18,
                    }}>
                        <img src={ logoPath } alt='logo' style={{ width: '80px', marginTop: '9px' }}/>
                    </div>
                </div>
                <div
                    style={{
                        marginLeft: 140,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 30,
                        paddingRight: 30,
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            maxWidth: `${maxWidth  }px`,
                            justifyContent: 'space-between',
                            display: 'flex',
                        }}
                    >
                        <h1
                            id='page-name'
                            style={{
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 22,
                                lineHeight: '26px',
                                color: '#ffffff',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        />
                        <div id='page-actions-top' />
                    </div>
                </div>
            </div>
        </AppBar>
    );
};

NavBar.defaultProps = {};

NavBar.propTypes = {
    mobileOpen: PropTypes.bool,
    isMobile: PropTypes.bool,
    setMobileOpen: PropTypes.func,
    logoPath: PropTypes.string,
    maxWidth: PropTypes.any,
};

export default NavBar;
