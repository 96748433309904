import React from 'react';

import PageTemplate from './Pages/PageTemplate/PageTemplate';

class PensionCheckReport extends React.Component {


    // eslint-disable-next-line class-methods-use-this
    render() {
        return (
            <div>
                <PageTemplate>
                    <h1>PensionsTjek</h1>
                </PageTemplate>
            </div>
        );
    }
}

PensionCheckReport.propTypes = {

};

export default PensionCheckReport;
