import React from 'react';
import PropTypes from 'prop-types';
import DefaultFooter from './DefaultFooter';

export default function UvildigraadFooter({ alternativeFooter }) {
    return <DefaultFooter alternativeFooter={ alternativeFooter } footerText='Uvildigraad.dk ApS | Rosenkæret 13, 2 mf. 2860 Søborg | kontakt@uvildigraad.dk | CVR: 35210679'/>;
}

UvildigraadFooter.propTypes = {
    alternativeFooter: PropTypes.bool,
};

UvildigraadFooter.defaultProps = {
    alternativeFooter: false,
};
