import { all, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { getScrape } from '../apiRepository/teal';
import { setFileMetaData, getCustomer } from '../databaseRepository/customerDataManager';

const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms));

function* getScrapeSaga({ payload }) {
    const { customerId, scrapeId, fileId } = payload;

    const scrape = yield getScrape({ scrapeId });

    if (scrape.status === 'finished' || scrape.status === 'failed') {
        // scrape ended
        return yield put({ type: 'SCRAPES_SAVE', payload: { customerId, fileId, scrape } });
    }

    // else polling for changes
    yield sleep(2000);
    yield put({ type: 'SCRAPES_REQUEST_GET', payload });
}

function* saveData({ payload }) {
    const { customerId, fileId, scrape: { content, externalAccessToken } } = payload;
    const { partnerId } = yield select(state => state.partnerData);

    yield setFileMetaData({ customerId, partnerId }, fileId, { scraping: false, content, externalAccessToken });

    const customerData = yield getCustomer(partnerId, customerId);
    yield put({ type: 'CUSTOMER_DATA_UPDATED', customerData, customerId });
}

function* detectLostScrapes({ customerData }) {
    const { files, customerId } = customerData;

    for (let index = 0; index < files.length; index += 1) {
        const file = files[index];
        if (file.metadata.scraping) {
            const { fileId, metadata: { scrapeId } } = file;
            yield put({
                type: 'SCRAPES_REQUEST_GET',
                payload: { customerId, fileId, scrapeId },
            });
        }
    }
}


export default function* authSaga() {
    yield all([
        takeEvery('SCRAPES_REQUEST_GET', getScrapeSaga),
        takeLatest('SCRAPES_SAVE', saveData),
        takeLatest('CUSTOMER_DATA_UPDATED', detectLostScrapes),
    ]);
}
