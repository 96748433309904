import initialState from './initialState';
import * as types from '../actions/actionTypes';


export default function authReducer(state = initialState.auth, action) {
    switch (action.type) {
        case types.AUTH_SIGNOUT: {
            return { ...state, globalLoading: false, loggedIn: false, loginFailed: false };
        }
        case types.AUTH_DATA_LOADED: {
            return {
                ...state,
                globalLoading: false,
                loggedIn: true,
                authUser: action.authUser ?? state.authUser,
                user: action.user ?? state.user,
                advisor: action.advisor ?? state.advisor,
                loginFailed: false,
            };
        }
        case types.AUTH_DATA_REQUESTED: {
            return { ...state, globalLoading: true, loginFailed: false };
        }
        case types.AUTH_NOT_LOGGED_IN: {
            return { ...state, globalLoading: false, loggedIn: false, loginFailed: false };
        }
        case types.AUTH_LOGIN_FAILED: {
            return { ...state, globalLoading: false, loggedIn: false, loginFailed: true };
        }
        default:
            return state;
    }
}
