import { all } from 'redux-saga/effects';

import authSaga from './auth';
import configSaga from './config';
import siteSaga from './site';
import integrationsSaga from './integrations';
import scrapesSaga from './scrapes';

export default function* rootSaga() {
    yield all([
        siteSaga(),
        authSaga(),
        configSaga(),
        integrationsSaga(),
        scrapesSaga(),
    ]);
}
