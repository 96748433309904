import { v4 as uuidv4 } from 'uuid';
import set from 'lodash.set';
import cloneDeep from 'lodash.clonedeep';
import * as types from './actionTypes';
import { getProcess, updateProcess, getAllProcesses } from '../databaseRepository/processDataManager';

export function getProcessData(customerId, processId) {
    return async (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: types.PROCESS_DATA_REQUESTED });
        const processData = await getProcess(auth.user.partnerId, customerId, processId);
        return dispatch({ type: types.PROCESS_DATA_LOADED, processData });
    };
}

export function updateProcessData(processData) {
    return async (dispatch) => {
        await updateProcess(processData);
        dispatch({ type: types.PROCESS_DATA_UPDATED, processData });
    };
}

export function updateProcessField(path, value) {
    return async (dispatch, getState) => {
        const { processData } = getState();

        const newProcessData = cloneDeep(processData);
        set(newProcessData, path, value);

        await updateProcess(newProcessData);

        dispatch({ type: types.PROCESS_DATA_UPDATED, processData: newProcessData });
    };
}

export function updateProcessDataField(processId, fieldName, fieldValue) {
    return async (dispatch, getState) => {
        dispatch({ type: types.PROCESS_DATA_FIELD_UPDATED, processId, fieldName, fieldValue });
        const { processesData } = getState();
        const { processes } = processesData;
        const process = processes.find(x => x.processId === processId);
        await updateProcess(process);
    };
}

export function createProcess(processData) {
    return async (dispatch, getState) => {
        const { auth, processesData } = getState();

        const processId = uuidv4();
        const now = new Date();
        const newProcessData = {
            partnerId: auth.user.partnerId,
            advisorId: auth.user.advisorId,
            deleted: false,
            createdAt: now,
            processId,
            ...processData,
        };

        await updateProcess(newProcessData);

        dispatch({ type: types.PROCESS_DATA_UPDATED, processData: newProcessData });
        dispatch({ type: types.PROCESSES_DATA_UPDATED, processesData: { processes: [...processesData.processes, newProcessData] } });

        return newProcessData;
    };
}

export function getProcesses(customerId) {
    return async (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: types.PROCESSES_DATA_UPDATE_REQUEST });
        const processes = await getAllProcesses(auth.user.partnerId, customerId);
        return dispatch({ type: types.PROCESSES_DATA_UPDATED, processesData: { processes } });
    };
}
