import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const ErrorBullet = props => {
    const theme = useTheme();
    return (
        <div style={{ color: theme.palette.error.main }}>
            { props.children }
        </div>
    );
};

ErrorBullet.defaultProps = {

};

ErrorBullet.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBullet;
