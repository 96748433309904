import React from 'react';

export default function DefaultFrontPage() {
    return (
        <>
            <p style={{ fontSize: 40, display: 'block' }}>monax</p>
            <p style={{ fontSize: 20, display: 'block' }}>- for rigtige rådgivere -</p>
        </>
    );
}
