import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';

const Tabs = (props) => {
    const handleChange = (event, newValue) => {
        props.setValue(newValue);
    };

    return (
        <div>
            <MuiTabs
                sx={{
                    '&.MuiTabs-root': {
                        borderBottom: '1px solid #eaeaea',
                        height: 55,
                    },
                }}
                value={ props.value }
                onChange={ handleChange }
                textColor='secondary'
                indicatorColor='secondary'
            >
                { props.tabs.map((tab, i) => (
                    <MuiTab
                        sx={{
                            '&.MuiTab-root':{
                                color: 'secondary.main',
                                textTransform: 'none',
                                minWidth: 125,
                                fontWeight: 'normal',
                                fontSize: '14px',
                                marginRight: 4,
                            },
                            '&.MuiTab-textColorSecondary': {
                                opacity: 0.7,
                            },
                            '&.Mui-selected': {
                                opacity: 1,
                            },
                        }}
                        disableRipple
                        label={ tab.label }
                        key={ i }
                        disabled={ tab.disabled } />
                )) }
            </MuiTabs>
        </div>
    );
};

Tabs.defaultProps = {
    tabs: [],
};

Tabs.propTypes = {
    tabs: PropTypes.array,
    setValue: PropTypes.func,
    value: PropTypes.node,
};

export default Tabs;
