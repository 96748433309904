import DefaultQuestionnaire from '@algostrata/risk-questionnaire/data/DefaultQuestionnaire';
import { defaultTransactionCostIndirect, defaultManagementCostRatio } from '../defaultValues';
import defaultOnboardingQuestionnaires from './defaultOnboardingQuestionnaires';

export default {
    // *************** AUTH ***************
    auth: {
        isFetching: false,
        loggedIn: false,
        loginFailed: false,
        globalLoading: true,
    },
    // *************** USER DATA ***************
    customerData: {
        customers: [],
        isFetching: false,
        riskProfile: 'moderat risiko',
    },
    advisorData: { isFetching: false, advisor: { }, advisors: { } },
    // ******************* PARTNER DATA *******************
    partnerData: {
        loaded: false,
        isFetching: false,
        pensionsInfoSettingsData: {
            providerDataList: [
            ],
        },
        modelPortfolios: [],
        customAssets: [],
        defaultValues: {
            managementCostRatio: defaultManagementCostRatio,
            transactionCostIndirect: defaultTransactionCostIndirect,
            columnGraphSavingYears: {
                firstColumn: 5,
                secondColumn: 10,
                thirdColumn: 15,
                fourthColumn: 20,
            },
        },
        mifid: {
            questionnaires: [ { id: '08eebd85-840a-4adf-845d-3f2418903bd3', name: 'Default Questionnaire', questionnaire: DefaultQuestionnaire } ],
        },
        onboarding: {
            questionnaireVersion: 1,
            questionnaires: defaultOnboardingQuestionnaires,
        },
    },
    // ********************** PENSION DATA ****************
    projection: {
        isCalculating: false,
    },
    documentData: {
        isFetching: false,
        documents: [],
    },
    wealthData: {},
    processesData: { processes: [] },
    adviceModuleData: { modules: [
        { type: 'investCheck', title: 'InvestTjek', description: 'Start en omkostningsoptimering', image: 'investCheck.jpg', disabled: false },
        { type: 'pensionCheck', title: 'PensionsTjek', description: 'Start et PensionsTjek', image: 'pensionCheck.jpg', disabled: false },
    ] },
    aaopData: {},
    providerData: { providers: [] },
    processData: { isFetching: false },
    investData: { isFetching: false },
    mifidProcessesData: { isFetching: false, processes: [] },
    onboardingProcessesData: { isFetching: false, processes: [] },
    config: {},
    uniify: {},
    site: {
        data: {
            // eg. logo url or similar
        },
        theme: {
            colors: {
                primary: '#0F8685',
                secondary: '#01364F',
                logoBackgroundColor: '#032D40',
                sidebarBackground: '#F4F6F8',
                mainBackground: '#F4F6F8',
            },
        },
    },
    integrations: { loading: true, ready: false },
};
