import initialState from './initialState';
import * as types from '../actions/actionTypes';


export default function integrationsReducer(state = initialState.integrations, action) {
    switch (action.type) {
        case types.INTEGRATIONS_UPDATED: {
            return { ...state, ...action.payload, loading: false };
        }
        case types.INTEGRATIONS_LOADING: {
            return { ...state, loading: true };
        }
        case types.INTEGRATIONS_FAILED: {
            return { ...state, error: action.payload.error, loading: false };
        }
        case types.INTEGRATIONS_RESET: {
            return initialState.integrations;
        }
        default:
            return state;
    }
}
