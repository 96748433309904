import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function TextButton({ children, onClick, disabled, style }) {
    return (
        <Button style={{ ...style }} variant='text' disableElevation onClick={ onClick } disabled={ disabled }>
            { children }
        </Button>
    );
}

TextButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    style: PropTypes.object,
};

TextButton.defaultProps = {
    disabled: false,
    style: {},
};
