import omit from 'lodash/omit';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { processesRef } from './processDataManager';

function mifidDataPocessesRef(partnerId, customerId, processId) {
    return processesRef(partnerId, customerId).doc(processId).collection('data').doc('mifidData');
}

function _updateMifidData(mifidData) {
    return mifidDataPocessesRef(mifidData.partnerId, mifidData.customerId, mifidData.processId)
        .set(omit(mifidData, ['partnerId', 'customerId', 'processId']));
}

const updatedMifidDataDebounced = AwesomeDebouncePromise(_updateMifidData, 1000, {
    key: (mifidProcess) => `${mifidProcess.customerId}${mifidProcess.processId}`,
});

export function updateMifidData(mifidData) {
    return updatedMifidDataDebounced(mifidData);
}

export function updateMifidProcess(process) {
    return processesRef(process.partnerId, process.customerId)
        .doc(process.processId).set(omit(process, ['partnerId', 'customerId', 'processId', 'mifidData', 'isUpdating']));
}

export async function getMifidProcess(partnerId, customerId, processId) {
    const process = await processesRef(partnerId, customerId).doc(processId).get();
    if(process.exists){
        const processData = process.data();
        const mifidData = await mifidDataPocessesRef(partnerId, customerId, process.id).get();
        return {
            ...processData,
            mifidData: mifidData.data(),
            partnerId,
            customerId,
            processId: process.id,
        };
    }
    return { partnerId, customerId, processId };
}

export async function getAllMifidProccess(partnerId, customerId) {
    const processes = await processesRef(partnerId, customerId).where('type', '==', 'mifidTest').get();
    const mappedData = await Promise.all(processes.docs.map(async p => {
        const mifidData = await mifidDataPocessesRef(partnerId, customerId, p.id).get();
        return {
            ...p.data(),
            mifidData: mifidData.data(),
            partnerId,
            customerId,
            processId: p.id,
        };

    }));
    return mappedData;
}
