import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { renderKr, renderPercent } from '../../utils/renderingService';

export default function MifidCostTable({ currentCostResult, targetCostResult, hasOneTimeManagmentCost }) {
    const savingInKr = currentCostResult.totalFees.value - targetCostResult.totalFees.value;
    const savingInKrPercent = savingInKr / currentCostResult.totalFees.value;
    const savingInKrFirstYear = currentCostResult.totalFees.value - targetCostResult.totalFeesFirstYear.value;
    const savingInKrFirstYearPercent = savingInKrFirstYear / currentCostResult.totalFees.value;

    return (
        <Paper variant="outlined">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Nuværende portefølje</TableCell>
                        <TableCell>Foreslået portefølje</TableCell>
                        <TableCell>Forskel</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell><b>Synlige omkostninger</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Pleje-/forvaltningsomkostninger</TableCell>
                        <TableCell>{ renderKr(currentCostResult.portfolioManagementYearlyFee.value) }</TableCell>
                        <TableCell>{ renderKr(targetCostResult.portfolioManagementYearlyFee.value) }</TableCell>
                        <TableCell>{ renderKr(currentCostResult.portfolioManagementYearlyFee.value - targetCostResult.portfolioManagementYearlyFee.value) }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Ikke synlige omkostninger</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Spreadomkostninger på depot</TableCell>
                        <TableCell>{ renderKr(currentCostResult.totalAssetEntryExitCost.value) }</TableCell>
                        <TableCell>{ renderKr(targetCostResult.totalAssetEntryExitCost.value) }</TableCell>
                        <TableCell>{ renderKr(currentCostResult.totalAssetEntryExitCost.value - targetCostResult.totalAssetEntryExitCost.value) }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Løbende omkostninger</TableCell>
                        <TableCell>{ renderKr(currentCostResult.totalAssetOngoingCost.value) }</TableCell>
                        <TableCell>{ renderKr(targetCostResult.totalAssetOngoingCost.value) }</TableCell>
                        <TableCell>{ renderKr(currentCostResult.totalAssetOngoingCost.value - targetCostResult.totalAssetOngoingCost.value) }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Handelsomkostninger inde i foreningerne</TableCell>
                        <TableCell>{ renderKr(currentCostResult.totalAssetTransactionFees.value) }</TableCell>
                        <TableCell>{ renderKr(targetCostResult.totalAssetTransactionFees.value) }</TableCell>
                        <TableCell>{ renderKr(currentCostResult.totalAssetTransactionFees.value - targetCostResult.totalAssetTransactionFees.value) }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Performance omkostninger inde i foreningerne</TableCell>
                        <TableCell>{ renderKr(currentCostResult.totalAssetPerformanceFees.value) }</TableCell>
                        <TableCell>{ renderKr(targetCostResult.totalAssetPerformanceFees.value) }</TableCell>
                        <TableCell>{ renderKr(currentCostResult.totalAssetPerformanceFees.value - targetCostResult.totalAssetPerformanceFees.value) }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Total omkostninger</b></TableCell>
                        <TableCell>{ renderKr(currentCostResult.totalFees.value) }</TableCell>
                        <TableCell>{ renderKr(targetCostResult.totalFees.value) }  { (() => {if (hasOneTimeManagmentCost) return `(${renderKr(targetCostResult.totalFeesFirstYear.value)})*`;})() }</TableCell>
                        <TableCell>{ renderKr(savingInKr) } { (() => {if (hasOneTimeManagmentCost) return `(${renderKr(savingInKrFirstYear)})*`;})() }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Total</b></TableCell>
                        <TableCell>{ renderPercent(currentCostResult.totalFeesInPercent.asFraction) }</TableCell>
                        <TableCell>{ renderPercent(targetCostResult.totalFeesInPercent.asFraction) }  { (() => {if (hasOneTimeManagmentCost) return `(${renderPercent(targetCostResult.totalFeesInPercentFirstYear.asFraction)})*`;})() }</TableCell>
                        <TableCell>{ renderPercent(currentCostResult.totalFeesInPercent.asFraction - targetCostResult.totalFeesInPercent.asFraction) } { (() => {if (hasOneTimeManagmentCost) return `(${renderPercent(currentCostResult.totalFeesInPercent.asFraction - targetCostResult.totalFeesInPercentFirstYear.asFraction)})*`;})() }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Årlig besparelse i %</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{ renderPercent(savingInKrPercent) } { (() => {if (hasOneTimeManagmentCost) return `(${renderPercent(savingInKrFirstYearPercent)})*`;})() }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Årlig besparelse i kroner</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{ renderKr(savingInKr) } { (() => {if (hasOneTimeManagmentCost) return `(${renderKr(savingInKrFirstYear)})*`;})() }</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
}

MifidCostTable.propTypes = {
    currentCostResult: PropTypes.object.isRequired,
    targetCostResult: PropTypes.object.isRequired,
    hasOneTimeManagmentCost: PropTypes.bool.isRequired,
};
