import React from 'react';
import PropTypes from 'prop-types';

class UvildigraadPageTemplate extends React.Component {
    render() {
        const { children, alternativeFooter } = this.props;
        return (
            <div
                className="page A4-landscape"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                    backgroundImage: 'url(/assets/img/uvildigraad/baggrund.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '640px',
                    backgroundPosition: '-200px -50px',
                }}
            >
                <div style={{ height: '40px' }} />
                <div style={{ flexGrow: 1, margin: '10px 20px' }}>{ children }</div>
                { !alternativeFooter ? (
                    <div style={{ height: '40px', textAlign: 'center', color: '#999999' }}>
                        Uvildigraad.dk ApS | Rosenkæret 13, 2 mf. 2860 Søborg | kontakt@uvildigraad.dk | CVR: 35210679
                    </div>
                ) : (
                    <div style={{ height: '40px', textAlign: 'center' }}>
                        Uvildigraad.dk ApS | Rosenkæret 13, 2 mf. 2860 Søborg | kontakt@uvildigraad.dk | CVR: 35210679
                    </div>
                ) }
            </div>
        );
    }
}

UvildigraadPageTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    alternativeFooter: PropTypes.bool,
};

UvildigraadPageTemplate.defaultProps = {
    alternativeFooter: false,
};

export default UvildigraadPageTemplate;
