import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, Tooltip } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

export default function WarningIcon({ style, listItemIcon, toolTip }) {
    let warningIcon = <ReportProblemOutlinedIcon style={{ color: 'orange', fontSize: 'default', verticalAlign:'middle', ...style }}/>;

    if(toolTip) warningIcon = <Tooltip title={ toolTip }>{ warningIcon }</Tooltip>;
    if(listItemIcon) warningIcon = <ListItemIcon>{ warningIcon }</ListItemIcon>;

    return warningIcon;
}

WarningIcon.propTypes = {
    style: PropTypes.object,
    listItemIcon: PropTypes.bool,
    toolTip: PropTypes.string,
};

WarningIcon.defaultProps = {
    listItemIcon: false,
    toolTip: null,
};
