import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

export default function AddressInputComponent({ onChange, value, style, variant, helperText }) {
    const [internalValue, setInternalValue] = useState(value ?? null);
    const [inputValue, setInputValue] = useState('');
    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        setInternalValue(value ?? null);
    }, [value]);

    async function findPropertyAddress(address) {
        const resultCountMax = 30;
        const response = await fetch(`https://dawa.aws.dk/autocomplete?per_side=${resultCountMax}&type=adresse&startfra=adresse&q=${address}`);
        return response.json();;
    }

    const findPropertyAddressDebounced = useMemo(() =>AwesomeDebouncePromise(findPropertyAddress, 400), []);

    useEffect(() => {
        async function getAddresses() {
            if (inputValue === '') {
                setAddresses([]);
                return;
            }
            const addressResult = await findPropertyAddressDebounced(inputValue);

            if (addressResult) {
                const addressList = addressResult.map(addressData => ({
                    id: addressData.data.id,
                    title: addressData.tekst,
                    roadname: addressData.data.vejnavn,
                    housenumber: addressData.data.husnr,
                    zipcode: addressData.data.postnr,
                    city: addressData.data.postnrnavn,
                    floornumber: addressData.data.etage,
                    doornumber: addressData.data.dør,
                    coordinatex: addressData.data.x,
                    coordinatey: addressData.data.y,
                }));
                if(internalValue !== null && internalValue?.id !== null && internalValue?.title !== null && !addressList.some(x => x.id === internalValue.id)) addressList.unshift(internalValue);

                setAddresses(addressList);
            } else {
                setAddresses([]);
            }
        }

        getAddresses();

    }, [internalValue, inputValue, findPropertyAddressDebounced]);

    return (
        <Autocomplete
            getOptionLabel={ (option) => typeof option === 'string' ? option : option.title }
            filterOptions={ (x) => x }
            options={ addresses }
            isOptionEqualToValue={ (option, x) => option?.id === x?.id }
            autoComplete
            autoHighlight
            includeInputInList
            forcePopupIcon={ false }
            value={ internalValue }
            style={{ ...style }}
            onChange={ (event, newValue) => { setInternalValue(newValue); onChange(newValue); } }
            onInputChange={ (event, newInputValue) => { setInputValue(newInputValue); } }
            renderInput={ (params) => (
                <TextField { ...params }
                    label="Adresse"
                    variant={ variant }
                    style={{ ...style }}
                    helperText={ helperText }
                />
            ) }
        />
    );
}

AddressInputComponent.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    variant: PropTypes.string,
    helperText: PropTypes.string,
};
