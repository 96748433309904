import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const NavigateBackComponent = (props) => {
    const getPrevRoute = (path) => path.substring(0, path.lastIndexOf('/'));
    const { pathname } = useLocation();
    const prevRoute = getPrevRoute(pathname.replace(/\/$/, ''));
    return (
        <Link
            to={ props.skip ? getPrevRoute(prevRoute) : prevRoute }
            component={ RouterLink }
            color='inherit'
            underline="hover">
            Tilbage
        </Link>
    );
};

NavigateBackComponent.defaultProps = {};

NavigateBackComponent.propTypes = {
    skip: PropTypes.bool,
};

export default NavigateBackComponent;
