import omit from 'lodash/omit';
import { db } from './database';

export function advisorsRef(partnerId) {
    return db.collection(`partners/${partnerId}/advisors`);
}

export async function getAdvisor(partnerId, advisorId) {
    const advisor = await advisorsRef(partnerId).doc(advisorId).get();
    if (advisor.exists) return { ...advisor.data(), partnerId, advisorId };
    return { partnerId, advisorId };
}

export async function getAllAdvisors(partnerId) {
    const advisors = await advisorsRef(partnerId).get();
    return advisors.docs.map(a => ({ ...a.data(), partnerId, advisorId: a.id }));
}

export function updateAdvisor(advisor) {
    return advisorsRef(advisor.partnerId).doc(advisor.advisorId).set(omit(advisor, ['partnerId', 'advisorId']));
}
