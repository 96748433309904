import React from 'react';
import PropTypes from 'prop-types';
import { renderDate } from '../../../../utils/renderingService';

export default function DefaultHeader({ alternativeHeader, children, date }) {

    const parsedDate = date.seconds ? new Date(date.seconds * 1000) : new Date(date);

    return (
        <div style={{ width: '100%', color: `${alternativeHeader ? 'inherit' : '#999999'}`, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 22.4px', fontSize: '8px' }}>
            <div style={{ flex: '15%', textAlign: 'left' }}><p>MiFID Test</p></div><div style={{ flex: '70%' }}>{ children }</div><div style={{ flex: '15%', textAlign: 'right' }}>{ renderDate(parsedDate) }</div>
        </div>
    );
}
DefaultHeader.propTypes = {
    alternativeHeader: PropTypes.bool,
    headerText: PropTypes.string,
    children: PropTypes.node,
    date: PropTypes.any,
};

DefaultHeader.defaultProps = {
    alternativeHeader: false,
    children: <><p style={{ fontSize: 20, margin: 0, textAlign: 'center' }}>monax</p><p style={{ fontSize: 10, margin: 0, textAlign: 'center' }}>- for rigtige rådgivere -</p></>,
    date: new Date(),
};
