import axios from 'axios';
import { config } from '../config';

const baseURL = config.teal.calculationApiUrl;

export async function pensionAnalysis(file) {
    const response = await axios(`${baseURL}/api/PensionAnalysis`, {
        method: 'POST',
        data: { scrape: file.metadata.content },
    });

    return response.data;
}
