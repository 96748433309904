import { v4 as uuidv4 } from 'uuid';
import initialState from './initialState';
import * as types from '../actions/actionTypes';

function getIndexFromIdField(array, id) {
    return array.findIndex(modelPortfolio => modelPortfolio.id === id);
}

export default function partnerDataReducer(state = initialState.partnerData, action) {
    switch (action.type) {
        case types.PARTNER_DATA_REQUESTED:
            return { ...state, ...action.partnerData, isFetching: true };
        case types.PARTNER_DATA_UPDATED:
            return { ...state, ...action.partnerData, isFetching: false };
        case types.PARTNER_DATA_CUSTOM_ASSET_ADDED:
            return {
                ...state,
                customAssets: [...state.customAssets, { id: '', name: '', isin: '', aaop: null, orgAaop: 0 }],
            };
        case types.PARTNER_DATA_CUSTOM_ASSET_UPDATED:
        {
            const { customAssets } = state;
            return {
                ...state,
                customAssets: [
                    ...customAssets.slice(0, action.index),
                    action.customAsset,
                    ...customAssets.slice(action.index + 1),
                ],
            };
        }
        case types.PARTNER_DATA_CUSTOM_ASSET_FIELD_UPDATED:
        {
            const { customAssets } = state;
            const customAsset = customAssets[action.index];
            return {
                ...state,
                customAssets: [
                    ...customAssets.slice(0, action.index),
                    { ...customAsset, [action.name]: action.value },
                    ...customAssets.slice(action.index + 1),
                ],
            };
        }
        case types.PARTNER_DATA_CUSTOM_ASSET_DELETED:
        {
            const { customAssets } = state;
            return {
                ...state,
                customAssets: [
                    ...customAssets.slice(0, action.index),
                    ...customAssets.slice(action.index + 1),
                ],
            };
        }
        case types.PARTNER_DATA_DEFAULT_VALUE_UPDATED:
            return {
                ...state,
                defaultValues: {
                    ...state.defaultValues,
                    [action.name]: action.value,
                },
            };
        case types.PARTNER_DATA_MODEL_PORTFOLIO_ADDED:
        {
            const modelPortfolioId = uuidv4();

            const newModelportfolio = {
                id: modelPortfolioId,
                excessTurnoverRatio: 0,
                oneTimeManagementCost: 0,
                managementCostRatio: 0,
                estimatedReturn: 0.05,
                standardDeviation: 0.08,
                name: `#${state.modelPortfolios.length + 1}`,
                archived: false,
                assets: [],
            };
            return {
                ...state,
                modelPortfolios: [
                    ...state.modelPortfolios,
                    newModelportfolio,
                ],
            };
        }
        case types.PARTNER_DATA_MODEL_PORTFOLIO_ALL_UPDATED:
        {
            return {
                ...state,
                modelPortfolios: action.modelPortfolios,
            };
        }
        case types.PARTNER_DATA_MODEL_PORTFOLIO_FIELD_UPDATED:
        {
            const { modelPortfolios } = state;
            const index = getIndexFromIdField(modelPortfolios, action.modelPortfolioId);
            const modelPortfolio = modelPortfolios[index];
            const updatedModelPortfolio = {
                ...modelPortfolio,
                [action.name]: action.value,
            };

            return {
                ...state,
                modelPortfolios: [
                    ...modelPortfolios.slice(0, index),
                    updatedModelPortfolio,
                    ...modelPortfolios.slice(index + 1),
                ],
            };
        }
        case types.PARTNER_DATA_MODEL_PORTFOLIO_ASSET_ADDED:
        {
            const { modelPortfolios } = state;
            const index = getIndexFromIdField(modelPortfolios, action.modelPortfolioId);
            const modelPortfolio = modelPortfolios[index];
            const updatedModelPortfolio = {
                ...modelPortfolio,
                assets: [ ...modelPortfolio.assets, { id: '', isin: '', msStars: '', msStarsDate: '', name: '', weight: 0 }],
            };

            return {
                ...state,
                modelPortfolios: [
                    ...modelPortfolios.slice(0, index),
                    updatedModelPortfolio,
                    ...modelPortfolios.slice(index + 1),
                ],
            };
        }
        case types.PARTNER_DATA_MODEL_PORTFOLIO_ASSET_DELETED:
        {
            const { modelPortfolios } = state;
            const index = getIndexFromIdField(modelPortfolios, action.modelPortfolioId);
            const modelPortfolio = modelPortfolios[index];
            const updatedModelPortfolio = {
                ...modelPortfolio,
                assets: [
                    ...modelPortfolio.assets.slice(0, action.assetIndex),
                    ...modelPortfolio.assets.slice(action.assetIndex + 1),
                ],
            };

            return {
                ...state,
                modelPortfolios: [
                    ...modelPortfolios.slice(0, index),
                    updatedModelPortfolio,
                    ...modelPortfolios.slice(index + 1),
                ],
            };
        }
        case types.PARTNER_DATA_MODEL_PORTFOLIO_ASSET_UPDATED:
        {
            const { modelPortfolios } = state;
            const index = getIndexFromIdField(modelPortfolios, action.modelPortfolioId);
            const modelPortfolio = modelPortfolios[index];
            const updatedModelPortfolio = {
                ...modelPortfolio,
                assets: [
                    ...modelPortfolio.assets.slice(0, action.assetIndex),
                    action.updatedAsset,
                    ...modelPortfolio.assets.slice(action.assetIndex + 1),
                ],
            };

            return {
                ...state,
                modelPortfolios: [
                    ...modelPortfolios.slice(0, index),
                    updatedModelPortfolio,
                    ...modelPortfolios.slice(index + 1),
                ],
            };
        }
        case types.PARTNER_DATA_MODEL_PORTFOLIO_ASSET_FIELD_UPDATED:
        {
            const { modelPortfolios } = state;
            const index = getIndexFromIdField(modelPortfolios, action.modelPortfolioId);
            const modelPortfolio = modelPortfolios[index];
            const updatedModelPortfolio = {
                ...modelPortfolio,
                assets: [
                    ...modelPortfolio.assets.slice(0, action.assetIndex),
                    { ...modelPortfolio.assets[action.assetIndex], [action.name]: action.value },
                    ...modelPortfolio.assets.slice(action.assetIndex + 1),
                ],
            };

            return {
                ...state,
                modelPortfolios: [
                    ...modelPortfolios.slice(0, index),
                    updatedModelPortfolio,
                    ...modelPortfolios.slice(index + 1),
                ],
            };
        }
        case types.PARTNER_DATA_MIFID_QUESTIONNAIRE_UPDATED:
        {
            const { mifid } = state;
            const index = getIndexFromIdField(mifid.questionnaires, action.questionnaireId);
            const updatedQuestionnaire = {
                ...mifid.questionnaires[index],
                questionnaire: action.questionnaire,
            };
            return {
                ...state,
                mifid: {
                    ...mifid,
                    questionnaires: [
                        ...mifid.questionnaires.slice(0, index),
                        updatedQuestionnaire,
                        ...mifid.questionnaires.slice(index + 1),
                    ],
                },
            };
        }
        default:
            return state;
    }
}
