import React from 'react';
import PropTypes from 'prop-types';

import DefaultPageTemplate from './DefaultPageTemplate';
import UvildigraadPageTemplate from './UvildigraadPageTemplate';
import F10PageTemplate from './F10PageTemplate';

const templates = {
    uvildigraad: UvildigraadPageTemplate,
    f10: F10PageTemplate,
};

function PageTemplate(props) {
    if(!props.state || !props.state.partnerData) return <DefaultPageTemplate>{ props.children }</DefaultPageTemplate>;

    const Template = templates[props.state.partnerData.partnerId] || DefaultPageTemplate;
    return <Template { ...props } />;
}

PageTemplate.propTypes = {
    state: PropTypes.any,
    children: PropTypes.node.isRequired,
};

PageTemplate.defaultProps = {
    state: false,
};

export default PageTemplate;
