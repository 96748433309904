import initialState from './initialState';
import * as types from '../actions/actionTypes';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function wealthDataReducer(state = initialState.wealthData, action) {
    switch (action.type) {
        case types.USER_RESET_STATE: {
            return { ...initialState.wealthData, isFetching: true };
        }
        case types.WEALTH_DATA_LOADED:
            return { ...initialState.wealthData, ...action.wealthData, isFetching: false };
        case types.WEALTH_DATA_UPDATED:
            return { ...state, ...action.wealthData, isFetching: false };
        default:
            return state;
    }
}
