export const address = 'address';
export const cpr = 'cpr';
export const email = 'email';
export const pdfImage = 'pdfImage';
export const kreditDataPension = 'kreditDataPension';
export const info = 'info';
export const name = 'name';
export const phone = 'phone';
export const text = 'text';
export const children = 'children';
