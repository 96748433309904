import initialState from './initialState';
import * as types from '../actions/actionTypes';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function processDataReducer(state = initialState.processData, action) {
    switch (action.type) {
        case types.USER_RESET_STATE: {
            return { ...initialState.processData, isFetching: true };
        }
        case types.PROCESS_DATA_LOADED: {
            return { ...initialState.processData, ...action.processData, isFetching: false };
        }
        case types.PROCESS_DATA_UPDATED: {
            return { ...state, ...action.processData, isFetching: false };
        }
        default:
            return state;
    }
}
