import { PensionCheckSuggestionEnum, PensionCheckSuggestionPriorityEnum, PensionCheckSuggestionTypeEnum, DissavingStrategyEnum } from './pensionCheckEnums';
import { renderKr } from './renderingService';

const getDissavingText = dissavingResult => {
    const standardText =
        'Vi har regnet på om du kan blive modregnet mindre i offentlige ydelser ved at ændre på udbetalingen af din pension. Vi har ikke kunnet finde nogle forbedringer, men det kan alligevel være en god ide at du laver en udbetalingsplan sammen med en rådgiver.';
    if (dissavingResult.totalGain < 10000) {
        return standardText;
    }
    if (dissavingResult.strategy === DissavingStrategyEnum.Spread) {
        return `Vi har beregnet at du kan opnå en gevinst på ca. ${renderKr(
            dissavingResult.totalGain,
        )} hvis du vælger at udbetale din pension over flere år, da du så vil blive modregnet mindre i ydelser fra det offentlige. Vores beregning tager dog ikke højde for andre indkomster end din pension eller dine personlige forhold så vi anbefaler at du laver en udbetalingsplan sammen med en rådgiver, for at afgøre om det er en mulighed.`;
    }
    if (dissavingResult.strategy === DissavingStrategyEnum.Concentrate) {
        return `Vi har beregnet at du kan opnå en gevinst på ca. ${renderKr(
            dissavingResult.totalGain,
        )} hvis du vælger at udbetale din ratepension over færre år, da du så vil blive modregnet mindre i ydelser fra det offentlige. Vores beregning tager dog ikke højde for andre indkomster end din pension eller dine personlige forhold så vi anbefaler at du laver en udbetalingsplan sammen med en rådgiver, for at afgøre om det er en mulighed.`;
    }
    return standardText;
};
const pensionCheckSuggestionValues = [
    {
        id: PensionCheckSuggestionEnum.ConsolidatePensions,
        type: PensionCheckSuggestionTypeEnum.Issue,
        priority: PensionCheckSuggestionPriorityEnum.High,
        header: 'Du har en klatpension',
        summary: 'En klatpension er en lille ordning, som du ikke aktivt indbetaler til. Du risikerer, at den bliver spist af omkostninger, hvis ikke du gør noget.',
        headerMultiple: 'Du har klatpensioner',
        summaryMultiple: 'En klatpension er en lille ordning, som du ikke aktivt indbetaler til. Du risikerer, at de bliver spist af omkostninger, hvis ikke du gør noget.',
        actionTitle: 'Red din klatpension',
        actionText:
            'Tal med en rådgiver om der er særlige garantier og rettigheder, der knytter sig til din klatpension, som du har gavn af at beholde. Alternativt kan du vælge at flytte din klatpension til din aktive pensionsordning eller til en investeringsplatform, hvor omkostningerne er lavere.',
    },
    {
        id: PensionCheckSuggestionEnum.PassivePensions,
        type: PensionCheckSuggestionTypeEnum.Issue,
        priority: PensionCheckSuggestionPriorityEnum.High,
        header: 'Du har en hvilende ordning',
        headerMultiple: 'Du har hvilende ordninger',
        summary: 'En hvilende ordning, er en ordning du ikke aktivt indbetaler til. Du risikerer, at den bliver spist af omkostninger, hvis ikke du gør noget.',
        summaryMultiple: 'En hvilende ordning, er en ordning du ikke aktivt indbetaler til. Du risikerer, at de bliver spist af omkostninger, hvis ikke du gør noget.',
        actionTitle: 'Genopliv din hvilende ordning',
        actionText:
            'Tal med en rådgiver om der er særlige garantier og rettigheder, der knytter sig til din hvilende ordning, som du har gavn af at beholde. Alternativt kan du vælge at flytte din hvilende ordning til din aktive pensionsordning eller til en investeringsplatform, hvor omkostningerne er lavere.',
        actionMoreInfoLinkText: 'Vær dog opmærksom på evt. 60-års ret - læs mere her',
        actionMoreInfoHtml: `<p>
                            Hvis din pensionsordning, er oprettet før 1. maj 2007, kan du have ret til at få dine penge udbetalt, allerede når du fylder 60 år,
                            også selv om din pensionsudbetalingsalder er højere ifølge reglerne i dag. For ordninger oprettet efter 1. maj 2007 gælder de normale regler
                            for pensionsudbetalingsalder.
                        </p>
                        <p>
                            Det betyder, at hvis du skulle ønske, at gå helt eller delvist på pension allerede som 60-årig, kan det lade sig gøre,
                            hvis du har 60-års ret på din pension. Hvis du vælger at lægge flere pensionsordninger sammen, er det vigtigt, at du
                            sikrer, at du beholder en eventuel 60-års ret. Det kan potentielt betyde, at du kan gå flere år tidligere på pension
                            end ellers.
                        </p>`,
    },
    {
        id: PensionCheckSuggestionEnum.MultiplePensionPlanAtSameCompany,
        type: PensionCheckSuggestionTypeEnum.Issue,
        priority: PensionCheckSuggestionPriorityEnum.Middle,
        header: 'Du betaler dobbelt op på omkostninger',
        summary: 'Du har flere pensionsopsparinger hos samme selskab. Det betyder dobbelt administrationsgebyr.',
        actionTitle: 'Undgå at betale dobbelte omkostninger',
        actionText: 'Du bør undersøge om dine pensioner kan slås sammen, så du undgår at betale dobbelt.Tag fat i din rådgiver for at få hjælp til det.',
        actionMoreInfoLinkText: 'Vær dog opmærksom på evt. 60-års ret - læs mere her',
        actionMoreInfoHtml: `<p>
                            Hvis din pensionsordning, er oprettet før 1. maj 2007, kan du have ret til at få dine penge udbetalt, allerede når du fylder 60 år,
                            også selv om din pensionsudbetalingsalder er højere ifølge reglerne i dag. For ordninger oprettet efter 1. maj 2007 gælder de normale regler
                            for pensionsudbetalingsalder.
                        </p>
                        <p>
                            Det betyder, at hvis du skulle ønske, at gå helt eller delvist på pension allerede som 60-årig, kan det lade sig gøre,
                            hvis du har 60-års ret på din pension. Hvis du vælger at lægge flere pensionsordninger sammen, er det vigtigt, at du
                            sikrer, at du beholder en eventuel 60-års ret. Det kan potentielt betyde, at du kan gå flere år tidligere på pension
                            end ellers.
                        </p>`,
    },
    {
        id: PensionCheckSuggestionEnum.MultipleActivePensionPlan,
        type: PensionCheckSuggestionTypeEnum.Issue,
        priority: PensionCheckSuggestionPriorityEnum.Low,
        header: 'Dit afkast er måske for lavt',
        summary:
            'Du har flere aktive pensionsordninger. Det kan betyde, at din risiko og dit afkast ikke er tilpasset til din situation, og du måske betaler for meget i administrationsomkostninger. Der er derfor mulighed for, at du kan få et bedre afkast.',
        actionTitle: 'Sammenlæg for bedre afkast',
        actionText:
            'Det kan være en fordel at slå dine pensionsopsparinger sammen, så du har den rette sammenhæng mellem risiko og afkast - og du kan også spare omkostninger. Tag fat i din rådgiver for at få hjælp til det.',
        actionMoreInfoLinkText: 'Vær dog opmærksom på evt. 60-års ret - læs mere her',
        actionMoreInfoHtml: `<p>
                            Hvis din pensionsordning, er oprettet før 1. maj 2007, kan du have ret til at få dine penge udbetalt, allerede når du fylder 60 år,
                            også selv om din pensionsudbetalingsalder er højere ifølge reglerne i dag. For ordninger oprettet efter 1. maj 2007 gælder de normale regler
                            for pensionsudbetalingsalder.
                        </p>
                        <p>
                            Det betyder, at hvis du skulle ønske, at gå helt eller delvist på pension allerede som 60-årig, kan det lade sig gøre,
                            hvis du har 60-års ret på din pension. Hvis du vælger at lægge flere pensionsordninger sammen, er det vigtigt, at du
                            sikrer, at du beholder en eventuel 60-års ret. Det kan potentielt betyde, at du kan gå flere år tidligere på pension
                            end ellers.
                        </p>`,
    },
    {
        id: PensionCheckSuggestionEnum.MissingData,
        priority: PensionCheckSuggestionPriorityEnum.Low,
        type: PensionCheckSuggestionTypeEnum.Data,
        header: 'Hov - der manger data. Pensionsinfo har ikke kunne hente tallene',
        summary: 'Nogle gange kan Pensionsinfo ikke hente tallene – det er desværre sket i dit tilfælde. ',
        actionTitle: 'Kom igen med en ny rapport',
        actionText:
            'Hent en ny rapport på pensionsInfo.dk om en uges tid, så burde PensionsInfo kunne levere dine tal. Vi glæder os til at analysere din pension og præsentere dig for dine muligheder.',
    },
    {
        id: PensionCheckSuggestionEnum.RightToEarlyPayments,
        priority: PensionCheckSuggestionPriorityEnum.Low,
        type: PensionCheckSuggestionTypeEnum.IssueCalculated,
        header: 'Hurra! Du kan få din pension udbetalt tidligt',
        summary: 'Du har ret til tidlig udbetaling og kan starte udbetaling allerede fra du fylder 60 år. Det er ikke alle der kan det, så glæd dig over, at have en fleksibel løsning.',
        actionTitle: 'Pas på din 60 årsret',
        actionText: 'Hvis du vælger at flytte denne ordning eller lægger den sammen med en anden ordning skal du være ekstra opmærksom på, ikke at miste denne ret.',
        actionMoreInfoLinkText: 'Læs mere om 60-års retten nedenfor.',
        actionMoreInfoHtml: `<p>
                            Hvis din pensionsordning, er oprettet før 1. maj 2007, kan du have ret til at få dine penge udbetalt, allerede når du fylder 60 år,
                            også selv om din pensionsudbetalingsalder er højere ifølge reglerne i dag. For ordninger oprettet efter 1. maj 2007 gælder de normale regler
                            for pensionsudbetalingsalder.
                        </p>
                        <p>
                            Det betyder, at hvis du skulle ønske, at gå helt eller delvist på pension allerede som 60-årig, kan det lade sig gøre,
                            hvis du har 60-års ret på din pension. Hvis du vælger at lægge flere pensionsordninger sammen, er det vigtigt, at du
                            sikrer, at du beholder en eventuel 60-års ret. Det kan potentielt betyde, at du kan gå flere år tidligere på pension
                            end ellers.
                        </p>`,
    },
    {
        id: PensionCheckSuggestionEnum.PublicServiceGain,
        priority: PensionCheckSuggestionPriorityEnum.High,
        type: PensionCheckSuggestionTypeEnum.IssueCalculated,
        header: 'Stræk din pensionsudbetalinger længere og få flere penge',
        summary: 'Der gemmer sig muligvis et større potentiale i din pensionsordning. Du ligger på grænsen til helt eller delvist at gå glip af folkepensionstillægget.',
        actionTitle: 'Få din pension til at strække længere',
        actionText:
            'Det kan muligvis betale sig at få udbetalt din pension over flere år, så du undgår at blive modregnet i offentlige ydelser. Lav evt. en udbetalingsplan sammen med rådgiver, for at finde ud af, om din pension kan strækkes længere.',
    },
    {
        id: PensionCheckSuggestionEnum.TopTaxPensionGain,
        priority: PensionCheckSuggestionPriorityEnum.High,
        type: PensionCheckSuggestionTypeEnum.IssueCalculated,
        header: 'Undgå at betale topskat',
        actionTitle: 'Optimer dine pensionsudbetalinger',
        summary:
            'Vi har beregnet at du kommer til at betale topskat af dine pensionsudbetalinger hvis du ikke gør noget inden du går på pension. Det kan du muligvis undgå ved at lave en udbetalingsplan sammen med en rådgiver.',
        actionText:
            'Justér tidspunkt og længde for dine pensionsudbetalinger så du får mest muligt udbetalt efter skat og modregninger i offentlige ydelser. Lav evt. en udbetalingsplan sammen med en rådgiver, for at få mest til din pension, og generelt for at få bedre overblik over din økonomi som pensioneret.',
    },
    {
        id: PensionCheckSuggestionEnum.TopTaxIncome,
        priority: PensionCheckSuggestionPriorityEnum.High,
        type: PensionCheckSuggestionTypeEnum.IssueCalculated,
        header: 'Undgå at betale topskat',
        actionTitle: 'Øg din pensionsindbetaling',
        summary:
            'Vi har beregnet, at du sandsynligvis betaler topskat af din lønindkomst. Vi medregner dog ikke skatteloftet og alle fradrag, så skatten kan godt være lavere end vist. Hvis du øger din pensionsindbetaling med et beløb tilsvarende beregningsgrundlaget for topskat, vil du kunne spare topskatten væk. Dette gælder dog kun så længe, du ikke også skal betale topskat af dine pensionsudbetalinger.',
        actionText:
            'Indbetal mere til din ratepension eller livrente så du opnår et skattemæssigt fradrag. Er du usikker på, hvor meget du skal indbetale, kan du tage en snak med din rådgiver om at lave et ekstra indskud til din pensionsopsparing i slutningen af året.',
    },
    {
        id: PensionCheckSuggestionEnum.HighLifePayment,
        priority: PensionCheckSuggestionPriorityEnum.Low,
        type: PensionCheckSuggestionTypeEnum.IssueCalculated,
        header: 'Undgå modregning i dit pensionstillæg',
        actionTitle: 'Læg en plan i god tid',
        summary:
            'Det vil muligvis ikke kunne svare sig at spare så meget op på livrente, som du gør nu. Du kan blive modregnet i pensionstillæget med 30% af beløbet der overstiger 89.700 kr. om året som enlig og 179.700 kr. som par.',
        actionText:
            'Overvej at indbetale mindre til livrente, hvis din pensionsordning tillader det. Du kan alternativt spare op og evt. investerere i aktier eller investeringforeninger. Du kan også undgå modregning ved at gå tidligere på pension, hvis din økonomi tillader det. Tal med din rådgiver om dine muligheder.',
    },
    {
        id: PensionCheckSuggestionEnum.LowPaymentComparedToSalary,
        priority: PensionCheckSuggestionPriorityEnum.High,
        type: PensionCheckSuggestionTypeEnum.IssueCalculated,
        header: 'Du sparer muligvis for lidt op',
        summary: 'Noget tyder på, at du bør spare mere op. Alternativt skal du forberede dig på, at sætte dit forbrug ned, når du går på pension.',
        actionTitle: 'Hvordan vil du leve dit liv som pensionist?',
        actionText:
            'Gør op med dig selv, om det er okay, at skulle leve for væsentligt mindre, når du bliver pensionist. Læg en plan for, hvordan din pensionisttilværelse skal se ud, og se på om det hænger sammen med dine nuværende indbetalinger.',
    },
    {
        id: PensionCheckSuggestionEnum.LowLifePaymentComparedToSalary,
        priority: PensionCheckSuggestionPriorityEnum.High,
        type: PensionCheckSuggestionTypeEnum.IssueCalculated,
        header: 'Du sparer muligvis for lidt op på livrente',
        summary:
            'Det meste af din pension ligger som aldersopsparing og ratepension. Disse pensioner kommer til udbetaling over en periode, hvorimod livrenten udbetales hele dit liv. En god balance mellem de forskellige typer opsparing kan sikre dig i din pensionisttilværelse.',
        actionTitle: 'Plads til mere opsparing?',
        actionText:
            'Skab et billede af, hvordan din pensionisttilværelse skal se ud for at vurdere om det kræver mere opsparing nu. I så fald kan det være en fordel at betale ind på en livrente. Tal med din rådgiver.',
    },
    {
        id: PensionCheckSuggestionEnum.MissingLifeInsurance,
        priority: PensionCheckSuggestionPriorityEnum.High,
        type: PensionCheckSuggestionTypeEnum.IssueCalculated,
        header: 'Ingen dækning ved dødsfald',
        summary: 'Forsikringen hjælper dine nærmeste til at stå stærkt økonomisk, hvis du falder bort. Det kan eksempelvis betyde, at din familie kan blive boende i huset, eller sikre at dine børn får en økonomisk tryg opvækst.',
        actionTitle: 'Vær tryg hvis uheldet er ude',
        actionText:
            'Overvej at tegne en forsikring igennem dit pensionsselskab efter en snak med din rådgiver.',
    },
    {
        id: PensionCheckSuggestionEnum.MissingAbilityToWorkInsurance,
        priority: PensionCheckSuggestionPriorityEnum.High,
        type: PensionCheckSuggestionTypeEnum.IssueCalculated,
        header: 'Ingen dækning ved tab af erhvervsevne',
        summary: 'Forsikringen kan sikre dig økonomisk, og hjælpe dig tilbage på rette spor, hvis du bliver ramt af så alvorlig sygdom eller ulykke, at du ikke kan arbejde. Det kan eksempelvis skyldes stress, problemer med ryggen eller livstruende sygdom.',
        actionTitle: 'Vær tryg hvis uheldet er ude',
        actionText:
            'Overvej at tegne en forsikring igennem dit pensionsselskab efter en snak med din rådgiver.',
    },
    // {
    //     id: PensionCheckSuggestionEnum.ContactPensionAdvisor,
    //     priority: PensionCheckSuggestionPriorityEnum.Middle,
    //     header: 'Søg uafhængig personlig pensionsrådgivning',
    //     summary: 'Vi foreslår at du kontakter en uvildig rådgiver, din bank eller dit pensionsselskab, så du kan handle på de muligheder dit PensionsTjek har synliggjort.',
    //     type: PensionCheckSuggestionTypeEnum.Default,
    //     actionTitle: '',
    //     actionText: '',
    // },
    // {
    //     id: PensionCheckSuggestionEnum.DoContinuousPensionCheck,
    //     priority: PensionCheckSuggestionPriorityEnum.Middle,
    //     header: 'Løbende PensionsTjek',
    //     summary: 'Vi anbefaler at du laver et PensionsTjek hver tredje måned, så du altid er sikker på at din pension så optimal som den kan være.',
    //     text: 'Det er vigtigt at du følger udviklingen i dine pensioner. Tag derfor løbende et PensionsTjek og upload dine rapporter fra PensionsInfo min. hver tredje måned',
    //     type: PensionCheckSuggestionTypeEnum.Default,
    //     actionTitle: '',
    //     actionText: '',
    // },
];

export const getPensionDetails = provider => {
    let details = '';

    if (provider?.aftaleType === 'Privat') {
        details += 'Privat pension, ';
    } else if (provider?.aftaleType === 'ViaArbejde') {
        details += 'Arbejdsgiverordning, ';
    }

    details += renderKr(provider.opsparing);
    return details;
};
export const getValue = ({ value, type }) => {
    if (type === 'Money') {
        return renderKr(Number(value));
    }
    return value;
};
export const getSuggestionHeader = suggestion => {
    if (suggestion.providers && Array.isArray(suggestion.providers) && suggestion.providers.length > 1 && suggestion.headerMultiple && suggestion.headerMultiple !== '') {
        return suggestion.headerMultiple;
    }

    return suggestion.header;
};

export const getSuggestionSummary = suggestion => {
    if (suggestion.providers && Array.isArray(suggestion.providers) && suggestion.providers.length > 1 && suggestion.summaryMultiple && suggestion.summaryMultiple !== '') {
        return suggestion.summaryMultiple;
    }

    return suggestion.summary;
};

export const initializePensionCheckResult = (suggestionsRaw, error, calculated = true, isCalculating = false) => {
    let suggestions = [];
    let hasKlatpension = false;
    if (suggestionsRaw) {
        suggestionsRaw.forEach(suggestionRaw => {
            const pensionCheckSuggestion = pensionCheckSuggestionValues.find(s => s.id === PensionCheckSuggestionEnum[suggestionRaw.type]);
            const suggestion = { ...pensionCheckSuggestion, providers: suggestionRaw.instances, values: suggestionRaw.values };
            if (suggestion.id === PensionCheckSuggestionEnum.PublicServiceGain) {
                suggestion.dissavingResult = suggestionRaw.dissavingResult;
                const text = getDissavingText(suggestionRaw.dissavingResult);
                suggestion.summary = text;
            } else if (suggestion.id === PensionCheckSuggestionEnum.ConsolidatePensions || suggestion.id === PensionCheckSuggestionEnum.PassivePensions) {
                hasKlatpension = true;
            }

            suggestions.push(suggestion);
        });
    }

    // Sort by priority suggestions
    suggestions = suggestions.sort((a, b) => {
        if (a.priority < b.priority) return -1;
        return 0;
    });

    const result = {
        hasError: !!error,
        hasPensionCheck: !error && calculated && !isCalculating && suggestions,
        hasSuggestions: calculated && !isCalculating && suggestions && suggestions?.length > 0,
        hasKlatpension,
        isLoading: isCalculating,
        suggestions,
    };

    return result;
};
