import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Menu, MenuItem, Button, CircularProgress } from '@mui/material';
import { downloadReport } from '../../actions/investActions';

export default function ReportButton() {
    const { customerId } = useParams();
    const dispatch = useDispatch();
    const onDownloadReport = useCallback(variation => dispatch(downloadReport(customerId, variation)), [dispatch, customerId]);
    const { reportLoading } = useSelector(state => state.investData);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = [
        { text: 'Tilbud', action: () => onDownloadReport('offer') },
        { text: 'Analyse', action: () => onDownloadReport('analysis') },
    ];

    return(
        <div>
            <Button onClick={ handleClick } style={{ width: '141.17px' }}>
                { reportLoading ? <CircularProgress size={ 20 } color="secondary" /> : 'Download rapport' }
            </Button>
            <Menu
                PaperProps={{
                    style: {
                        width: '141.17px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorReference= 'anchorEl'
                anchorEl={ anchorEl }
                open={ Boolean(anchorEl) }
                onClose={ handleClose }
            >
                { options.map((option, index) => {
                    return <MenuItem key={ index } onClick={ () => { option.action(); handleClose(); } }>{ option.text }</MenuItem>;
                }) }
            </Menu>
        </div>
    );
};
