import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function ErrorIcon({ style, listItemIcon, toolTip }) {
    let errorIcon = <ErrorOutlineIcon style={{ color: 'red', fontSize: 'default', verticalAlign:'middle', ...style }}/>;

    if(toolTip) errorIcon = <Tooltip title={ toolTip }>{ errorIcon }</Tooltip>;
    if(listItemIcon) errorIcon = <ListItemIcon>{ errorIcon }</ListItemIcon>;

    return errorIcon;
}

ErrorIcon.propTypes = {
    style: PropTypes.object,
    listItemIcon: PropTypes.bool,
    toolTip: PropTypes.string,
};

ErrorIcon.defaultProps = {
    listItemIcon: false,
    toolTip: null,
};
