import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import * as types from './actionTypes';
import {
    getAllCustomers,
    updateCustomer,
    getDownloadFileUrl,
    setFileMetaData,
    getCustomer as getCustomerFromFirebase,
    uploadFile as uploadFileToCustomer,
    uploadFileOnboardingLink as uploadFileOnboardingLinkToCustomer,
    uploadPensionFileOnboardingLink as uploadPensionFileOnboardingLinkToCustomer,
    deleteFile as deleteFileOnCustomer,
    deleteFileOnboardingLink as deleteFileOnboardingLinkOnCustomer,
} from '../databaseRepository/customerDataManager';
import {
    getUsersFileBinary,
    deleteScrape as deleteScrapeApi,
    createScrape as createScrapeApi,
} from '../apiRepository/teal';

const sleep = (ms) => new Promise(res => setTimeout(res, ms));
const atLeast = async (ms, promise) => {
    const result = await Promise.all([sleep(ms), promise]);
    return result[1];
};

export function getAllCustomersData() {
    return async (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: types.CUSTOMER_DATA_UPDATE_REQUEST });
        const customers = await getAllCustomers(auth.user.partnerId);
        dispatch({ type: types.CUSTOMER_ALL_DATA_UPDATED, customers });
    };
}

export function getCustomer(customerId) {
    return async (dispatch, getState) => {
        const { partnerId } = getState().auth.user;
        const customerData = await getCustomerFromFirebase(partnerId, customerId);
        dispatch({ type: types.CUSTOMER_DATA_UPDATED, customerData, customerId });
        return customerData;
    };
}

export function updateCustomerData(customerData) {
    return async (dispatch) => {
        await updateCustomer(customerData);
        dispatch({ type: types.CUSTOMER_DATA_UPDATED, customerData });
    };
}

export function createCustomer(customer) {
    return async (dispatch, getState) => {
        const { auth } = getState();

        const customerId = uuidv4();
        const now = new Date();
        const newCustomer = {
            advisorId: auth.user.advisorId,
            partnerId: auth.user.partnerId,
            name: customer.name || `${customer.firstName  } ${  customer.lastName}`,
            deleted: false,
            createdAt: now,
            customerId,
            ...customer,
        };

        dispatch({ type: types.CUSTOMER_DATA_ADDED, customerData: newCustomer });

        await updateCustomer(newCustomer);

        return newCustomer;
    };
}

export function uploadFile(customerId, file, options = { }, spouse = false) {
    return async (dispatch, getState) => {
        const state = getState();
        const { partnerId } = state.auth.user;
        const customer = await getCustomerFromFirebase(partnerId, customerId);

        dispatch({ type: types.CUSTOMER_FILE_UPLOAD_REQUEST, payload: { customerId } });
        const fileData = await atLeast(800, uploadFileToCustomer(customer, file, { }, spouse));
        const { fileId } = fileData;

        dispatch(getCustomer(customerId));
        if (options.scrape) {
            await dispatch(scrapePiFile(customerId, fileId));
        }

        return fileData;
    };
}

export function uploadFileOnboardingLink(customerId, file, spouse = false) {
    return async (dispatch, getState) => {
        const state = getState();
        const { partnerId } = state.auth.user;
        const fileData = await atLeast(800, uploadFileOnboardingLinkToCustomer(customerId, partnerId, file, { }, spouse));
        return fileData;
    };
}

export function uploadPensionFileOnboardingLink(customerId, file, fileName, spouse = false) {
    return async (dispatch, getState) => {
        const state = getState();
        const { partnerId } = state.auth.user;
        const fileData = await atLeast(800, uploadPensionFileOnboardingLinkToCustomer(customerId, partnerId, file, fileName, { }, spouse));
        return fileData;
    };
}

export function scrapePiFile(customerId, fileId) {
    return async (dispatch, getState) => {
        const state = getState();
        const { partnerId } = state.auth.user;
        const customer = await getCustomerFromFirebase(partnerId, customerId);

        dispatch({ type: types.CUSTOMER_FILE_SCRAPE_REQUEST, payload: { customerId, fileId } });

        const fileUrl = await getDownloadFileUrl(customer, fileId);

        const { data, headers } = await axios({
            url: fileUrl,
            method: 'GET',
            responseType: 'blob',
        });
        const file = new Blob([data], { type: headers['content-type'] });

        const { scrapeId } = await createScrapeApi({ pdf: file });

        await setFileMetaData(customer, fileId, { scrapeId, scraping: true });

        await dispatch({
            type: 'SCRAPES_REQUEST_GET',
            payload: { customerId, fileId, scrapeId, polling: true },
        });

        return scrapeId;
    };
}

export function deleteFile(customerId, fileId) {
    return async (dispatch, getState) => {
        const { partnerId } = getState().auth.user;
        const customer = await getCustomerFromFirebase(partnerId, customerId);
        dispatch({ type: types.CUSTOMER_FILE_DELETE_REQUEST, payload: { customerId, fileId } });
        await atLeast(500, deleteFileOnCustomer(customer, fileId));
        dispatch(getCustomer(customerId));
    };
}

export function deleteFileOnboardingLink(customerId, fileId) {
    return async (dispatch, getState) => {
        const { partnerId } = getState().auth.user;
        await atLeast(500, deleteFileOnboardingLinkOnCustomer(customerId, partnerId, fileId));
    };
}

export function deleteScrape(scrapeId, customerId) {
    return async (dispatch) => {
        await dispatch({
            type: 'SCRAPES_REQUEST_DELETE',
            payload: { scrapeId, customerId },
        });
        await deleteScrapeApi(scrapeId);
    };
}
// returns a direct url to the file or a data url with base64 encoding
export function getFileUrl(file) {
    return async () => {
        if (file.source === 'Upload') return file.url;
        if (file.source === 'Neway') {
            const binaryFile = await getUsersFileBinary(file.userId, file.fileId);
            return new Promise((res) => {
                const reader = new FileReader();
                reader.readAsDataURL(binaryFile);
                reader.onloadend = () => res(reader.result);
            });
        }
    };
};
