const drawerWidth = 184;

export default function getOverrides(theme) {
    return {
        MuiAlert: {
            defaultProps: {
                closeText: 'Luk',
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                clearText: 'Ryd',
                closeText: 'Luk',
                loadingText: 'Loader...',
                noOptionsText: 'Ingen muligheder',
                openText: 'Åben',
            },
        },
        // Style sheet name ⚛️
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides:{
            // Name of the rule
                contained: {
                    height: '32px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px',
                    textTransform: 'unset',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontSize: '13px',
                    lineHeight: '16px',
                },
            },
        },
        MuiAppBar: {
            styleOverrides:{
                root: {
                    zIndex: 100,
                    height: 60,
                },
            },
        },
        MuiPaper: {
            styleOverrides:{
                elevation4: {
                    boxShadow: 'none',
                },
            },
        },
        MuiDrawer: {
            styleOverrides:{
                root: {
                    zIndex: 99,
                    flexShrink: 0,
                },
                paper: {
                    width: drawerWidth,
                    backgroundColor: theme.colors.sidebarBackground,
                },
                paperAnchorDockedLeft: {
                    borderRight: '1px solid #eaeaea',
                },
            },
        },
        MuiListItem: {
            styleOverrides:{
                root: {
                    paddingTop: 4,
                    paddingBottom: 4,
                },
                gutters: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides:{
                root: {
                    minWidth: '32px',
                    color: '#ABABAB',
                },
            },
        },
        MuiListItemText: {
            styleOverrides:{
                root: {
                    fontSize: '14px',
                },
            },
        },
        MuiTable: {
            styleOverrides:{
                root: {
                    padding: 30,
                },
            },
        },
        MuiRating: {
            defaultProps: {
                getLabelText: (value) => `${value} ${value !== 1 ? 'Stjerner' : 'Stjerne'}`,
            },
        },
        MuiTableRow: {
            styleOverrides:{
                root: {
                    '&:nth-of-type(even)': {
                        backgroundColor: '#F4F6F8',
                    },
                    '&:first-of-type': {
                        paddingTop: 30,
                    },
                },
            },
        },
        MuiTablePagination: {
            defaultProps: {
                SelectProps: {
                    inputProps: { 'aria-label': 'rækker per side' },
                },
                labelRowsPerPage: 'Rækker per side:',
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to === -1 ? count : to} af ${count}`,
                getItemAriaLabel: (type) => {
                    if(type === 'first') return 'Første side';
                    if(type === 'last') return 'Sidste side';
                    if(type === 'next') return 'Næste side';
                    if(type === 'previous') return 'Forrige side';
                    return '';
                },
            },
            styleOverrides:{
                toolbar: {
                    paddingRight: 12,
                },
            },
        },
        MuiTableCell: {
            styleOverrides:{
                head: {
                    color: theme.colors.textBlack,
                    fontWeight: '600',
                    fontSize: '15px',
                },
                root: {
                    '&:first-of-type': {
                        paddingLeft: 30,
                    },
                    '&:last-child': {
                        paddingRight: 30,
                    },
                },
            },
        },
    };
}
