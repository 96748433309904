export const budget = 'budget';
export const intro = 'intro';
export const info = 'info';
export const pension = 'pension';
export const invest = 'invest';
export const house = 'house';
export const account = 'account';
export const salary = 'salary';
export const taxFolder = 'taxfolder';
export const comment = 'comment';
export const other = 'other';
export const welcomeMessage = 'welcomeMessage';
