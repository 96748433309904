import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { renderKr } from '../../../utils/renderingService';

const UdbetalingerTable = ({ udbetalingsRaekker, perioder }) => {
    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Leverandør</TableCell>
                    <TableCell align="right">Aftalenr.</TableCell>
                    <TableCell align="right">Bemærkninger</TableCell>
                    { perioder.map((p) => (
                        <TableCell align="right" key={ p.startAlder + p.slutAlder }>
                            { `${p.startAlder + (p.slutAlder !== 0 && `-${  p.slutAlder}`)  } år` }
                        </TableCell>
                    )) }
                </TableRow>
            </TableHead>
            <TableBody>
                { udbetalingsRaekker.map((row) => (
                    <TableRow key={ row.aftalenr + row.leverandoer + row.bemaerkninger }>
                        <TableCell component="th" scope="row">
                            <strong>
                                { row.manglerOplysninger
                                    ? 'Mangler oplysninger'
                                    : row.leverandoer }
                            </strong>
                        </TableCell>
                        <TableCell align="right">
                            <div className="tablePrimary">{ row.aftaleNr } </div>{ ' ' }
                            <div className="tableSecondary">{ row.aftaleTypeText }</div>{ ' ' }
                        </TableCell>
                        <TableCell align="right" style={{ maxWidth: 100 }}>
                            { row.bemaerkninger }
                        </TableCell>
                        { row.udbetalinger.map((u) => (
                            <TableCell align="right" key={ u.startAlder + u.slutAlder }>
                                { renderKr(u.beloeb) }
                            </TableCell>
                        )) }
                    </TableRow>
                )) }
                <TableRow>
                    <TableCell component="th" scope="row">
                        Sum
                    </TableCell>
                    <TableCell
                        align="right"
                        style={{ whiteSpace: 'pre-line' }}
                    ></TableCell>
                    <TableCell align="right"></TableCell>
                    { perioder.map((u, i) => (
                        <TableCell align="right" key={ u.startAlder + u.slutAlder }>
                            <strong>
                                { renderKr(
                                    udbetalingsRaekker
                                        .map((x) => x.udbetalinger[i].beloeb || 0)
                                        .reduce((sum, x) => sum + x),
                                ) }
                            </strong>
                        </TableCell>
                    )) }
                </TableRow>
            </TableBody>
        </Table>
    );
};
UdbetalingerTable.propTypes = {
    udbetalingsRaekker: PropTypes.array,
    perioder: PropTypes.array,
};
export default UdbetalingerTable;
