import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function adviceModuleDataReducer(state = initialState.adviceModuleData, action) {
    switch (action.type) {
        case types.ADVICE_MODULE_DATA_UPDATED:
            return { ...state, ...action.adviceModuleData };
        default:
            return state;
    }
}
