import React from 'react';
import PropTypes from 'prop-types';

export default function DefaultPortraitPageTemplate({ children }) {
    return (
        <div className="page A4-portrait" style={{ display: 'flex', flexDirection: 'column', padding: '0px 30px' }}>
            <div style={{ flexGrow: 1 }}>{ children }</div>
        </div>
    );
}

DefaultPortraitPageTemplate.propTypes = {
    children: PropTypes.node.isRequired,
};

DefaultPortraitPageTemplate.defaultProps = {
};
