import { v4 as uuidv4 } from 'uuid';
import * as types from './actionTypes';
import { getSingleOnboardingProcess, getAllOnboadringProccess, updateOnboardingProcess as updateOnboardingProcessDB } from '../databaseRepository/onboardingDataManager';
import { generateAndAddMagicLinkToken, deleteMagicLinkToken } from '../databaseRepository/magicLinkDataManager';
import { copyAnswers } from '../reducers/defaultOnboardingQuestionnaires';

export function getOnboardingProcesses(customerId) {
    return async (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: types.ONBOARDING_PROCESSES_LOAD_REQUEST });
        const processes = await getAllOnboadringProccess(auth.user.partnerId, customerId);
        dispatch({ type: types.ONBOARDING_PROCESSES_LOADED, processesData: { processes } });
        return Promise.resolve();
    };
}

export function getOnboardingProcess(partnerId, customerId, processId) {
    return async (dispatch) => {
        dispatch({ type: types.ONBOARDING_PROCESSES_LOAD_REQUEST });
        const process = await getSingleOnboardingProcess(partnerId, customerId, processId);
        dispatch({ type: types.ONBOARDING_PROCESSES_LOADED, processesData: { processes: [ process ] } });
        return Promise.resolve();
    };
}

export function createOnboadringProcess(processData) {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const processId = uuidv4();
        const now = new Date();
        const newProcessData = {
            partnerId: auth.user.partnerId,
            advisorId: auth.user.advisorId,
            deleted: false,
            createdAt: now,
            processId,
            ...processData,
        };

        await updateOnboardingProcessDB(newProcessData);
        dispatch({ type: types.ONBOARDING_PROCESSES_ADDED, process: { ...newProcessData } });
        return processId;
    };
}

export function updateOnboardingProcess(partnerId, customerId, processId, onboardingProcess) {
    return async (dispatch) => {
        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId, onboardingProcess });
        await updateOnboardingProcessDB({ ...onboardingProcess, partnerId, customerId, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
        return Promise.resolve();
    };
}

export function updateOnboardingProcessField(processId, fieldName, fieldValue) {
    return async (dispatch, getState) => {
        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName, fieldValue });
        const { onboardingProcessesData } = getState();
        const { processes } = onboardingProcessesData;
        const process = processes.find(x => x.processId === processId);
        await updateOnboardingProcessDB(process);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}

export function generateCustomerLink(processId, customerId) {
    return async (dispatch, getState) => {
        const { auth } = getState();

        const customerLink = await generateAndAddMagicLinkToken(auth.user.partnerId, customerId, processId, 'onboarding-user');

        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName: 'customerLink', fieldValue: customerLink });
        const { onboardingProcessesData } = getState();
        const process = onboardingProcessesData.processes.find(x => x.processId === processId);
        await updateOnboardingProcessDB(process);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}

export function deleteCustomerLink(processId) {
    return async (dispatch, getState) => {
        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        let { onboardingProcessesData } = getState();
        let process = onboardingProcessesData.processes.find(x => x.processId === processId);
        const magickTokenId = process.customerLink.magicToken;
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName: 'customerLink', fieldValue: null });
        onboardingProcessesData = getState().onboardingProcessesData;
        process = onboardingProcessesData.processes.find(x => x.processId === processId);
        await deleteMagicLinkToken(magickTokenId);
        await updateOnboardingProcessDB(process);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}

function initializeAnswer(questionnaire) {
    const localAnswer = {
        id: uuidv4(),
        questionnairId: questionnaire.id,
        customerAnswerGroups : [],
    };

    if(questionnaire.includeSpouse) {
        localAnswer.spouseAnswerGroups = [];
        localAnswer.sharedAnswerGroups = [];
    }

    return localAnswer;
}

export function addOrUpdateAnswer(processId, questionGroupId, questionId, responder, answerData) {
    return async (dispatch, getState) => {
        const { onboardingProcessesData } = getState();
        const onboardingProcess = onboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        const questionnaire = onboardingProcess?.questionnaire;
        const answers = onboardingProcess?.answers;
        const localAnswers = answers ? copyAnswers(answers) : initializeAnswer(questionnaire);

        let localResponder;
        if(responder === 'customer') localResponder = 'customerAnswerGroups';
        if(responder === 'spouse') localResponder = 'spouseAnswerGroups';
        if(responder === 'shared') localResponder = 'sharedAnswerGroups';

        const answerGroupIndex = localAnswers[localResponder].findIndex(answerGroup => answerGroup.id === questionGroupId);
        if (answerGroupIndex === -1) {
            localAnswers[localResponder].push({
                id: questionGroupId,
                customerComment: null,
                answers: [
                    {
                        id: questionId,
                        answerData,
                    },
                ],
            });
        } else {
            const answerIndex = localAnswers[localResponder][answerGroupIndex].answers.findIndex(answer => answer.id === questionId);

            if (answerIndex === -1) {
                localAnswers[localResponder][answerGroupIndex].answers.push({
                    id: questionId,
                    answerData,
                });
            } else {
                localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData = answerData;
            }
        }

        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName: 'answers', fieldValue: localAnswers });
        const updatedOnboardingProcessesData = getState().onboardingProcessesData;
        const updatedOnboardingProcess = updatedOnboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        await updateOnboardingProcessDB(updatedOnboardingProcess);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}

export function addOrUpdateCustomerComment(processId, questionGroupId, responder, comment) {
    return async (dispatch, getState) => {
        const { onboardingProcessesData } = getState();
        const onboardingProcess = onboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        const questionnaire = onboardingProcess?.questionnaire;
        const answers = onboardingProcess?.answers;
        const  localAnswers = answers ? copyAnswers(answers) : initializeAnswer(questionnaire);

        let localResponder;
        if(responder === 'customer') localResponder = 'customerAnswerGroups';
        if(responder === 'spouse') localResponder = 'spouseAnswerGroups';
        if(responder === 'shared') localResponder = 'sharedAnswerGroups';

        const answerGroupIndex = localAnswers[localResponder].findIndex(answerGroup => answerGroup.id === questionGroupId);
        if (answerGroupIndex === -1) {
            localAnswers[localResponder].push({
                id: questionGroupId,
                customerComment: comment,
                answers: [],
            });
        } else {
            localAnswers[localResponder][answerGroupIndex] = {
                ...localAnswers[localResponder][answerGroupIndex],
                customerComment: comment,
            };
        }

        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName: 'answers', fieldValue: localAnswers });
        const updatedOnboardingProcessesData = getState().onboardingProcessesData;
        const updatedOnboardingProcess = updatedOnboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        await updateOnboardingProcessDB(updatedOnboardingProcess);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}

export function addFileToAnswer(processId, responder, questionGroupId, questionId, fileId, fileName) {
    return async (dispatch, getState) => {
        const { onboardingProcessesData } = getState();
        const onboardingProcess = onboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        const questionnaire = onboardingProcess?.questionnaire;
        const answers = onboardingProcess?.answers;
        const  localAnswers = answers ? copyAnswers(answers) : initializeAnswer(questionnaire);
        let localResponder;
        if(responder === 'customer') localResponder = 'customerAnswerGroups';
        if(responder === 'spouse') localResponder = 'spouseAnswerGroups';
        if(responder === 'shared') localResponder = 'sharedAnswerGroups';

        const answerGroupIndex = localAnswers[localResponder].findIndex(answerGroup => answerGroup.id === questionGroupId);
        if (answerGroupIndex === -1) {
            localAnswers[localResponder].push({
                id: questionGroupId,
                customerComment: null,
                answers: [
                    {
                        id: questionId,
                        answerData: {
                            files: [ { fileId, fileName } ],
                        },
                    },
                ],
            });
        } else {
            const answerIndex = localAnswers[localResponder][answerGroupIndex].answers.findIndex(answer => answer.id === questionId);

            if (answerIndex === -1) {
                localAnswers[localResponder][answerGroupIndex].answers.push({
                    id: questionId,
                    answerData: {
                        files: [ { fileId, fileName } ],
                    },
                });
            } else {
                const currentAnswerData = localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData;
                if(!currentAnswerData.files || currentAnswerData.files.length === 0) {
                    localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData = {
                        ...currentAnswerData,
                        files: [ { fileId, fileName } ],
                    };
                } else {
                    const fileIndex = currentAnswerData?.files?.findIndex(file => file.fileId === fileId);
                    if( fileIndex === -1 ) {
                        localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData.files.push({ fileId, fileName });
                    } else {
                        throw new Error('This should never happen, you should not be allowed to update a file!');
                    }
                }
            }
        }

        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName: 'answers', fieldValue: localAnswers });
        const updatedOnboardingProcessesData = getState().onboardingProcessesData;
        const updatedOnboardingProcess = updatedOnboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        await updateOnboardingProcessDB(updatedOnboardingProcess);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}

export function removeFileRef(processId, responder, questionGroupId, questionId, fileId) {
    return async (dispatch, getState) => {
        const { onboardingProcessesData } = getState();
        const onboardingProcess = onboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        const questionnaire = onboardingProcess?.questionnaire;
        const answers = onboardingProcess?.answers;
        const  localAnswers = answers ? copyAnswers(answers) : initializeAnswer(questionnaire);

        let localResponder;
        if(responder === 'customer') localResponder = 'customerAnswerGroups';
        if(responder === 'spouse') localResponder = 'spouseAnswerGroups';
        if(responder === 'shared') localResponder = 'sharedAnswerGroups';

        const answerGroupIndex = localAnswers[localResponder].findIndex(answerGroup => answerGroup.id === questionGroupId);
        const answerIndex = localAnswers[localResponder][answerGroupIndex].answers.findIndex(answer => answer.id === questionId);
        const currentAnswerData = localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData;
        localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData.files = currentAnswerData.files.filter(file => file.fileId !== fileId);
        console.log(localAnswers);

        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName: 'answers', fieldValue: localAnswers });
        const updatedOnboardingProcessesData = getState().onboardingProcessesData;
        const updatedOnboardingProcess = updatedOnboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        await updateOnboardingProcessDB(updatedOnboardingProcess);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}

export function addChildToAnswer(processId, responder, questionGroupId, questionId) {
    return async (dispatch, getState) => {
        const { onboardingProcessesData } = getState();
        const onboardingProcess = onboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        const questionnaire = onboardingProcess?.questionnaire;
        const answers = onboardingProcess?.answers;
        const  localAnswers = answers ? copyAnswers(answers) : initializeAnswer(questionnaire);
        let localResponder;
        if(responder === 'customer') localResponder = 'customerAnswerGroups';
        if(responder === 'spouse') localResponder = 'spouseAnswerGroups';
        if(responder === 'shared') localResponder = 'sharedAnswerGroups';

        const answerGroupIndex = localAnswers[localResponder].findIndex(answerGroup => answerGroup.id === questionGroupId);
        if (answerGroupIndex === -1) {
            localAnswers[localResponder].push({
                id: questionGroupId,
                customerComment: null,
                answers: [
                    {
                        id: questionId,
                        answerData: {
                            children: [ { id: uuidv4() } ],
                        },
                    },
                ],
            });
        } else {
            const answerIndex = localAnswers[localResponder][answerGroupIndex].answers.findIndex(answer => answer.id === questionId);

            if (answerIndex === -1) {
                localAnswers[localResponder][answerGroupIndex].answers.push({
                    id: questionId,
                    answerData: {
                        children: [ { id: uuidv4() } ],
                    },
                });
            } else {
                const currentAnswerData = localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData;

                if(!currentAnswerData.children || currentAnswerData.children?.length === 0) {
                    localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData = {
                        ...currentAnswerData,
                        children: [{ id: uuidv4() }],
                    };
                } else {
                    localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData.children.push({ id: uuidv4() });
                }
            }
        }

        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName: 'answers', fieldValue: localAnswers });
        const updatedOnboardingProcessesData = getState().onboardingProcessesData;
        const updatedOnboardingProcess = updatedOnboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        await updateOnboardingProcessDB(updatedOnboardingProcess);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}

export function updateChildInAnswer(processId, responder, questionGroupId, questionId, childId, fieldName, fieldValue) {
    return async (dispatch, getState) => {
        const { onboardingProcessesData } = getState();
        const onboardingProcess = onboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        const questionnaire = onboardingProcess?.questionnaire;
        const answers = onboardingProcess?.answers;
        const  localAnswers = answers ? copyAnswers(answers) : initializeAnswer(questionnaire);

        let localResponder;
        if(responder === 'customer') localResponder = 'customerAnswerGroups';
        if(responder === 'spouse') localResponder = 'spouseAnswerGroups';
        if(responder === 'shared') localResponder = 'sharedAnswerGroups';

        const answerGroupIndex = localAnswers[localResponder].findIndex(answerGroup => answerGroup.id === questionGroupId);
        const answerIndex = localAnswers[localResponder][answerGroupIndex].answers.findIndex(answer => answer.id === questionId);
        const { children } = localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData;
        const childIndex = children.findIndex(child => child.id === childId);
        children[childIndex] = { ...children[childIndex], [fieldName]: fieldValue };

        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName: 'answers', fieldValue: localAnswers });
        const updatedOnboardingProcessesData = getState().onboardingProcessesData;
        const updatedOnboardingProcess = updatedOnboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        await updateOnboardingProcessDB(updatedOnboardingProcess);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}

export function deleteChildInAnswer(processId, responder, questionGroupId, questionId, childId) {
    return async (dispatch, getState) => {
        const { onboardingProcessesData } = getState();
        const onboardingProcess = onboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        const questionnaire = onboardingProcess?.questionnaire;
        const answers = onboardingProcess?.answers;
        const  localAnswers = answers ? copyAnswers(answers) : initializeAnswer(questionnaire);

        let localResponder;
        if(responder === 'customer') localResponder = 'customerAnswerGroups';
        if(responder === 'spouse') localResponder = 'spouseAnswerGroups';
        if(responder === 'shared') localResponder = 'sharedAnswerGroups';

        const answerGroupIndex = localAnswers[localResponder].findIndex(answerGroup => answerGroup.id === questionGroupId);
        const answerIndex = localAnswers[localResponder][answerGroupIndex].answers.findIndex(answer => answer.id === questionId);
        localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData.children =
            localAnswers[localResponder][answerGroupIndex].answers[answerIndex].answerData.children.filter(child => child.id !== childId);

        dispatch({ type: types.ONBOARDING_PROCESSES_UPDATING, processId });
        dispatch({ type: types.ONBOARDING_PROCESSES_FIELD_UPDATED, processId, fieldName: 'answers', fieldValue: localAnswers });
        const updatedOnboardingProcessesData = getState().onboardingProcessesData;
        const updatedOnboardingProcess = updatedOnboardingProcessesData.processes?.find(x => x.processId === processId) ?? null;
        await updateOnboardingProcessDB(updatedOnboardingProcess);
        return dispatch({ type: types.ONBOARDING_PROCESSES_UPDATED, processId });
    };
}
