import React from 'react';
import PropTypes from 'prop-types';
import { renderPercent } from '../../utils/renderingService';
import DefaultValueInputComponent from './DefaultValueInputComponent';
import { isNullOrNaN } from '../../utils/helperFunctions';

export default function PercentDefaultValueInputComponent({
    value, defaultValue, name, disabled, onChange, clearValue, variant,
    size, style, disableBorder, textAlign, label, fullWidth, placeholder }) {
    return (
        <DefaultValueInputComponent
            name={ name }
            value={ value }
            defaultValue={ defaultValue }
            clearValue={ clearValue }
            disabled={ disabled }
            onChange={ x => {
                if(isNullOrNaN(x)) {
                    onChange(null);
                } else {
                    onChange((Number(x) / 100).toFixed(4));
                }

            } }
            style={ style }
            renderFunc={ x => isNullOrNaN(x) ? '' : renderPercent(x / 100) }
            parserFunc={ x => isNullOrNaN(x) ? '' : Number((x * 100).toFixed(5)) }
            variant={ variant }
            size={ size }
            disableBorder={ disableBorder }
            textAlign={ textAlign }
            activeType='number'
            label={ label }
            fullWidth={ fullWidth }
            placeholder={ placeholder }
        />
    );
}

PercentDefaultValueInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    clearValue: PropTypes.func.isRequired,
    disableBorder: PropTypes.bool,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string,
    textAlign: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
};
