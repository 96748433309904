import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingPage() {
    // this is plain html with classnames instead of semantic-ui to match the css in public/index.html
    // that way the mounting of the react app will result in the exact same UI
    return (
        <div style={{ height: 'calc(100% - 80px)' }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height:'100%',
            }}>
                <CircularProgress color="secondary" />
            </div>
        </div>

    );
}

export default LoadingPage;
