import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const SideBarTopContent = () => {
    const { pathname } = useLocation();

    return (
        <div>
            <List disablePadding component="nav" aria-label="sidebar top menu">
                <ListItem button component={ RouterLink } to="/kunder" selected={ pathname==='/kunder' }>
                    <ListItemIcon>
                        <PersonOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Kunder" />
                </ListItem>

                { /* PLOP_INJECT_PAGE */ }

            </List>
        </div>
    );
};

SideBarTopContent.defaultProps = {};

SideBarTopContent.propTypes = {};

export default SideBarTopContent;
