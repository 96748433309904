import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextButton from './TextButton';

export default function Dropdown({ menuText, options, style }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <TextButton style={{ ...style }} aria-controls="simple-menu" onClick={ handleClick }>
                { menuText }
            </TextButton>
            <Menu
                id="simple-menu"
                anchorEl={ anchorEl }
                keepMounted
                open={ Boolean(anchorEl) }
                onClose={ handleClose }
            >
                { options.map((option, index) => {
                    return <MenuItem key={ index } onClick={ () => { option.action(); handleClose(); } }>{ option.text }</MenuItem>;
                }) }
            </Menu>
        </div>
    );
}

Dropdown.propTypes = {
    menuText: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
    })).isRequired,
    style: PropTypes.object,
};

Dropdown.defaultProps = {
    alternativeHeader: false,
    style: {},
};
