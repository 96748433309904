import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

class PageActions extends React.Component {
  el = document.createElement('div');

  componentDidMount() {
      // nav element is selected here by id
      this.targetEl = document.getElementById(this.props.id);
      if (this.targetEl) {
          this.targetEl.appendChild(this.el);
      } else {
          console.error(
              `Target element missing when trying to append to ${  this.props.id}`,
          );
      }
  }

  componentWillUnmount() {
      if (this.targetEl) {
          this.targetEl.removeChild(this.el);
      } else {
          console.error(
              `Target element missing when trying to remove ${  this.el}`,
          );
      }
  }

  render() {
      return ReactDOM.createPortal(this.props.children, this.el);
  }
}

PageActions.defaultProps = {};

PageActions.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default PageActions;
