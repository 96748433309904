/* Common */
export const GenderEnum = {
    Male: 1,
    Female: 2,
};

export const MaritalStatusEnum = {
    Unknown: 0,
    Married: 1,
    Single: 2,
    Divorced: 3,
};

export const ChildOfEnum = {
    Unknown: 0,
    Both: 1,
    You: 2,
    Partner: 3,
};

export const ChildResidenceEnum = {
    Unknown: 0,
    LivingAtHome: 1,
    NotLivingAtHome: 2,
    LivingPartlyAtHome: 3,
};

export const SavingsTypeEnum = {
    BankAccount: 1,
    Investment: 2,
    ChildSavings: 3,
    Other: 4,
};

export const DebtTypeEnum = {
    ConsumerLoan: 1,
    StudentLoan: 2,
    CarLoan: 3,
    PersonalLoan: 4,
    Overdraft: 5,
    OtherDebt: 6,
    HouseLoan: 7,
};

export const OccupationEnum = {
    Unknown: 0,
    PermanentJobFullTime: 1,
    PermanentJobPartTime: 2,
    SelfEmployed: 3,
    Student: 4,
    LeaveOfAbsenceOrStayAtHome: 5,
    Unemployed: 6,
    Pensioner: 7,
    PensionerWithJob: 8,
    EarlyRetiree: 9,
    EarlyRetirementPay: 10,
};

export const PartnerRelationEnum = {
    Unknown: 0,
    Spouse: 1,
    Cohabitant: 2,
};

export const InvestmentRiskLevelEnum = {
    Low: 1,
    Medium: 2,
    High: 3,
};

export const YesNoDontKnowEnum = {
    Yes: 1,
    No: 2,
    DontKnow: 3,
};

/* Stepper component */
export const StepperStepsVisibilityEnum = {
    Visible: 1,
    Hidden: 2,
};

/* Validation */
export const ValidationStateEnum = {
    NotValidated: 1,
    Invalid: 2,
    Valid: 3,
};

/* General */
export const FormInputValidationStateEnum = {
    NotValidated: 1,
    Invalid: 2,
    Valid: 3,
};

export const FormInputDataTypeEnum = {
    Any: 1,
    Number: 2,
    Boolean: 3,
    String: 4,
};

/* Pension check */
export const PensionReportValidationStateEnum = {
    NotValidated: 1,
    Invalid: 2,
    Valid: 3,
};

export const PensionCheckSuggestionPriorityEnum = {
    High: 1,
    Middle: 2,
    Low: 3,
};

export const PensionCheckSuggestionTypeEnum = {
    // Issues are suggestions calculated by the PensionCheck logic.
    Issue: 1,

    // Calculated issues are suggestions calculated in the frontend
    IssueCalculated: 2,

    // Calculated suggestions are suggestions/comments to a healthy pension.
    Calculated: 3,

    // Default suggestions are not based on the pension report file or user data, and are generel suggestions not based on any calulations.
    // They can be shown/hidden if some external or frontend condition is reached/not reached.
    Default: 4,

    // Data suggestions are shown if data is missing from either the pension report or the user.
    Data: 5,
};

export const PensionCheckSuggestionEnum = {
    ConsolidatePensions: 1,
    PassivePensions: 2,
    MultiplePensionPlanAtSameCompany: 3, // TODO: Type-o, rename to MultiplePensionPlan's'AtSameCompany and also update scraper API
    MultipleActivePensionPlan: 4,
    MissingData: 5,
    RightToEarlyPayments: 6,
    PublicServiceGain: 7,
    HealthyPensionAgeBelow50: 8,
    HealthyPensionAge50AndAbove: 9,
    LowPaymentComparedToSalary: 10,
    LowLifePaymentComparedToSalary: 11,
    ContactPensionAdvisor: 13,
    DoContinuousPensionCheck: 14,
    TopTaxPensionGain: 15,
    TopTaxIncome: 16,
    HighLifePayment: 17,
    MissingAbilityToWorkInsurance: 18,
    MissingLifeInsurance: 19,
};
export const DissavingStrategyEnum = {
    Nothing: 'Nothing',
    Spread: 'Spread',
    Concentrate: 'Concentrate',
    NotQualified: 'NotQualified',
};

/* PenlyPlay */
export const PenlyPlayModeEnum = {
    Public: 1, // Anonymous user
    MyPenly: 2, // Penly user
};

/* PenlyPlay Pension */
export const PenlyPlayPensionChoicesEnum = {
    Age: 1,
    Deposit: 2,
    Payment: 3,
};

export const PenlyPlayApiEndpoints = {
    PaymentEndPoint: '/annuities/CalculatePensionInstallmentAndPrognosis',
    DepositEndPoint: '/annuities/CalculateSavingsInstallmentAndPrognosis',
    AgeEndPoint: '/annuities/CalculatePensionYearAndPrognosis',
};

export const PenlyPlayPensionAgeChoicesEnum = {
    Unknown: 0,
    LowerAge: 1,
    HigherAge: 2,
};

export const PenlyPlayPensionAgeGainChoicesEnum = {
    Unknown: 0,
    LowerAgeIncreaseDeposit: 1,
    LowerAgeDecreasePayment: 2,
    HigherAgeDecreaseDeposit: 3,
    HigherAgeIncreasePayment: 4,
};

export const PenlyPlayPensionDepositChoicesEnum = {
    Unknown: 0,
    LowerDeposit: 1,
    HigherDeposit: 2,
};

export const PenlyPlayPensionDepositGainChoicesEnum = {
    Unknown: 0,
    LowerDepositLowerPayment: 1,
    LowerDepositHigherAge: 2,
    HigherDepositHigherPayment: 3,
    HigherDepositLowerAge: 4,
};

export const PenlyPlayPensionPaymentChoicesEnum = {
    Unknown: 0,
    LowerPayment: 1,
    HigherPayment: 2,
};

export const PenlyPlayPensionPaymentGainChoicesEnum = {
    Unknown: 0,
    LowerPaymentLowerDeposit: 1,
    LowerPaymentLowerAge: 2,
    HigherPaymentHigherDeposit: 3,
    HigherPaymentHigherAge: 4,
};

/* Loader */
export const LoaderTriviaCategory = {
    Default: 0,
    Pension: 1,
    Investments: 2,
    Loans: 3,
};

/* Products */
export const ProductIds = {
    PensionAdvisoryPensioncheck: {
        id: 'pension-advisory-pensioncheck',
        urlid: 'pensionstjek',
    },
    PensionAdvisoryHourlyRate: {
        id: 'pension-advisory-hourlyrate',
        urlid: 'pensionstjek-time',
    },
    PensionAdvisoryFull: {
        id: 'pension-advisory-full',
        urlid: 'pensionstjek-fuld',
    },
    PensionAdvisorySafetyCheck: {
        id: 'pension-advisory-safetycheck',
        urlid: 'pensions-sikkerhedstjek',
    },
    PensionAdvisoryContinousPensioncheck: {
        id: 'pension-advisory-continous-pensioncheck',
        urlid: 'loebende-pensionscheck',
    },
    PensionAdvisoryNordinvestmentReferral: {
        id: 'pension-advisory-nordinvestment-referral',
        urlid: 'pension-nordinvestment',
    },
};

export const ProductTypes = {
    Product: 'product',
    Subscription: 'subscription',
    Referral: 'product',
};
