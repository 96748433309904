import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function mifidProcessesReducer(state = initialState.mifidProcessesData, action) {
    switch (action.type) {
        case types.MIFID_PROCESSES_LOADED:
            return {
                ...state,
                ...action.processesData,
                isFetching: false,
            };
        case types.MIFID_PROCESSES_LOAD_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.MIFID_PROCESSES_FIELD_UPDATED:
        {
            const { processes } = state;
            const index = getIndex(processes, action.processId);
            const process = processes[index];
            const updatedProcess = {
                ...process,
                [action.fieldName]: action.fieldValue,
            };
            return {
                ...state,
                processes: [
                    ...processes.slice(0, index),
                    updatedProcess,
                    ...processes.slice(index + 1),
                ],
            };
        }
        case types.MIFID_PROCESSES_MIFID_DATA_UPDATED:
        {
            const { processes } = state;
            const index = getIndex(processes, action.processId);
            const process = processes[index];
            const updatedProcess = {
                ...process,
                mifidData: { ...action.mifidData },
            };
            return {
                ...state,
                processes: [
                    ...processes.slice(0, index),
                    updatedProcess,
                    ...processes.slice(index + 1),
                ],
            };
        }
        case types.MIFID_PROCESSES_MIFID_DATA_FIELD_UPDATED:
        {
            const { processes } = state;
            const index = getIndex(processes, action.processId);
            const process = processes[index];
            const updatedProcess = {
                ...process,
                mifidData: {
                    ...process.mifidData,
                    [action.fieldName]: action.fieldValue,
                },
            };
            return {
                ...state,
                processes: [
                    ...processes.slice(0, index),
                    updatedProcess,
                    ...processes.slice(index + 1),
                ],
            };
        }
        case types.MIFID_PROCESSES_UPDATING:
        {
            const { processes } = state;
            const index = getIndex(processes, action.processId);
            const process = processes[index];
            const updatedProcess = {
                ...process,
                isUpdating: true,
            };
            return {
                ...state,
                processes: [
                    ...processes.slice(0, index),
                    updatedProcess,
                    ...processes.slice(index + 1),
                ],
            };
        }
        case types.MIFID_PROCESSES_UPDATED:
        {
            const { processes } = state;
            const index = getIndex(processes, action.processId);
            const process = processes[index];
            const updatedProcess = {
                ...process,
                isUpdating: false,
            };
            return {
                ...state,
                processes: [
                    ...processes.slice(0, index),
                    updatedProcess,
                    ...processes.slice(index + 1),
                ],
            };
        }
        case types.MIFID_PROCESSES_ADDED:
        {
            const { processes } = state;
            return {
                ...state,
                processes: [
                    ...processes,
                    { ...action.process },
                ],
            };
        }
        default:
            return state;
    }

    function getIndex(processes, processId) {
        return processes.findIndex(process => process.processId === processId);
    };
}
