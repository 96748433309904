import React from 'react';
import PropTypes from 'prop-types';

export default function DefaultFooter({ alternativeFooter, footerText }) {
    return (
        <div style={{ width: '100%', textAlign: 'center', color: `${alternativeFooter ? 'inherit' : '#999999'}`, fontSize: '8px' }}>
            { footerText }
        </div>
    );
}
DefaultFooter.propTypes = {
    alternativeFooter: PropTypes.bool,
    footerText: PropTypes.string,
};

DefaultFooter.defaultProps = {
    alternativeFooter: false,
    footerText: 'Missing Link ApS | Studio Nordvest Glasvej 3, 1., 2400 København NV | CVR: 40297979',
};
