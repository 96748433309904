/* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, ReferenceDot, Label } from 'recharts';
import { renderKr, renderDate } from '../../utils/renderingService';
import { calulateOpsparingSum, sortScrapesByDate } from '../../utils/scrapeUtils';

const PensionSavingsGraph = ({ data, keyFigures, scrapes }) => {
    const themeColors = useSelector(state => state.site.theme.colors);

    const toKr = (value, small) => value && `${renderKr(value, undefined, undefined, undefined, small === true)}`;
    const skip = props => props && props.dataKey === 'value' && props.payload.history;
    const toKrTooltip = (value, name, props) => (value && !skip(props) ? [`${renderKr(value, undefined, undefined, undefined, false)}`, 'Opsparing'] : [undefined, undefined]);
    const renderAge = (value, props) => (props.length && props[0].payload.date ? `${renderDate(new Date(props[0].payload.date))}, ${Math.floor(value)} år` : `${Math.floor(value)} år`);

    const getAgeAtDate = date => {
        const ageDifMs = new Date(date) - new Date(keyFigures.birthDay);
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970 + (ageDate.getMonth() + 1) / 12);
    };
    const sorted = sortScrapesByDate(scrapes);
    const filtered = sorted
        ?.reverse()
        .map(x => ({
            history: calulateOpsparingSum(x.aftaler),
            year: new Date(x.profil.udskrevetDato).getFullYear(),
            age: getAgeAtDate(x.profil.udskrevetDato),
            date: x.profil.udskrevetDato,
            aftaler: x.aftaler,
        }))
        .filter((tag, index, array) => array.findIndex(t => t.age === tag.age && t.history === tag.history) === index);
    const history = filtered?.map((x, i) => ({ ...x, value: i === filtered.length - 1 ? calulateOpsparingSum(x.aftaler) : undefined }));
    const graphData = [...history, ...data.filter((x, i) => i !== 0)];
    const lastHistoryAge = history[history.length - 1]?.age;
    const lastHistoryValue = history[history.length - 1]?.value;
    history.splice(-1, 1);
    const minValue = Math.min(...graphData?.map(x => x.value ?? x.history));
    const todayValue = data[0].value;
    const lastValue = graphData[graphData.length - 1].value;
    const lastAge = graphData[graphData.length - 1].age;
    const CustomizedYAxisTick = props => {
        const { x, y, payload } = props;
        return (
            <g transform={ `translate(${x},${y})` }>
                <text x={ 0 } y={ 0 } dx={ -20 } textAnchor="end" style={{ fontWeight: 'bold' }}>
                    { toKr(payload.value) }
                </text>
                { payload.value === todayValue ? (
                    <text x={ 0 } y={ 0 } dx={ -20 } dy={ 20 } textAnchor="end" style={{ fontSize: 12 }}>
                        Opsparet til dato
                    </text>
                ) : (
                    <>
                        <text x={ 0 } y={ 0 } dx={ -20 } dy={ 20 } textAnchor="end" style={{ fontSize: 12 }}>
                            Opsparet ved
                        </text>
                        <text x={ 0 } y={ 0 } dx={ -48 } dy={ 34 } textAnchor="end" style={{ fontSize: 12 }}>
                            pension
                        </text>
                    </>
                ) }
            </g>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width={ 500 }
                height={ 300 }
                data={ graphData }
                margin={{
                    top: 30,
                    right: 30,
                    left: 20,
                    bottom: 20,
                }}
            >
                <CartesianGrid horizontal={ false } stroke={ '#D3D3D3' } />
                <XAxis scale="auto" axisLine={ false } tickLine={ false } dataKey="age" tick={{ dy: 20 }} tickFormatter={ renderAge } minTickGap={ 20 } />
                <YAxis tick={ <CustomizedYAxisTick /> } axisLine={ false } tickLine={ false } ticks={ [todayValue, lastValue] } width={ 120 } type="number" domain={ [Math.max(0, minValue * 0.9), lastValue] } />
                <Tooltip formatter={ toKrTooltip } labelFormatter={ renderAge } />
                <Legend iconType="plainline" iconSize={ 20 } wrapperStyle={{ transform: 'translate(0, 30px)' }} />
                <ReferenceLine y={ lastValue } stroke={ '#D3D3D3' } strokeWidth={ 3 } />
                <ReferenceLine x={ lastAge } stroke={ themeColors.secondary } strokeWidth={ 3 } label={ <Label value="Pension" position="insideTop" offset={ -20 } /> } />
                <ReferenceDot x={ lastHistoryAge } y={ lastHistoryValue } stroke={ themeColors.secondary } strokeWidth={ 3 } label={ <Label value="Sidste upload" position="insideTop" offset={ -20 } /> } />
                <Line isAnimationActive={ false } type="monotone" dataKey="history" strokeWidth={ 3 } stroke={ '#476789' } name="Historik" />
                <Line dot={ false } type="monotone" dataKey="value" strokeWidth={ 3 } stroke={ themeColors.primary } name="Forventet udvikling" />
            </LineChart>
        </ResponsiveContainer>
    );
};

PensionSavingsGraph.defaultProps = {};

PensionSavingsGraph.propTypes = {};

export default PensionSavingsGraph;
