import React from 'react';
import PropTypes from 'prop-types';
import { renderKr } from '../../utils/renderingService';
import InputComponent from './InputComponent';

export default function CurrencyInputComponent({ value, name, disabled, onChange, variant, size, inputPropsStyle, textAlign, disableBorder, label, fullWidth, placeholder }) {
    return (
        <InputComponent
            name={ name }
            value={ value }
            onChange={ x => onChange(x) }
            disabled={ disabled }
            inputPropsStyle={ inputPropsStyle }
            renderFunc={ x => renderKr(x, 5, 0) }
            parserFunc={ x => parseFloat(x) }
            variant={ variant }
            size={ size }
            disableBorder={ disableBorder }
            textAlign={ textAlign }
            activeType='number'
            label={ label }
            fullWidth={ fullWidth }
            placeholder={ placeholder }
        />
    );
}

CurrencyInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string,
    inputPropsStyle: PropTypes.object,
    textAlign: PropTypes.string,
    disableBorder: PropTypes.bool,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
};
