import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function aaopDataReducer(state = initialState.aaopData, action) {
    switch (action.type) {
        case types.USER_RESET_STATE: {
            return { ...initialState.aaopData, isFetching: true };
        }
        case types.AAOP_DATA_UPDATED:
            return { ...state, ...action.aaopData, isFetching: false };
        case types.AAOP_DATA_FAIL: {
            return { ...state, isFetching: false };
        }
        case types.AAOP_DATA_REQUESTED: {
            return { ...state, isFetching: true };
        }
        default:
            return state;
    }
}
