import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { VerifyOnboardingFields } from '../../pages/Kunder/Kunde/Onboarding/VerifyOnboardingFields';
import { copyAnswers } from '../../reducers/defaultOnboardingQuestionnaires';
import { intro as introType,  comment as commentType, taxFolder as taxFolderType, welcomeMessage as welcomeMessageGroupType } from '../../reducers/onboardingQuestionGroupTypes';
import { GetMergedQuistionair } from './onboardingHelpers';

export default function Overview({ questionnaire, customer, answers, goToLink }) {

    const getAnswerGroups = useCallback((responder) => {
        if(!answers) return null;

        let localResponder;
        if(responder === 'customer') localResponder = 'customerAnswerGroups';
        if(responder === 'spouse') localResponder = 'spouseAnswerGroups';
        if(responder === 'shared') localResponder = 'sharedAnswerGroups';

        const  responderAnswers = copyAnswers(answers);

        return responderAnswers[localResponder];
    }, [answers]);

    const getAnswerGroup = useCallback((responder, questionGroupId) => {
        if(!answers) return null;

        const responderAnswers = getAnswerGroups(responder);
        const answerGroupIndex = responderAnswers?.findIndex(answerGroup => answerGroup.id === questionGroupId) ?? -1;
        if (answerGroupIndex === -1) return null;

        return responderAnswers[answerGroupIndex];
    }, [answers, getAnswerGroups]);

    const localQuestionGroups = GetMergedQuistionair(questionnaire);

    return (
        <div style={{ marginTop: 50, marginLeft: 50, fontSize: '40px' }}>
            <div style={{ marginBottom: 25 }}>Velkommen { customer?.firstName }</div>
            <p>Som forberedelse til mødet med rådgiveren skal du igennem følgende punkter:</p>
            { localQuestionGroups.map((group, index) => {
                const activeResponder = group?.responder ?? 'customer';
                const activeAnswerGroup = getAnswerGroup(activeResponder, group.id);
                const allMandotoryFiledsVerified = VerifyOnboardingFields(group, activeAnswerGroup);
                const started = activeAnswerGroup?.answers?.length > 0;
                const showStatus = group.type !== introType && group.type !== commentType && group.type !== taxFolderType;

                if (group.type === welcomeMessageGroupType) {
                    return (
                        <div key={ group.id + activeResponder } style={{ marginBottom: 20, paddingTop: 20, paddingBottom: 40 }}>
                            <span style={{ verticalAlign: 'middle' }}>
                                { activeResponder === 'shared' ? 'Fælles spørgsmål' : `${activeResponder === 'customer' ? customer.firstName : questionnaire.spouseName.firstName}'s spørgsmål` }
                            </span>
                        </div>
                    );
                }

                let quiestionNumber;

                if(questionnaire.includeSpouse) {
                    switch (group?.responder) {
                        case 'customer':
                            quiestionNumber = index + 1 - 1;
                            break;
                        case 'spouse':
                            quiestionNumber = index + 1 - 2;
                            break;
                        case 'shared':
                            quiestionNumber = index + 1 - 3;
                            break;
                        default:
                            quiestionNumber = index + 1;
                    };
                } else {
                    quiestionNumber = index + 1;
                }

                return (
                    <div key={ group.id + activeResponder } style={{ marginBottom: 20, paddingBottom: 20, borderBottom: '1px solid #B8B8B8', cursor: 'pointer' }} onClick={ () => goToLink(group.id, activeResponder) }>
                        <span
                            style={{
                                display: 'inline-block',
                                width: 55,
                                height: 55,
                                border: '5px solid #006387',
                                backgroundColor: '#006387',
                                color: '#FFF',
                                borderRadius: '50%',
                                lineHeight: '45px',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                marginRight: 5,
                            }}
                        >
                            { quiestionNumber }
                        </span>
                        <span style={{ verticalAlign: 'middle' }}>{ group.title }</span>
                        <br />
                        { showStatus && allMandotoryFiledsVerified && <div style={{ display: 'inline-block', marginLeft: 60, padding: '0 12', fontSize: 14, borderRadius: 20, background: '#C3F2BB' }}>Gennemført</div> }
                        { showStatus && started && !allMandotoryFiledsVerified && <div style={{ display: 'inline-block', marginLeft: 60, padding: '0 12', fontSize: 14, borderRadius: 20, background: '#FFE8AC' }}>I gang</div> }
                        { showStatus && !started && <div style={{ display: 'inline-block', marginLeft: 60, padding: '0 12', fontSize: 14, borderRadius: 20, background: '#ACD7FF' }}>Ikke startet</div> }
                    </div>
                );
            }) }
        </div>
    );
}

Overview.propTypes = {
    questionnaire: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    answers: PropTypes.object,
    goToLink: PropTypes.func,
};
