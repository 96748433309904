import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { Table } from '../UIComponents';
import { renderDate, renderKr } from '../../utils/renderingService';
import { paths } from '../../paths';
import UploadPensionsInfoModal from './UploadPensionsInfoModal';
import SelectPensionsInfoModal from './SelectPensionsInfoModal';
import { updateProcessField } from '../../actions/processActions';

const PensionInput = ({ process }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { customerId } = useParams();
    const [open, setOpen] = React.useState(false);
    const [openSelect, setOpenSelect] = React.useState(false);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleClickOpenSelect = () => setOpenSelect(true);
    const handleCloseSelect = () => setOpenSelect(false);

    const onClickScrape = (event, file) => {
        history.push(paths.fileDetails.getPath(customerId, file.fileId));
    };

    const customer = useSelector(state => state.customerData.customers.find((c) => c.customerId === customerId));
    const files = Object.values(process.files || {});

    useEffect(() => {
        if (!process.files || !customer.files) return;
        const scrapingFile = Object.values(process.files).find(file => file.scraping);
        if (!scrapingFile) return;

        const scrapedFile = customer.files.find(cFile => cFile.fileId === scrapingFile.fileId && cFile.metadata.content);
        if (!scrapedFile) return;

        dispatch(updateProcessField(`files.${scrapingFile.fileId}`, scrapedFile));

    }, [dispatch, customer.files, process.files]);

    const colConfig = [
        {
            id: 'date',
            label: 'Udskrevet dato',
        },
        {
            id: 'source',
            label: 'Kilde',
        },
        {
            id: 'count',
            label: 'Aftaler',
        },
        {
            id: 'payments',
            label: 'Årlig indbetaling',
        },
        {
            id: 'savings',
            label: 'Samlet opsparing',
        },
    ];

    const tableScrapes = files
        .map(file => {
            if (file.metadata?.content) {
                const { content } = file.metadata;
                return {
                    ...file,
                    date: renderDate(content.profil.udskrevetDato, { numeric: true }),
                    count: content.aftaler.length,
                    source: file.source,
                    savings: renderKr(content.aftaler.map(y => y.opsparing || 0).reduce((sum, y) => sum + y)),
                    payments: renderKr(content.aftaler.map(y => y.indbetaling || 0).reduce((sum, y) => sum + y)),
                };
            }
            if (file.status === 'failed') {
                return { ...file, date: 'Fil kunne ikke scrapes' };
            }
            return { loading: true };
        })
        .sort((a, b) => a.date < b.date ? 1 : -1);

    return (
        <div>
            <UploadPensionsInfoModal open={ open } handleClose={ handleClose } customerId={ customerId } />
            <SelectPensionsInfoModal open={ openSelect } handleClose={ handleCloseSelect } customer={ customer } />
            { }
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 20,
                    gap: '10px',
                }}
            >
                <Button onClick={ handleClickOpen }>
                    Upload ny PensionsInfo
                </Button>
                <Button onClick={ handleClickOpenSelect }>
                    Vælg eksisterende PensionsInfo
                </Button>
            </div>

            { files.length > 0 && (
                <>
                    <Table rows={ tableScrapes } colConfig={ colConfig } onClick={ onClickScrape } paginated={ false } />
                </>
            ) }
        </div>
    );
};

PensionInput.defaultProps = {};

PensionInput.propTypes = {
    process: PropTypes.object.isRequired,
};

export default PensionInput;
