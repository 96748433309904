import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment  } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

export default function DefaultValueInputComponent({ value, defaultValue, name, disabled, onChange, clearValue,
    variant, size, style, textAlign, disableBorder, renderFunc, parserFunc, activeType, label, fullWidth, placeholder }) {
    const [isActive, setIsActive] = useState(false);
    const [internalValue, setInternalValue] = useState(parserFunc(value));
    useEffect(() => setInternalValue(parserFunc(value)), [value, parserFunc]);

    const [defaultInternalValue, setDefaultInternalValue] = useState(parserFunc(defaultValue));
    useEffect(() => setDefaultInternalValue(parserFunc(defaultValue)), [defaultValue, parserFunc]);

    function onFocus() {
        setIsActive(true);
    }

    function onBlur(e) {
        onChange(e.target.value); // only save value when leaving field
        // unnecessarily complex due to firefox: https://stackoverflow.com/questions/54690014/react-input-type-change-fires-blur-event-in-firefox
        const relatedTarget = e.relatedTarget ||
            e.explicitOriginalTarget ||
            document.activeElement; // IE11

        if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
            setIsActive(false);
        }
    }

    function onChangeValue(e) {
        setInternalValue(e.target.value);
    }

    return (
        <div style={{ display:'flex', alignItems: 'center' }}>
            <TextField
                disabled={ disabled }
                sx={ disableBorder ? {
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'none',
                    },
                    '& .MuiInputBase-input': {
                        padding: 0,
                    },
                    '& .MuiOutlinedInput-inputMarginDense': {
                        padding: 0,
                    },
                } : {} }
                name={ name }
                type={ isActive ? activeType : 'string' }
                value={ isActive ? internalValue : renderFunc(internalValue) }
                onChange={ onChangeValue }
                onFocus={ (e) => onFocus(e) }
                onBlur={ (e) => onBlur(e) }
                onKeyDown={ (e) => e.key === 'Enter' && document.activeElement.blur() }
                variant={ variant }
                size={ size }
                inputProps={{ style: { textAlign, ...style } }}
                style={{ maxWidth: fullWidth ? '100%' : 150 }}
                label={ label }
                fullWidth={ fullWidth }
                placeholder={ placeholder }
                InputProps={{
                    endAdornment:(
                        <InputAdornment position="end">
                            { value !== null && value !== undefined ?
                                (
                                    <>
                                        <del style={{ color: 'grey', textDecoration: 'line-through' }}>{ defaultValue !== null && defaultValue !== undefined ? renderFunc(defaultInternalValue) : 'N/A' }</del>
                                        <IconButton style={{ color: 'red' }} size="small" aria-label="clear value" display="text" onClick={ () => clearValue() }>
                                            <ClearIcon />
                                        </IconButton>
                                    </>
                                )
                                : <del style={{ color: 'grey', textDecoration: 'none' }}>{ defaultValue !== null && defaultValue !== undefined ? renderFunc(defaultInternalValue) : 'N/A' }</del>
                            }
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
}

DefaultValueInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    clearValue: PropTypes.func.isRequired,
    renderFunc: PropTypes.func,
    parserFunc: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object,
    textAlign: PropTypes.string,
    disableBorder: PropTypes.bool,
    activeType: PropTypes.string,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
};

DefaultValueInputComponent.defaultProps = {
    disabled: false,
    variant: 'outlined',
    size: 'small',
    style: { },
    textAlign: 'center',
    disableBorder: false,
    renderFunc: x => x,
    parserFunc: x => x,
    activeType: 'string',
    fullWidth: false,
};
