import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import customerData from './customerDataReducer';
import partnerData from './partnerDataReducer';
import wealthData from './wealthDataReducer';
import documentData from './documentDataReducer';
import providerData from './providerDataReducer';
import aaopData from './aaopDataReducer';
import adviceModuleData from './adviceModuleDataReducer';
import processData from './processDataReducer';
import processesData from './processesDataReducer';
import advisorData from './advisorDataReducer';
import auth from './authReducer';
import config from './configReducer';
import site from './siteReducer';
import integrations from './integrations';
import investData from './investDataReducer';
import mifidProcessesData from './mifidProcessesReducer';
import onboardingProcessesData from './onboardingProcessesReducer';
import projection from './projectionReducer';
import uniify from './uniifyReducer';
import initialState from './initialState';

const appReducer = combineReducers({
    partnerData,
    wealthData,
    customerData,
    documentData,
    providerData,
    adviceModuleData,
    advisorData,
    aaopData,
    processData,
    investData,
    mifidProcessesData,
    onboardingProcessesData,
    processesData,
    projection,
    uniify,
    auth,
    config,
    site,
    integrations,
    routing: routerReducer,
    global: state => ({ ...state }),
});

const rootReducer = (state, action) => {
    if (action.type === 'AUTH_SIGNOUT') return initialState;

    const newState = appReducer(state, action);
    const loading = Object.keys(newState).some(key => newState[key].globalLoading);
    return { ...newState, global: { loading } };
};


export default rootReducer;
