import { address as addresstype, cpr as cprType, children as childrenType, email as emailType, pdfImage as pdfImageType, text as textType, name as nameType, phone as phoneType, kreditDataPension as kreditDataPensionType } from '../../../../reducers/onboardingQuestionTypes';
import { isNullOrWhitespace } from '../../../../utils/helperFunctions';


export function VerifyAllGroups(questionGroups, answerGroups) {
    let allVerified = true;
    for (let index = 0; index < questionGroups?.length ?? 0; index += 1) {
        const questionGroup = questionGroups[index];
        const answerGroup = answerGroups?.find(x => x.id === questionGroup.id);
        allVerified = allVerified && VerifyOnboardingFields(questionGroup, answerGroup);
    }
    return allVerified;
}

export function VerifyOnboardingFields(questionGroup, answerGroup) {
    const { questions } = questionGroup ?? { };
    let allVerified = true;
    for (let index = 0; index < questions?.length ?? 0; index += 1) {
        const question = questions[index];
        if (question?.mandatory) {
            const answer = answerGroup?.answers?.find(x => x.id === question.id) ?? { };
            allVerified = allVerified && VerifyOnboardingField(question.type, answer);
        }
    }

    return allVerified;
}

export function VerifyOnboardingField(questionType, answer) {
    switch (questionType) {
        case addresstype:
            return verifyAddress(answer);
        case cprType:
            return verifyCpr(answer);
        case childrenType:
            return verifyChildren(answer);
        case emailType:
            return verifyEmail(answer);
        case pdfImageType:
            return verifyPdfImage(answer);
        case textType:
            return verifyText(answer);
        case nameType:
            return verifyName(answer);
        case phoneType:
            return verifyPhone(answer);
        case kreditDataPensionType:
            return verifyKreditdata(answer);
        default:
            break;
    }
}

function verifyAddress(answer) {
    return answer?.answerData?.selectedAddress !== null && answer?.answerData?.selectedAddress !== undefined;
}

function verifyCpr(answer) {
    const cpr = answer?.answerData?.cpr;
    if (isNullOrWhitespace(cpr)) return false;

    if(cpr.match(/[^\d]/g)) return false;

    if (cpr.length === 10 && cpr.match(/\d/g) && cpr.match(/\d/g).length === 10) {
        return true;
    }

    return false;
}

function verifyChildren(answer) {
    const answerData = answer?.answerData;
    if(answerData?.noKids) return true;

    const children = answerData?.children;
    if(!children || children?.length < 1) return false;
    let allChildrenValid = true;

    for (let index = 0; index < children?.length; index += 1) {
        const child = answerData?.children[index];
        allChildrenValid = allChildrenValid && !isNullOrWhitespace(child?.name);
        allChildrenValid = allChildrenValid && !isNullOrWhitespace(child?.birthday);
    }

    return allChildrenValid;
}

function verifyEmail(answer) {
    const email = answer?.answerData?.email;
    if (isNullOrWhitespace(email)) return false;
    if (email.toString().indexOf('@') === -1 || email.toString().indexOf('.') === -1) return false;

    return true;
}

function verifyPdfImage(answer) {
    const files = answer?.answerData?.files;
    if (!files || files?.length < 1) return false;
    let allFilesValid = true;
    for (let index = 0; index < files?.length; index += 1) {
        const file = files[index];
        allFilesValid = allFilesValid && !isNullOrWhitespace(file?.fileId) && !isNullOrWhitespace(file?.fileName);
    }

    return allFilesValid;
}

function verifyText(answer) {
    return !isNullOrWhitespace(answer?.answerData?.text);
}

function verifyName(answer) {
    const { firstName, lastName } = answer?.answerData ?? { };
    return !isNullOrWhitespace(firstName) && !isNullOrWhitespace(lastName);
}

function verifyPhone(answer) {
    const phoneNumber = answer?.answerData?.phoneNumber;
    if (isNullOrWhitespace(phoneNumber)) return false;

    if(phoneNumber.match(/[^\d+]/g)) return false;

    if ((!phoneNumber.startsWith('+') && phoneNumber.length === 8 && phoneNumber.match(/\d/g) && phoneNumber.match(/\d/g).length === 8)
        || (phoneNumber.startsWith('+') && phoneNumber.length === 11 && phoneNumber.match(/\d/g) && phoneNumber.match(/\d/g).length === 10)) {
        return true;
    }
    console.log('test');
    return false;
}

function verifyKreditdata(answer) {
    const { kreditdata } = answer?.answerData ?? { };
    return !(kreditdata === undefined || kreditdata === null);
}
