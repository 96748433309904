import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTheme } from '../../actions/siteActions';
import { decode } from '../../utils/base64';
import ReportInjector from './ReportInjector';
import BlankReport from './BlankReport';
import InvestCheckReport from './InvestCheckReport';
import PensionCheckReport from './PensionCheckReport';
import MifidReport from './MifidReport';

const reportTypeMap = {
    blank: BlankReport,
    investCheck: InvestCheckReport,
    pensionCheck: PensionCheckReport,
    mifid: MifidReport,
};

function ReportPage() {
    const dispatch = useDispatch();
    const [state, setState] = useState({ reportType: 'blank' });

    useEffect(() => {
        window.setReportState = (injectBase64) => {
            const injectString = decode(injectBase64);
            const injectJson = JSON.parse(injectString);
            console.log(`Injecting state into report: ${JSON.stringify(injectJson, null, 4)}`); // eslint-disable-line no-console
            setState(injectJson);
            dispatch(updateTheme(injectJson?.site?.theme));
        };
    });

    const ReportTypeName = reportTypeMap[state.reportType];
    return (
        <div>
            <ReportInjector />
            <ReportTypeName state={ state } />
        </div>
    );
}

export default ReportPage;
