import React from 'react';
import propTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { updateProcessField } from '../../actions/processActions';


export default function SelectPensionsInfoModal({ open, handleClose, customer }) {
    const dispatch = useDispatch();

    const handleSelect = async (file) => {
        await dispatch(updateProcessField(`files.${file.fileId}`, file));

        handleClose();
    };

    if (!customer.files) return null;

    return (
        <div>
            <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Upload PensionsInfo</DialogTitle>
                <DialogContent>
                    <DialogContentText>Vælg hvilken PensionsInfo-fil du gerne vil bruge.</DialogContentText>
                    <div>
                        { customer.files
                            .filter(file => file.fileType === 'PensionsInfoFile' && file.metadata.content)
                            .map(file => {
                                return (
                                    <div key={ file.fileId } style={{ margin: 5, width: '100%' }}>
                                        <Button style={{ width: '100%' }}
                                            onClick={ () => handleSelect(file) }
                                        >
                                            { `${file.fileName}, udskrevet: ${file.metadata.content.profil.udskrevetDato}` }
                                        </Button>
                                    </div>
                                );
                            })
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

SelectPensionsInfoModal.propTypes = {
    open: propTypes.bool.isRequired,
    handleClose: propTypes.func.isRequired,
    customer: propTypes.object.isRequired,
};
