import React from 'react';
import PropTypes from 'prop-types';
import DefaultFooter from './DefaultFooter';

export default function F10Footer({ alternativeFooter }) {
    return <DefaultFooter alternativeFooter={ alternativeFooter } footerText='F10 Finansrådgiver P/S | Hollufgårds Allé 2, 5220 Odense | kontakt@f10.dk | CVR: 35028595'/>;
}

F10Footer.propTypes = {
    alternativeFooter: PropTypes.bool,
};

F10Footer.defaultProps = {
    alternativeFooter: false,
};
