import { db } from './database';

export function updateTheme(partnerId, theme) {
    return db.collection(`partners/${partnerId}/site`).doc('theme').set(theme);
}

export async function getSite(partnerId) {
    const siteDocuments = await db.collection(`partners/${partnerId}/site`).get();

    const site = { };
    siteDocuments.docs.forEach(doc => {
        site[doc.id] = doc.data();
    });
    return site;
}
