import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@mui/material';

const ForgotPasswordForm = (props) => {
    return (
        <form
            onSubmit={ props.login }
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 40,
            }}
        >
            <div style={{
                fontSize: 15,
                color: '#032d40',
                marginBottom: 12,
            }}>Glemt kodeord</div>
            <TextField
                value={ props.email }
                onChange={ (e) => props.setEmail(e.target.value) }
                color="secondary"
                autoFocus
                label="Email"
                variant="filled"
                type="email"
                autoComplete="email"
                style={{ marginBottom: 31 }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="submit">
                    Nulstil kodeord
                </Button>
            </div>
        </form>
    );
};

ForgotPasswordForm.defaultProps = {};

ForgotPasswordForm.propTypes = {
    login: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
};

export default ForgotPasswordForm;
