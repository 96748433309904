import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useWindowSize } from '../../../hooks';

const PageContent = (props) => {
    const theme = useTheme();
    const { width } = useWindowSize();
    const isMobile = width < theme.breakpoints.values.md;
    return (
        <div
            style={{
                padding: !isMobile && props.padded && '20px',
                minHeight: isMobile ? 'calc(100vh - 128px)' : 'calc(100vh - 189px)',
            }}
        >
            { props.children }
        </div>
    );
};

PageContent.defaultProps = {
    padded: true,
};

PageContent.propTypes = {
    padded: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default PageContent;
