import React from 'react';
import PropTypes from 'prop-types';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import CircularProgress from '@mui/material/CircularProgress';
import EnhancedTableHead from './EnhancedTableHead';
import { getComparator, stableSort } from './sort';

export default function Table(props) {
    const {
        rows,
        colConfig,
        onClick,
        label,
        labelElement,
        sortable,
        paginated,
        inline,
        emptyCols,
        showEmptyRows,
    } = props;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(undefined);
    const [page, setPage] = React.useState(0);
    const rowsPerPageDefault = paginated ? 8 : rows.length;
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageDefault);

    React.useEffect(() => { setRowsPerPage(paginated ? 8 : rows.length); }, [paginated, rows.length]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - (page * rowsPerPage));

    return (
        <TableContainer style={ props.style }>
            { (label || labelElement) && <div
                style={{
                    fontSize: '15px',
                    marginLeft: '30px',
                    marginBottom: '0px',
                    marginTop: '30px',
                    fontWeight: 'bold',
                }}
            >
                { label || labelElement }
            </div> }
            <MuiTable
                aria-label='table'
                style={ emptyCols ? { tableLayout: 'auto' } : {} }
            >
                <EnhancedTableHead
                    emptyCols={ emptyCols }
                    sortable={ sortable }
                    inline={ inline }
                    colConfig={ colConfig }
                    order={ order }
                    orderBy={ orderBy }
                    onRequestSort={ handleRequestSort }
                    rowCount={ rows.length }
                />
                <TableBody>
                    { stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                        .map((row, index) => (
                            <TableRow
                                hover={ !inline && !row.loading }
                                tabIndex={ -1 }
                                key={ index }
                                onClick={ onClick && ((event) => onClick(event, row, index)) }
                            >
                                { row.loading ? (
                                    <TableCell colSpan={ colConfig.length } align='center'>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                                            <CircularProgress size={ 18 } color='secondary' />
                                            { typeof row.loading === 'string' ? row.loading : null }
                                        </div>
                                    </TableCell>
                                ) : (
                                    colConfig.map((cell, i) => {
                                        const Formatter = cell.formatter;
                                        return (
                                            <TableCell
                                                key={ i }
                                                align={ i === 0 ? 'left' : 'right' }
                                                style={
                                                    emptyCols ? { minWidth: cell.minWidth || 200 } : {}
                                                }
                                            >
                                                { cell.formatter ? (
                                                    <Formatter row={ row }>{ row[cell.id] }</Formatter>
                                                ) : (
                                                    row[cell.id]
                                                ) }
                                            </TableCell>
                                        );
                                    })
                                ) }
                                { emptyCols && <TableCell style={{}} /> }
                            </TableRow>
                        )) }

                    { showEmptyRows && emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={ 6 } />
                        </TableRow>
                    ) }
                </TableBody>
                { paginated && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={ [
                                    rowsPerPageDefault,
                                    20,
                                    50,
                                    100,
                                    { label: 'Alle', value: -1 },
                                ] }
                                colSpan={ 5 }
                                count={ rows.length }
                                rowsPerPage={ rowsPerPage }
                                page={ page }
                                onPageChange={ handleChangePage }
                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                showFirstButton
                                showLastButton
                            />
                        </TableRow>
                    </TableFooter>
                ) }
            </MuiTable>
        </TableContainer>
    );
}
Table.defaultProps = {
    inline: false,
    sortable: true,
    paginated: true,
    showEmptyRows: true,
};

Table.propTypes = {
    rows: PropTypes.array.isRequired,
    colConfig: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    inline: PropTypes.bool,
    sortable: PropTypes.bool,
    paginated: PropTypes.bool,
    label: PropTypes.string,
    labelElement: PropTypes.element,
    emptyCols: PropTypes.bool,
    showEmptyRows: PropTypes.bool,
    style: PropTypes.any,
};
