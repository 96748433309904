export const fileTypes = {
    PensionsInfoFile: 'PensionsInfoFile',
    PensionsDepots: 'PensionsDepots',
    StockOverview: 'StockOverview',
    BankAccountOview: 'BankAccountOview',
    HouseData: 'HouseData',
    SalaryData: 'SalaryData',
    Id: 'Id',
    HealthInsuranceCase: 'HealthInsuranceCase',
    Budget: 'Budget',
};

export const fileTypeTranslations = {
    PensionsInfoFile: 'PensionsInfoPDF',
    PensionsDepots: 'PensionsPuljerPDF',
    StockOverview: 'Depotsoversigt',
    BankAccountOview: 'Kontooversigt',
    HouseData: 'Boligdata',
    SalaryData: 'lønsedler',
    Id: 'ID',
    HealthInsuranceCase: 'Sygesikringskort',
    Budget: 'Budget',
};

export const getFileTypeTranslation = fileType => {
    if (fileTypeTranslations[fileType]) return fileTypeTranslations[fileType];
    return 'fileType';
};
