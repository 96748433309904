import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function FileNotFoundDialog({ open, onCancel, onConfirm }) {
    const canceled = () => {
        onCancel();
    };

    const confirmed = () => {
        onConfirm();
    };

    return (
        <>
            <Dialog
                open={ open }
            >
                <DialogTitle>Filen blev ikke fundet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Filen blev ikke fundet, vil du fjerne filen fra oversigten?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => canceled() }>
                        Nej
                    </Button>
                    <Button onClick={ () => confirmed() } autoFocus>
                        Ja - fjern filen fra oversigten
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

FileNotFoundDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};
