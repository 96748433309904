import React from 'react';
import { useSelector } from 'react-redux';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getOverrides from './UIComponents/muiOverrides';
import BrowserSupportModal from './BrowserSupportModal';
import Routes from '../routes';

const App = () => {
    const theme = useSelector(state => state.site.theme);
    const muiTheme = createTheme({
        palette: {
            primary: {
                main: theme.colors.primary,
            },
            secondary: {
                main: theme.colors.secondary,
            },
        },
        spacing: theme.spacing,
        components: getOverrides(theme),
    });
    // cant figure out where body background is being set, and it overrides if no important
    return (
        <ThemeProvider theme={ muiTheme }>
            <GlobalStyles styles={{
                body: {
                    /* props come from themeprovider here */
                    backgroundColor: `${theme.colors.mainBackground} !important`,
                },
                '.Mui-selected .MuiTypography-root': {
                    fontWeight: 500,
                },
                '.MuiTableRow-root.MuiTableRow-hover:hover': {
                    backgroundColor: 'rgba(1, 54, 79, 0.05) !important',
                    cursor: 'pointer',
                },
                '.chrome-picker': {
                    boxShadow: 'none !important',
                },
                '.chrome-picker > *': {
                    borderRadius: '0px !important',
                },
            }} />
            <BrowserSupportModal />
            <Routes />
        </ThemeProvider>
    );
};

export default App;
