import React from 'react';
import PropTypes from 'prop-types';
import InputComponent from './InputComponent';

function YearInputComponent({ value, name, disabled, onChange, variant, size, inputPropsStyle, disableBorder, textAlign, label, fullWidth, placeholder }) {
    function onChangeValue(x) {
        const yearValue = Math.floor(Number(x));
        onChange(yearValue);
    }

    return (
        <InputComponent
            name={ name }
            value={ value }
            disabled={ disabled }
            onChange={ onChangeValue }
            inputPropsStyle={ inputPropsStyle }
            renderFunc={ x => `${x} år` }
            variant={ variant }
            size={ size }
            disableBorder={ disableBorder }
            textAlign={ textAlign }
            activeType='number'
            label={ label }
            fullWidth={ fullWidth }
            placeholder={ placeholder }
        />
    );
}

YearInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disableBorder: PropTypes.bool,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string,
    textAlign: PropTypes.string,
    inputPropsStyle: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
};

export default YearInputComponent;
