import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PageActions from '../PageActions';
import NavigateBackComponent from '../NavigateBackComponent';

const SectionDescription = (props) => {
    const theme = useTheme();
    return (
        <PageActions id='section-description'>
            <div
                style={{
                    marginTop: !props.isMobile && '-5px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#ababab',
                    fontSize: '11px',
                    visibility: props.isRoot && 'hidden',
                }}
            >
                <ArrowBackIcon style={{ width: 12, marginRight: 2 }} />
                <NavigateBackComponent skip={ props.skip } />
            </div>
            <div style={{
                fontSize: '20px',
                color: theme.palette.secondary.main,
                lineHeight: '15px',
            }}>{ props.children }</div>
        </PageActions>
    );
};

SectionDescription.defaultProps = {
    isRoot: false,
    skip: false,
    isMobile: false,
};

SectionDescription.propTypes = {
    isRoot: PropTypes.bool,
    isMobile: PropTypes.bool,
    skip: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default SectionDescription;
