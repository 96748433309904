import React from 'react';
import PropTypes from 'prop-types';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LabelList,
    ResponsiveContainer,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { generateDifferenceProjection } from '../../utils/calc';
import { renderNumber } from '../componentHelpers';

export default function CostDifferenceBarChart(props) {
    const {
        initialValue,
        returnPerYear,
        currentCostsPerYear,
        newCostFirstYear,
        newCostPerYear,
        startYear,
        intervals,
        height,
    } = props;

    const data = generateDifferenceProjection(
        initialValue,
        returnPerYear,
        currentCostsPerYear,
        newCostFirstYear,
        newCostPerYear,
        startYear,
        intervals,
    );
    const toKr = (value) => `${renderNumber(value)}`;
    const theme = useTheme();
    return (
        <ResponsiveContainer width="100%" height={ height }>
            <BarChart
                data={ data }
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" domain={ ['dataMin', 'dataMax'] } />
                <YAxis
                    tickFormatter={ toKr }
                    width={ 90 }
                    type="number"
                    padding={{ top: 10 }}
                    domain={ [0, 'auto'] }
                />
                <Tooltip />
                <Legend />
                <Bar
                    name="Mer-værdi"
                    isAnimationActive={ false }
                    legendType="none"
                    dataKey="difference"
                    fill={ theme.palette.primary.main }
                >
                    <LabelList
                        dataKey="difference"
                        position="top"
                        style={{ fontSize: 12 }}
                        formatter={ toKr }
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

CostDifferenceBarChart.propTypes = {
    initialValue: PropTypes.number.isRequired,
    returnPerYear: PropTypes.number.isRequired,
    currentCostsPerYear: PropTypes.number.isRequired,
    newCostFirstYear: PropTypes.number.isRequired,
    newCostPerYear: PropTypes.number.isRequired,
    startYear: PropTypes.number.isRequired,
    intervals: PropTypes.array.isRequired,
    height: PropTypes.number,
};

CostDifferenceBarChart.defaultProps = {
    height: 300,
};
