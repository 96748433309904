import React from 'react';
import PropTypes from 'prop-types';

class F10PageTemplate extends React.Component {
    render() {
        const { children, alternativeFooter } = this.props;
        return (
            <div
                className="page A4-landscape"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                }}
            >
                <div style={{ height: '40px' }} />
                <div style={{ flexGrow: 1, margin: '10px 20px' }}>{ children }</div>
                { !alternativeFooter ? (
                    <div style={{ height: '40px', textAlign: 'center', color: '#999999' }}>
                        F10 Finansrådgiver P/S | Hollufgårds Allé 2, 5220 Odense | kontakt@f10.dk | CVR: 35028595
                    </div>
                ) : (
                    <div style={{ height: '40px', textAlign: 'center' }}>
                        F10 Finansrådgiver P/S | Hollufgårds Allé 2, 5220 Odense | kontakt@f10.dk | CVR: 35028595
                    </div>
                ) }
            </div>
        );
    }
}
F10PageTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    alternativeFooter: PropTypes.bool,
};

F10PageTemplate.defaultProps = {
    alternativeFooter: false,
};

export default F10PageTemplate;
