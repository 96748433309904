import React from 'react';
import PropTypes from 'prop-types';
import DefaultHeader from './MifidDefaultHeader';
import { imgToBase64 } from '../../../../utils/base64';

export default function F10MifidHeader({ alternativeHeader, date, imgData }) {
    return <DefaultHeader alternativeHeader={ alternativeHeader } date={ date }><img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '18%', height: 'auto' }} alt='F10 logo' src={ imgData } /></DefaultHeader>;
};

export async function getF10Header(alternativeHeader, date) {
    const img = await imgToBase64('/assets/img/f10/logo-2.png');
    return <F10MifidHeader imgData={ img } alternativeHeader={ alternativeHeader } date={ date } />;
}

F10MifidHeader.propTypes = {
    alternativeHeader: PropTypes.bool,
    date: PropTypes.any,
    imgData: PropTypes.string.isRequired,
};

F10MifidHeader.defaultProps = {
    alternativeHeader: false,
};
