import React from 'react';
import PropTypes from 'prop-types';
import CountryExposurePieChart from '../Charts/CountryExposurePieChart';

export default function InvestCombinedCountryExposurePieCharts({ currentPortfolioCountryExposure, targetPortfolioCountryExposure }) {
    const colors = ['#00008b', '#ff4500', '#ffa500', '#FF0000', '#00ff00', '#ba55d3', '#00ffff', '#00bfff', '#2f4f4f', '#0000ff', '#f08080', '#ff00ff', '#f0e68c', '#dda0dd', '#ff1493', '#98fb98', '#d3d3d3', '#8b4513', '#2e8b57', '#808000', '#483d8b'];

    const currentEquityLongCountryExposure = [ ...(currentPortfolioCountryExposure?.result?.equityLongCountryExposure ?? []) ];
    const targetEquityLongCountryExposure = [ ...(targetPortfolioCountryExposure?.result?.equityLongCountryExposure ?? []) ];
    const currentPortfolioCountryExposureTop10 = currentEquityLongCountryExposure?.sort((a, b) => b.value-a.value).slice(0, 10) ?? [];
    const targetPortfolioCountryExposureTop10 = targetEquityLongCountryExposure?.sort((a, b) => b.value-a.value).slice(0, 10) ?? [];
    const currentNames = currentPortfolioCountryExposureTop10.map(x => x.countryName);
    const targetNames = targetPortfolioCountryExposureTop10.map(x => x.countryName);
    const colorCountryName = [... new Set([...currentNames, ...targetNames, 'Andre'])].map((x, index) => { return { countryName: x, color: colors[index] }; } );
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <div style={{ flex: '50%' }}>
                <CountryExposurePieChart portfolioCountryExposure={ currentPortfolioCountryExposureTop10 } colors={ colorCountryName } name="Nuværende portefølje" />
            </div>
            <div style={{ flex: '50%' }}>
                <CountryExposurePieChart portfolioCountryExposure={ targetPortfolioCountryExposureTop10 } colors={ colorCountryName } name="Foreslået portefølje" />
            </div>
        </div>
    );
}

InvestCombinedCountryExposurePieCharts.propTypes = {
    currentPortfolioCountryExposure: PropTypes.object,
    targetPortfolioCountryExposure: PropTypes.object,
};

InvestCombinedCountryExposurePieCharts.defaultProps = {
};
