export const paths = {
    customers: { path: '/kunder', getPath: () => '/kunder' },
    createCustomer: { path: '/opret-kunde', getPath: () => '/opret-kunde' },
    editCustomer: { path: '/kunder/:customerId/rediger-kunde', getPath: (customerId) => `/kunder/${customerId}/rediger-kunde` },
    newayConnectCustomer: { path: '/kunder/:customerId/forbind-kunde-til-neway', getPath: (customerId) => `/kunder/${customerId}/forbind-kunde-til-neway` },
    profile: { path: '/kunder/:customerId', getPath: customerId => `/kunder/${customerId}` },
    advice: { path: '/advice/:customerId', getPath: customerId => `/advice/${customerId}` },
    pensionCheck: { path: '/kunder/:customerId/pensions-tjek/:processId', getPath: (customerId, processId) => `/kunder/${customerId}/pensions-tjek/${processId}` },
    investCheck: { path: '/kunder/:customerId/invest-tjek/:processId', getPath: (customerId, processId) => `/kunder/${customerId}/invest-tjek/${processId}` },
    report: { path: '/report', getPath: () => '/report' },
    settings: { path: '/indstillinger', getPath: () => '/indstillinger' },
    settingsModelPortfolio: { path: '/indstillinger/modelPortfolios', getPath: () => '/indstillinger/modelPortfolios' },
    settingsCustomAssets: { path: '/indstillinger/customAssets', getPath: () => '/indstillinger/customAssets' },
    settingsDefaultValues: { path: '/indstillinger/defaultValues', getPath: () => '/indstillinger/defaultValues' },
    mifid: { path: '/kunder/:customerId/mifid', getPath: customerId => `/kunder/${customerId}/mifid` },
    mifidTest: { path: '/kunder/:customerId/mifid/:processId', getPath: (customerId, processId) => `/kunder/${customerId}/mifid/${processId}` },
    fileDetails: { path: '/kunder/:customerId/filer/:fileId', getPath: (customerId, fileId) => `/kunder/${customerId}/filer/${fileId}` },
    onboarding: { path: '/kunder/:customerId/onboarding/:processId', getPath: (customerId, processId) => `/kunder/${customerId}/onboarding/${processId}` },
};
